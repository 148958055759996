import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const mitreMatrixAdapter = createEntityAdapter<any>({
    selectId: item => {
        let id = '';
        if (!!item.tactic_external_id) {
            id += item.tactic_external_id + '-';
        }
        id += item.mitre_external_id;
        return id;
    },
});

export interface MitreMatrixState extends EntityState<any> {
    isLoading: boolean;
    error: any;
}

export const initialMitreMatrixState: MitreMatrixState = mitreMatrixAdapter.getInitialState({
    isLoading: false,
    error: null,
});

//----------------------------------------------------------------------------------------------------------
export const mitreMitigationsAdapter = createEntityAdapter<any>({
    selectId: item => item.id,
});
export interface MitreMitigationsState extends EntityState<any> {
    isLoading: boolean;
    error: any;
}

export const initialMitreMitigationsState: MitreMitigationsState = mitreMitigationsAdapter.getInitialState({
    isLoading: false,
    error: null,
});

//----------------------------------------------------------------------------------------------------------
export const mitreDatasourcesAdapter = createEntityAdapter<any>({
    selectId: item => item.id,
});
export interface MitreDatasourcesState extends EntityState<any> {
    isLoading: boolean;
    error: any;
}

export const initialMitreDatasourcesState: MitreDatasourcesState = mitreDatasourcesAdapter.getInitialState({
    isLoading: false,
    error: null,
});

//----------------------------------------------------------------------------------------------------------
export const mitreGroupsAdapter = createEntityAdapter<any>({
    selectId: item => item.id,
});
export interface MitreGroupsState extends EntityState<any> {
    isLoading: boolean;
    error: any;
}

export const initialMitreGroupsState: MitreGroupsState = mitreGroupsAdapter.getInitialState({
    isLoading: false,
    error: null,
});

export interface MitreState {
    matrix: MitreMatrixState;
    mitigations: MitreMitigationsState;
    datasources: MitreDatasourcesState;
    groups: MitreGroupsState;
}

export const initialMitreState: MitreState = {
    matrix: initialMitreMatrixState,
    mitigations: initialMitreMitigationsState,
    datasources: initialMitreDatasourcesState,
    groups: initialMitreGroupsState,
};
