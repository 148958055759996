import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, take, tap } from 'rxjs/operators';
import { Authenticate } from '../../../models/authenticate.interface';
import { AuthService } from '../../../services/auth.service';
import {
    AuthActionTypes,
    setFreshLogin,
    loginFailure,
    loginSuccess,
    tokenRefreshFailure,
    tokenRefreshSuccess,
    logoutSuccess,
    logoutFailure,
} from '../actions/auth.actions';
import { selectAuthTokens } from '../selectors/auth.selectors';
import { AuthState } from '../state/auth.state';

@Injectable()
export class AuthEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly auth: AuthService,
        private readonly router: Router,
        private readonly store: Store<AuthState>
    ) {}

    loginRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActionTypes.LOGIN_REQUEST),
            map((action: Action & Authenticate) => ({ username: action.username, password: action.password })),
            exhaustMap((auth: Authenticate) =>
                this.auth.login(auth).pipe(
                    map(tokensAndUser => loginSuccess(tokensAndUser)),
                    catchError(error => of(loginFailure({ message: error.error, status: error.status })))
                )
            )
        )
    );

    loginSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActionTypes.LOGIN_SUCCESS),
                tap(() => this.router.navigate(['/home']))
            ),
        { dispatch: false }
    );

    logoutRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActionTypes.LOGOUT_REQUEST),
            exhaustMap(() =>
                this.auth.logout().pipe(
                    map(() => logoutSuccess()),
                    catchError(error => of(loginFailure({ message: error.error, status: error.status })))
                )
            )
        )
    );

    logoutSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActionTypes.LOGOUT_SUCCESS),
                tap(() => this.router.navigate(['/login']))
            ),
        { dispatch: false }
    );

    tokenRefresh$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActionTypes.TOKEN_REFRESH_REQUEST),
            exhaustMap(() =>
                this.store.select(selectAuthTokens).pipe(
                    take(1),
                    switchMap(tokens =>
                        this.auth.refreshToken(tokens?.refresh_token || '').pipe(
                            map(newTokens => tokenRefreshSuccess({ tokens: newTokens })),
                            catchError(error => of(loginFailure({ message: error.error, status: error.status })))
                        )
                    )
                )
            )
        )
    );
}
