import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { utc } from 'moment';
import {
    ApexAnnotations,
    ApexAxisChartSeries,
    ApexChart,
    ApexFill,
    ApexGrid,
    ApexMarkers,
    ApexPlotOptions,
    ApexStroke,
    ApexTitleSubtitle,
    ApexTooltip,
    ApexXAxis,
    ApexYAxis,
    ChartComponent,
} from 'ng-apexcharts';
import { NetworkFlowData } from 'src/app/models/network-flow-data.interface';
import { UtilsService } from 'src/app/shared/utils/utils.service';

export type ChartOptions = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    title: ApexTitleSubtitle;
    stroke: ApexStroke;
    fill: ApexFill;
    markers: ApexMarkers;
    xaxis: ApexXAxis;
    yaxis: ApexYAxis;
    plotOptions: ApexPlotOptions;
    tooltip: ApexTooltip;
    annotations: ApexAnnotations;
    grid: ApexGrid;
};

@Component({
    selector: 'hypervision-syncing-charts',
    templateUrl: './syncing-charts.component.html',
    styleUrls: ['./syncing-charts.component.scss'],
})
export class SyncingChartsComponent implements OnInit {
    @ViewChild('chartTrafficIn') chartIn: ChartComponent;
    @ViewChild('chartTrafficOut') chartOut: ChartComponent;
    @ViewChild('chartTrafficTimeline') chartTimeline: ChartComponent;

    offset = 30;
    yLabelsSize = 120 + this.offset;

    onSurface = 'rgba(var(--onsurface))';

    chart1options: any;
    chart2options: any;
    chart3options: any;
    commonOptions: any = {
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'stepline', // straight, smooth, stepline
            width: 2,
            colors: ['#AAAAAA'],
        },
        toolbar: {
            tools: {
                download: false,
                selection: false,
                zoomin: false,
                zoomout: false,
                pan: false,
            },
        },
        tooltip: {
            followCursor: false,
            x: {
                show: true,
                format: 'dd MMM, HH:mm:ss',
            },
            marker: {
                show: false,
            },
            y: {
                formatter: (value: number) => this.utils.convertBytes(value),
                title: {
                    formatter: () => {
                        return '';
                    },
                },
            },
        },
        grid: {
            show: true,
            borderColor: '#CCCCCC',
            strokeDashArray: 1,
            position: 'back',
            xaxis: {
                lines: {
                    show: true,
                },
            },
        },
    };

    private optionsInInternal: NetworkFlowData | undefined = undefined;
    @Input() set optionsIn(v: NetworkFlowData | undefined) {
        this.optionsInInternal = v;
        this.updateData();
    }
    get optionsIn(): NetworkFlowData | undefined {
        return this.optionsInInternal;
    }

    @Input() optionsOutInternal: NetworkFlowData | undefined = undefined;
    @Input() set optionsOut(v: NetworkFlowData | undefined) {
        this.optionsOutInternal = v;
        this.updateData();
    }
    get optionsOut(): NetworkFlowData | undefined {
        return this.optionsOutInternal;
    }

    @Input() optionsTimelineInternal: any = undefined;
    @Input() set optionsTimeline(v: any) {
        this.optionsTimelineInternal = v;
        this.updateData();
    }
    get optionsTimeline(): any {
        return this.optionsTimelineInternal;
    }

    private resizeChangedInternal: { height: number; width: number } | undefined = undefined;
    @Input() set resizeChanged(v: { height: number; width: number } | undefined) {
        this.resizeChangedInternal = v;
        this.updateSize();
    }
    get resizeChanged(): { height: number; width: number } | undefined {
        return this.resizeChangedInternal;
    }

    @Output()
    zoomChange = new EventEmitter<{ fromDate: string; toDate: string }>();
    @Output()
    resetZoom = new EventEmitter();
    zoomBlocked = false;

    @Output()
    selectionChange = new EventEmitter<{ category: string; fromDate: string; toDate: string }>();

    minTimelineHeight: number = 300;
    minTimelineWidth: number = 950;
    realMinTimelineHeight: number = this.minTimelineHeight;
    minChart1Height: number = 100;
    minChart1Width: number = 950;
    minChart2Height: number = 100;
    minChart2Width: number = 950;

    constructor(private readonly utils: UtilsService) { }

    ngOnInit(): void {
        this.chart1options = { series: [], chart: { type: 'line' } };
        this.chart2options = { series: [], chart: { type: 'line' } };
        this.chart3options = { series: [], chart: { type: 'rangeBar' } };
    }

    private updateSize() {
        if (this.resizeChanged) {
            this.minChart1Height = this.resizeChanged.height * 0.22;
            this.minChart1Width = this.resizeChanged.width + this.offset;

            this.minChart2Height = this.resizeChanged.height * 0.22;
            this.minChart2Width = this.resizeChanged.width + this.offset;

            this.minTimelineHeight = this.resizeChanged.height * 0.45;
            this.minTimelineWidth = this.resizeChanged.width + this.offset;

            this.updateData();
        }
    }

    private updateData() {
        if (
            !!this.optionsIn &&
            !!this.optionsIn.serie &&
            !!this.optionsOut &&
            !!this.optionsOut.serie &&
            !!this.optionsTimeline &&
            this.optionsTimeline?.serie
        ) {
            this.updateSeriesFlowIn();
            this.updateSeriesFlowOut();
            this.updateSeriesTimeline();
        }
    }

    private updateSeriesFlowIn() {
        this.chart1options = this.updateSerieFlow(
            'flowIn',
            this.optionsIn?.serie,
            this.optionsIn?.title,
            this.optionsIn?.min,
            this.optionsIn?.max,
            this.optionsIn?.fromDate,
            this.optionsIn?.toDate,
            this.minChart1Height,
            this.minChart1Width,
            this.optionsIn?.markersColors,
            this.optionsIn?.gradientsColors
        );
    }

    private updateSeriesFlowOut() {
        this.chart2options = this.updateSerieFlow(
            'flowOut',
            this.optionsOut?.serie,
            this.optionsOut?.title,
            this.optionsOut?.min,
            this.optionsOut?.max,
            this.optionsOut?.fromDate,
            this.optionsOut?.toDate,
            this.minChart2Height,
            this.minChart2Width,
            this.optionsOut?.markersColors,
            this.optionsOut?.gradientsColors
        );
    }

    private updateSerieFlow(id, series, title, min, max, fromDate, toDate, minChartHeight, minCHartWidth, markersColors, gradientsColors) {
        return {
            series,
            chart: {
                id,
                group: 'network',
                type: 'line',
                height: minChartHeight,
                width: minCHartWidth,
                animations: {
                    enabled: false,
                },
                toolbar: {
                    show: false,
                    tools: {
                        download: false,
                        selection: false,
                        zoomin: false,
                        zoomout: false,
                        pan: false,
                    },
                },
            },
            markers: {
                size: 2,
                strokeWidth: 0,
                hover: {
                    size: 4,
                },
                discrete: markersColors,
            },
            xaxis: {
                type: 'datetime',
                min: fromDate,
                max: toDate,
                floating: false,
                labels: {
                    show: false,
                    format: 'dd MMM, HH:mm:ss',
                    datetimeUTC: true,
                    style: {
                        colors: this.onSurface,
                    },
                },
                tooltip: {
                    enabled: false,
                },
            },
            yaxis: {
                min: min,
                max: max,
                tickAmount: 2,
                labels: {
                    style: {
                        colors: this.onSurface,
                    },
                    minWidth: this.yLabelsSize,
                    maxWidth: this.yLabelsSize,
                    formatter: (value: number) => this.utils.convertBytes(value),
                },
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 1,
                    opacityFrom: 0.7,
                    opacityTo: 0.9,
                    colorStops: gradientsColors,
                },
            },
            title: {
                text: title,
                margin: 0,
                align: 'center',
                //text: undefined,
                floating: true,
                style: { color: this.onSurface, fontSize: '14px', fontWeight: 'bold', fontFamily: 'Roboto' },
            },
        };
    }

    private updateSeriesTimeline() {
        const heightOffset = Math.min(this.optionsTimeline?.categories.length / 10 + 0.25, 1);

        this.realMinTimelineHeight = this.minTimelineHeight * heightOffset;
        this.chart3options = {
            series: this.optionsTimeline.serie,
            chart: {
                id: 'timeline',
                group: 'network',
                type: 'rangeBar',
                height: this.minTimelineHeight * heightOffset,
                width: this.minTimelineWidth,
                animations: {
                    enabled: false,
                },

                events: {
                    click: (event, chartContext, config) => {
                        const grain = config?.config?.series[config.seriesIndex]?.data[config.dataPointIndex];
                        if (grain) {
                            this.selectionChange.emit({
                                category: grain.x,
                                fromDate: utc(grain.y[0]).toISOString(),
                                toDate: utc(grain.y[1]).toISOString(),
                            });
                        }
                    },
                    zoomed: (chartContext, { xaxis, yaxis }) => {
                        if (!this.zoomBlocked) {
                            this.zoomChange.emit({ fromDate: utc(xaxis.min).toISOString(), toDate: utc(xaxis.max).toISOString() });
                        }
                        this.zoomBlocked = false;
                    },
                    beforeZoom: (e, { xaxis }) => {
                        let zoomdifference = xaxis.max - xaxis.min;
                        if (zoomdifference < 1000) {
                            this.zoomBlocked = true;
                            // dont zoom out any further
                            return {
                                xaxis: { min: this.optionsTimeline?.fromDate, max: this.optionsTimeline?.toDate },
                            };
                        } else {
                            // keep on zooming
                            return {
                                xaxis: { min: xaxis.min, max: xaxis.max },
                            };
                        }
                    },
                },
                toolbar: {
                    show: false,
                    tools: {
                        download: false,
                        selection: false,
                        zoomin: false,
                        zoomout: false,
                        pan: false,
                    },
                },
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    barHeight: '70%',
                },
            },
            xaxis: {
                type: 'datetime',
                tickPlacement: 'on',
                tickAmount: 10,
                min: this.optionsTimeline?.fromDate,
                max: this.optionsTimeline?.toDate,
                labels: {
                    show: true,
                    format: 'dd MMM, HH:mm:ss',
                    datetimeUTC: true,
                    style: {
                        colors: this.onSurface,
                    },
                },
            },
            stroke: {
                width: 1,
                colors: ['transparent'],
                //colors: [onSurfaceColor],
            },
            fill: {
                type: 'solid',
                opacity: 1,
                //colors: ['#6a89cc'],
                colors: [
                    ({ seriesIndex, dataPointIndex, w }) => {
                        const grain = w.config.series[seriesIndex].data[dataPointIndex];

                        if (!!grain.meta) {
                            const cMin = 0;
                            const cMax = 5;
                            const x = (grain.meta.count - cMin) / (cMax - cMin) + 0.1;
                            return this.utils.getColorFromScore(grain.meta.score_max, x.toFixed(5));
                        }

                        return 'transparent';
                    },
                ],
            },
            yaxis: {
                show: true,
                labels: {
                    show: true,
                    align: 'right',
                    minWidth: this.yLabelsSize,
                    maxWidth: this.yLabelsSize,
                    style: {
                        colors: this.onSurface,
                        fontSize: '10px',
                        fontFamily: 'Roboto',
                        fontWeight: 400,
                        cssClass: 'capitalize',
                    },
                    formatter: value => {
                        const maxCharact = 22;
                        const name = this.optionsTimeline?.classifications[value]?.name;
                        let label = '';
                        if (name) {
                            label = this.utils.truncate(name, 22);
                        } else {
                            label = 'NC';
                        }

                        return label;
                    },
                },
                tooltip: {
                    enabled: false,
                },
            },
            tooltip: {
                followCursor: true,
                x: {
                    show: false,
                    format: 'dd MMM, HH:mm:ss',
                },
                y: {
                    formatter: (value, index) => {
                        let retValue = value;

                        if (!!index) {
                            const grain = index?.w.config.series[index?.seriesIndex].data[index?.dataPointIndex];
                            if (!!grain && !!grain.meta) {
                                retValue = 'Count: ' + grain?.meta?.count + ', Max score: ' + grain?.meta?.score_max;
                            }
                        }
                        return retValue;
                    },
                },
                marker: {
                    show: false,
                },
            },
            title: {
                text: this.optionsTimeline?.title,
                margin: 0,
                align: 'center',
                floating: true,
                style: {
                    color: this.onSurface,
                    fontSize: '14px',
                    fontWeight: 400,
                    fontFamily: 'Roboto',
                },
            },
            grid: {
                show: true,
                borderColor: '#CCCCCC',
                strokeDashArray: 1,
                position: 'back',
                xaxis: {
                    lines: {
                        show: true,
                    },
                },
            },
        };
    }

    rightClick(event) {
        event.preventDefault();
        this.resetZoom.emit();
    }
}
