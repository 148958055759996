import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { DictionaryService } from 'src/app/services/dictionary.service';
import { AuthActionTypes } from '../actions/auth.actions';
import {
    DictionaryActionsType,
    loadEventDictionaryFailure,
    loadEventDictionaryRequest,
    loadEventDictionarySuccess,
    loadFeatureImportanceDictionaryFailure,
    loadFeatureImportanceDictionaryRequest,
    loadFeatureImportanceDictionarySuccess,
    loadNdpiL7DictionaryFailure,
    loadNdpiL7DictionaryRequest,
    loadNdpiL7DictionarySuccess,
    loadThreatDictionaryFailure,
    loadThreatDictionarySuccess,
} from '../actions/dictionary.actions';
import { DictionaryState } from '../state/dictionary.state';

@Injectable()
export class DictionaryEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly store: Store<DictionaryState>,
        private readonly dicoService: DictionaryService
    ) {}

    init$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActionTypes.USER_AUTO_LOGGED_IN, AuthActionTypes.LOGIN_SUCCESS),
                tap(() => {
                    this.store.dispatch(loadFeatureImportanceDictionaryRequest());
                    this.store.dispatch(loadNdpiL7DictionaryRequest());
                    this.store.dispatch(loadEventDictionaryRequest());
                })
            ),
        { dispatch: false }
    );

    eventDictionnaryRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DictionaryActionsType.LOAD_EVENT_DICO_REQUEST),
            exhaustMap(() =>
                this.dicoService.getEventDico().pipe(
                    map(events => loadEventDictionarySuccess({ events })),
                    catchError(error => of(loadEventDictionaryFailure({ error })))
                )
            )
        )
    );

    threatDictionnaryRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DictionaryActionsType.LOAD_EVENT_DICO_REQUEST),
            exhaustMap(() =>
                this.dicoService.getThreatDico().pipe(
                    map(threats => loadThreatDictionarySuccess({ threats })),
                    catchError(error => of(loadThreatDictionaryFailure({ error })))
                )
            )
        )
    );

    featureImportanceDictionaryRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DictionaryActionsType.LOAD_FI_DICO_REQUEST),
            exhaustMap(() =>
                this.dicoService.getFeatureImportanceDico().pipe(
                    map(features_importance => loadFeatureImportanceDictionarySuccess({ features_importance })),
                    catchError(error => of(loadFeatureImportanceDictionaryFailure({ error })))
                )
            )
        )
    );

    ndpiL7DictionaryRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DictionaryActionsType.LOAD_NDPIL7_DICO_REQUEST),
            exhaustMap(() =>
                this.dicoService.getNdpiL7Dico().pipe(
                    map(ndpi_l7 => loadNdpiL7DictionarySuccess({ ndpi_l7 })),
                    catchError(error => of(loadNdpiL7DictionaryFailure({ error })))
                )
            )
        )
    );
}
