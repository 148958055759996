import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../models/api-response.interface';
import { Attack } from '../models/attacks.interface';
import { TreeNodeAttack } from '../models/tree-node-attacks.interface';
import { AttackReason } from '../models/attack-reason.interface';
import { ReportWidget } from '../report/models/report-widget.interface';

@Injectable({
    providedIn: 'root',
})
export class AttacksDataService {
    constructor(@Inject(LOCALE_ID) protected localeId: string, private readonly http: HttpClient) { }

    getAttacksTree(): Observable<TreeNodeAttack[]> {
        return this.http
            .get<ApiResponse<TreeNodeAttack[]>>(environment.bckAPI.attackAPIs.attackTreeUrl)
            .pipe(map(response => response.result));
    }

    getAttacks(fromDate: string, toDate: string): Observable<Attack[]> {
        const formData = new FormData();

        if (fromDate && toDate) {
            formData.append('from_date', fromDate.replace('Z', ''));
            formData.append('to_date', toDate.replace('Z', ''));
        }

        return this.http.post<ApiResponse<Attack[]>>(environment.bckAPI.attackAPIs.attackUrl, formData).pipe(map(r => r.result));
    }

    getAttacksNew(fromDate: string, toDate: string): Observable<Attack[]> {
        const formData = new FormData();

        if (fromDate && toDate) {
            formData.append('from_date', fromDate.replace('Z', ''));
            formData.append('to_date', toDate.replace('Z', ''));
        }

        return this.http.post<ApiResponse<Attack[]>>(environment.bckAPI.attackAPIs.attackUrl, formData).pipe(map(r => r.result));
    }

    getAttacksClosed(fromDate: string, toDate: string) {
        let params = '?lang=' + this.localeId;
        if (fromDate) {
            params += '&from_date=' + fromDate.replace('Z', '');
        }
        if (toDate) {
            params += '&to_date=' + toDate.replace('Z', '');
        }
        return this.http
            .get<ApiResponse<any>>(environment.bckAPI.attackAPIs.attackClosedUrl + params)
            .pipe(map(response => response.result));
    }

    getSOCWorkload(fromDate: string, toDate: string) {
        let params = '?lang=' + this.localeId;
        if (fromDate) {
            params += '&from_date=' + fromDate.replace('Z', '');
        }
        if (toDate) {
            params += '&to_date=' + toDate.replace('Z', '');
        }
        return this.http
            .get<ApiResponse<any>>(environment.bckAPI.attackAPIs.socWorkloadUrl + params)
            .pipe(map(response => response.result));
    }



    getWidgetAttacksClosed(fromDate: string, toDate: string, widget: ReportWidget) {
        let params = '?lang=' + this.localeId;
        if (fromDate) {
            params += '&from_date=' + fromDate.replace('Z', '');
        }
        if (toDate) {
            params += '&to_date=' + toDate.replace('Z', '');
        }
        return this.http
            .get<ApiResponse<any>>(environment.bckAPI.attackAPIs.attackClosedUrl + params)
            .pipe(map(response => ({ ...widget, data: response.result })));
    }

    getAttacksStatusCounter(fromDate: string, toDate: string) {
        let params = '?lang=' + this.localeId;
        if (fromDate) {
            params += '&from_date=' + fromDate.replace('Z', '');
        }
        if (toDate) {
            params += '&to_date=' + toDate.replace('Z', '');
        }
        return this.http
            .get<ApiResponse<any>>(environment.bckAPI.attackAPIs.attacksStatusCounter + params)
            .pipe(map(response => response.result));
    }

    getWidgetAttacksStatusCounter(fromDate: string, toDate: string, widget: ReportWidget) {
        let params = '?lang=' + this.localeId;
        if (fromDate) {
            params += '&from_date=' + fromDate.replace('Z', '');
        }
        if (toDate) {
            params += '&to_date=' + toDate.replace('Z', '');
        }
        return this.http
            .get<ApiResponse<any>>(environment.bckAPI.attackAPIs.attacksStatusCounter + params)
            .pipe(map(response => ({ ...widget, data: response.result })));
    }

    getAttack(attackId: string): Observable<any> {
        return this.http.get<ApiResponse<any>>(environment.bckAPI.attackAPIs.attackUrl + attackId).pipe(map(r => r.result));
    }

    attackCountFromThreashold(
        fromDate: string,
        toDate: string,
        low: number,
        medium: number,
        high: number,
        critical: number,
        status: number | undefined = undefined
    ): Observable<any> {
        const formData = new FormData();
        if (fromDate && toDate) {
            formData.append('from_date', fromDate.replace('Z', ''));
            formData.append('to_date', toDate.replace('Z', ''));
        }
        formData.append('low', low + '');
        formData.append('medium', medium + '');
        formData.append('high', high + '');
        formData.append('critical', critical + '');
        if (!!status) {
            formData.append('status', status + '');
        }

        return this.http
            .post<ApiResponse<any>>(environment.bckAPI.attackAPIs.attackCountFromThreasholdUrl, formData)
            .pipe(map(r => r.result));
    }

    attackCountFromThreasholdWidget(
        widget: ReportWidget,
        fromDate: string,
        toDate: string,
        low: number,
        medium: number,
        high: number,
        critical: number
    ): Observable<any> {
        const formData = new FormData();
        if (fromDate && toDate) {
            formData.append('from_date', fromDate.replace('Z', ''));
            formData.append('to_date', toDate.replace('Z', ''));
        }
        formData.append('low', low + '');
        formData.append('medium', medium + '');
        formData.append('high', high + '');
        formData.append('critical', critical + '');

        return this.http
            .post<ApiResponse<any>>(environment.bckAPI.attackAPIs.attackCountFromThreasholdUrl, formData)
            .pipe(map(r => ({ ...widget, data: r.result })));
    }

    getAttackDiagram(attackId: string): Observable<any> {
        return this.http.get<ApiResponse<any>>(environment.bckAPI.attackAPIs.attackDiagramUrl + attackId).pipe(map(r => r.result));
    }

    getAttackThreats(attackId: string): Observable<any> {
        return this.http.get<ApiResponse<any>>(environment.bckAPI.attackAPIs.attackThreatsUrl + attackId).pipe(map(r => r.result));
    }

    postAttack(newAttack: TreeNodeAttack) {
        const formData = new FormData();
        formData.append('score', newAttack.score.toFixed());
        if (!!newAttack.comment) {
            formData.append('comment', newAttack.comment);
        }
        if (!!newAttack.impact) {
            formData.append('impact', newAttack.impact);
        }
        if (!!newAttack.qualification) {
            formData.append('qualification', newAttack.qualification);
        }
        if (!!newAttack.status) {
            formData.append('status', newAttack.status.toFixed());
        }
        return this.http.post<ApiResponse<{ id: string }>>(environment.bckAPI.attackAPIs.attackUrl, formData).pipe(map(r => r.result));
    }

    changeAttackStatus(attackId: string, newStatus: number, reason: number, remarks: any) {
        const formData = new FormData();
        formData.append('new_status', newStatus + '');
        formData.append('reason_id', reason + '');
        if (!!remarks) {
            formData.append('reason_details', remarks);
        }
        return this.http.patch(environment.bckAPI.attackAPIs.attackStatusUrl + attackId, formData);
    }

    // Mise à jour d'une attaque
    patchAttack(newAttack: TreeNodeAttack) {
        const formData = new FormData();
        formData.append('score', newAttack.score.toFixed());
        if (!!newAttack.comment) {
            formData.append('comment', newAttack.comment);
        }
        if (!!newAttack.impact) {
            formData.append('impact', newAttack.impact);
        }
        if (!!newAttack.qualification) {
            formData.append('qualification', newAttack.qualification);
        }
        if (!!newAttack.status) {
            formData.append('status', newAttack.status.toFixed());
        }
        return this.http.patch(environment.bckAPI.attackAPIs.attackUrl + newAttack.id, formData);
    }

    /// Suppression d'une attaque à partir de son ID
    deleteAttack(id: string) {
        return this.http.delete(environment.bckAPI.attackAPIs.attackUrl + id);
    }

    getAttackReasonList() {
        return this.http
            .get<ApiResponse<AttackReason[]>>(environment.bckAPI.attackAPIs.attackReasonUrl + '?lang=' + this.localeId)
            .pipe(map(response => response.result));
    }

    getAttacksMttr(fromDate: string, toDate: string) {
        let params = '?lang=' + this.localeId;
        if (fromDate) {
            params += '&from_date=' + fromDate.replace('Z', '');
        }
        if (toDate) {
            params += '&to_date=' + toDate.replace('Z', '');
        }
        return this.http
            .get<ApiResponse<any>>(environment.bckAPI.attackAPIs.attackMttrUrl + params)
            .pipe(map(response => response.result));
    }
}
