<div class="fixed top-0 right-0 bottom-0 left-0 m-auto z-50" [style.height]="diameter + 'px'" [style.width]="diameter + 'px'">
    <ejs-progressbar
        id="indeterminate"
        type="Circular"
        [height]="diameter + ''"
        [width]="diameter + ''"
        [value]="20"
        [isIndeterminate]="true"
        [animation]="{ enable: true, duration: 2000, delay: 0 }"
    >
    </ejs-progressbar>
</div>
