import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Dictionary } from '@ngrx/entity';
import { Store, select } from '@ngrx/store';
import { ContextMenuItemModel } from '@syncfusion/ej2-angular-grids';
import { RuleModel } from '@syncfusion/ej2-angular-querybuilder';
import { UUID } from 'angular2-uuid';
import { IPv4, IPv4CidrRange, IPv6CidrRange, Validator } from 'ip-num';
import { cloneDeep } from 'lodash';
import moment, { utc } from 'moment';
import { Observable, filter, lastValueFrom, take } from 'rxjs';
import { Analyzer } from 'src/app/models/analyzer.interface';
import { AttackReason } from 'src/app/models/attack-reason.interface';
import { AvailableFilter } from 'src/app/models/available-filter.interface';
import { Criticity } from 'src/app/models/criticity.interface';
import { FilterOperator } from 'src/app/models/filter-operator.enum';
import { FilterType } from 'src/app/models/filter-type.enum';
import { Filter, FilterScope } from 'src/app/models/filter.interface';
import { ClassificationTreeNode } from 'src/app/models/score-class-node.interface';
import { ThemeLuminosity } from 'src/app/models/theme-luminosity.interface';
import { ThreatView } from 'src/app/models/threats-view.interface';
import { TimeSelection } from 'src/app/models/time-selection.interface';
import { TreeNodeAttack } from 'src/app/models/tree-node-attacks.interface';
import { TreeNode } from 'src/app/models/treenode.interface';
import { User } from 'src/app/models/user.interface';
import { HistoryData } from '../../models/history-data.interface';
import { TimeType } from '../../models/time-selection.interface';
import { addActiveFilter, removeActiveFilterByScope, updateActiveFilter } from '../store/actions/filters.actions';
import {
    addHistoryEntry,
    editCurrentHistoryEntry,
    loadCurrentHistoryState,
    setCurrentHistoryEntry,
} from '../store/actions/history-list.action';
import { selectAnalyzerEntities } from '../store/selectors/analyzer.selectors';
import { selectAttackReasonEntities } from '../store/selectors/attack-reason.selectors';
import { selectFilterByType } from '../store/selectors/filters.selectors';
import { selectCurrentHistoryEntry } from '../store/selectors/history-list.selectors';
import { selectClassificationTreeEntities } from '../store/selectors/scoring.selectors';
import { selectCriticity, selectDefaultTimeSelection, selectThemeLuminosityParams, selectThemeParams } from '../store/selectors/settings.selectors';
import { AppState } from '../store/state/app.state';
import { Theme } from 'src/app/models/theme.interface';
import { FilterDashboardType } from 'src/app/models/filter-dashboard-type.enum';
import { AnalyzerEnum } from 'src/app/models/analyzer.enum';
import { WhitelistRuleIpType, WhitelistRuleIpType_FromDB } from 'src/app/whitelist/models/whitelist-rule-ip-type';

@Injectable({
    providedIn: 'root',
})
export class UtilsService {
    public orderedTactics: string[] = [
        'TA0043',
        //'TA0042',
        'TA0001',
        //'TA0002',
        //'TA0003',
        //'TA0004',
        //'TA0005',
        'TA0006',
        'TA0007',
        'TA0008',
        'TA0009',
        'TA0011',
        'TA0010',
        'TA0040',
    ];

    public echartsColor: string[] = [
        '#3498DB', // Peter River (Flat UI) - Bleu
        '#2ECC71', // Emerald (Flat UI) - Vert
        '#FFA500', // Orange - Orange alternatif
        '#C0392B', // Pomegranate (Flat UI) - Rouge alternatif
        '#8E44AD', // Wisteria (Flat UI) - Violet
        '#16A085', // Green Sea (Flat UI) - Vert
        '#D35400', // Pumpkin (Flat UI) - Orange
        '#1ABC9C', // Turquoise (Flat UI) - Turquoise
        '#E67E22', // Carrot (Flat UI) - Orange
        '#9B59B6', // Amethyst (Flat UI) - Violet
        '#27AE60', // Nephritis (Flat UI) - Vert
        '#F1C40F', // Sun Flower (Flat UI) - Jaune
    ];

    private readonly criticity$: Observable<Criticity>;
    criticity: Criticity;

    private readonly themeLuminosityParams$: Observable<ThemeLuminosity>;
    themeLuminosityParams: ThemeLuminosity;

    private readonly themeParams$: Observable<Theme>;
    themeParams: Theme;

    private readonly classificationsTree$: Observable<Dictionary<ClassificationTreeNode>>;
    classificationsTree: Dictionary<ClassificationTreeNode>;

    private readonly analyzersTree$: Observable<Dictionary<Analyzer>>;
    analyzersTree: Dictionary<Analyzer>;

    private readonly attackReasonsTree$: Observable<Dictionary<AttackReason>>;
    attackReasonsTree: Dictionary<AttackReason>;

    constructor(private readonly store: Store<AppState>, private readonly router: Router, private readonly ngZone: NgZone) {
        this.criticity$ = this.store.pipe(select(selectCriticity)).pipe(take(1));
        this.criticity$.subscribe(v => (this.criticity = v));

        this.themeLuminosityParams$ = this.store.pipe(select(selectThemeLuminosityParams));
        this.themeLuminosityParams$.subscribe(v => (this.themeLuminosityParams = v));

        this.themeParams$ = this.store.pipe(select(selectThemeParams));
        this.themeParams$.subscribe(v => (this.themeParams = v));

        this.classificationsTree$ = this.store.pipe(select(selectClassificationTreeEntities));
        this.classificationsTree$.subscribe(v => (this.classificationsTree = v));

        this.analyzersTree$ = this.store.pipe(select(selectAnalyzerEntities));
        this.analyzersTree$.subscribe(v => (this.analyzersTree = v));

        this.attackReasonsTree$ = this.store.pipe(select(selectAttackReasonEntities));
        this.attackReasonsTree$.subscribe(v => (this.attackReasonsTree = v));
    }

    isObjectEmpty(objectName) {
        return Object.keys(objectName)?.length === 0;
    }

    truncate(texte: string | undefined, max: number): string {
        if (!!texte) {
            return texte.length > max ? texte.substring(0, max) + '...' : texte;
        }

        return '';
    }

    initNewHistoryEntry(
        url: string,
        data?: {
            [key: string]: {
                [key: string]: unknown;
            };
        }
    ) {
        this.store.dispatch(
            addHistoryEntry({
                newEntry: {
                    id: UUID.UUID(),
                    route: url,
                    date: Date.now(),
                    componentData: data ? data : {},
                    locked: data ? true : false,
                },
            })
        );
    }

    async navigateWithBack(url: string[], filters?: Filter[], state?: { [k: string]: any }) {
        const currentHistoryEntry = await lastValueFrom(this.store.select(selectCurrentHistoryEntry).pipe(take(1)));
        this.ngZone.run(() => {
            this.router.navigate(url, { state }).then(v => {
                this.store.dispatch(editCurrentHistoryEntry({ editedEntry: { backId: currentHistoryEntry?.id } }));

                this.removeViewFilters();
                filters?.forEach(f => {
                    this.createViewFilter(f.type, f.field, f.operator, f.value);

                    // if (f.scope === FilterScope.view && f.type === FilterType.DATE_RANGE) {
                    //     this.changeTimeSelectionEdition(false);
                    // }
                });
            });
        });
    }

    isCIDRIncluded(subnet: string, supernet: string): boolean {
        if (!subnet || !supernet) {
            return false;
        }

        try {
            const ipv4Range = IPv4CidrRange.fromCidr(subnet);
            const ipv4Range_toCheck = IPv4CidrRange.fromCidr(supernet);
            return ipv4Range.isEquals(ipv4Range_toCheck) || ipv4Range.contains(ipv4Range_toCheck);
        } catch (error: any) {
            // Gestion de l'erreur
            console.error("Erreur attrapée :", error.message);
            console.error("subnet :", subnet);
            console.error("supernet :", supernet);
        }

        return false;
    }

    isIpIncluded(subnet: string, ip: string): boolean {
        if (!subnet || !ip) {
            return false;
        }

        try {
            const ipv4Range = IPv4CidrRange.fromCidr(subnet);
            const ipv4Range_toCheck = IPv4.fromDecimalDottedString(ip);

            return ipv4Range_toCheck.isGreaterThanOrEquals(ipv4Range.getFirst()) && ipv4Range_toCheck.isLessThanOrEquals(ipv4Range.getLast());
        } catch (error: any) {
            // Gestion de l'erreur
            console.error("Erreur attrapée :", error.message);
            console.error("subnet :", subnet);
            console.error("supernet :", ip);
        }

        return false;
    }


    /**
        * Vérifie si une adresse IPv4 est privée.
        * @param ip - L'adresse IPv4 à vérifier.
        * @returns True si l'adresse est privée, sinon False.
        */
    isPrivateIPv4(ip: string): boolean {
        const privateRanges = [
            '10.0.0.0/8',
            '172.16.0.0/12',
            '192.168.0.0/16',
            '169.254.0.0/16', // Link-local addresses
            '127.0.0.0/8' // Loopback addresses
        ];

        return privateRanges.some(range => this.isIpIncluded(range, ip));
    }

    /**
     * Vérifie si une adresse IPv4 est valide.
     * @param ip - L'adresse IPv4 à vérifier.
     * @returns True si l'adresse est valide, sinon False.
     */
    isValidIPv4(ip: string): boolean {
        return Validator.isValidIPv4String(ip)[0];
    }

    /**
     * Vérifie si une adresse IP est publique.
     * @param ip - L'adresse IP à vérifier.
     * @returns True si l'adresse est publique, sinon False.
     */
    isPublicIP(ip: string): boolean {
        if (this.isValidIPv4(ip)) {
            return !this.isPrivateIPv4(ip);
        } else {
            throw new Error('Invalid IP address format');
        }
    }

    loadHistoryEntry(entry: HistoryData) {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        if (entry.state) {
            this.store.dispatch(loadCurrentHistoryState({ state: JSON.parse(entry.state) as AppState }));
        }
        this.store.dispatch(setCurrentHistoryEntry({ entryId: entry.id }));
        this.router.navigateByUrl(entry.route);
    }

    hexToRgb(hex: string) {
        const bigint = parseInt(hex.replace('#', ''), 16);

        /* eslint-disable no-bitwise */
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        /* eslint-enable no-bitwise */

        return r + ',' + g + ',' + b;
    }

    rgbToHex(rgb: string) {
        return (
            '#' +
            rgb
                .split(',')
                .map(v => (+v).toString(16).padStart(2, '0'))
                .join('')
        );
    }

    rgbaToHex(orig) {
        var a,
            rgb = orig.replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i),
            alpha = ((rgb && rgb[4]) || '').trim(),
            hex = rgb
                ? (rgb[1] | (1 << 8)).toString(16).slice(1) +
                (rgb[2] | (1 << 8)).toString(16).slice(1) +
                (rgb[3] | (1 << 8)).toString(16).slice(1)
                : orig;

        return hex;
    }

    getScoreRangeLabelFromScore(score) {
        if (!!this.criticity) {
            if (score >= this.criticity.critical) {
                return 'critical';
            } else if (score >= this.criticity.high) {
                return 'high';
            } else if (score >= this.criticity.medium) {
                return 'medium';
            } else if (score >= this.criticity.low) {
                return 'low';
            } else {
                return 'none';
            }
        }

        return 'none';
    }

    getScoreRangeLabelFromAttackScore(score) {
        if (!!this.criticity) {
            if (score >= this.criticity.attack_critical) {
                return 'critical';
            } else if (score >= this.criticity.attack_high) {
                return 'high';
            } else if (score >= this.criticity.attack_medium) {
                return 'medium';
            } else if (score >= this.criticity.attack_low) {
                return 'low';
            } else {
                return 'none';
            }
        }

        return 'none';
    }

    getClassIconFromScore(score: number, notColored: boolean = false) {
        if (!!this.criticity) {
            if (score >= this.criticity.critical) {
                return 'thermo-critical' + (notColored ? '' : ' bg-critical');
            } else if (score >= this.criticity.high) {
                return 'thermo-high' + (notColored ? '' : ' bg-high');
            } else if (score >= this.criticity.medium) {
                return 'thermo-medium' + (notColored ? '' : ' bg-medium');
            } else {
                return 'thermo-low' + (notColored ? '' : ' bg-low');
            }
        }

        return '';
    }

    getClassIconFromAttackScore(score: number, notColored: boolean = false) {
        if (!!this.criticity) {
            if (score >= this.criticity.attack_critical) {
                return 'thermo-critical' + (notColored ? '' : ' bg-critical');
            } else if (score >= this.criticity.attack_high) {
                return 'thermo-high' + (notColored ? '' : ' bg-high');
            } else if (score >= this.criticity.attack_medium) {
                return 'thermo-medium' + (notColored ? '' : ' bg-medium');
            } else {
                return 'thermo-low' + (notColored ? '' : ' bg-low');
            }
        }

        return '';
    }

    getColorBgClassNameFromScore(score: number, alpha: string = '') {
        if (!!this.criticity) {
            if (score >= this.criticity.critical) {
                return ' bg-critical' + alpha;
            } else if (score >= this.criticity.high) {
                return ' bg-high' + alpha;
            } else if (score >= this.criticity.medium) {
                return ' bg-medium' + alpha;
            } else if (score >= this.criticity.low) {
                return ' bg-low' + alpha;
            } else {
                return ' bg-nonecol';
            }
        }

        return '';
    }

    getColorFillFromScore(score: number, alpha: string = '') {
        if (!!this.criticity) {
            if (score >= this.criticity.critical) {
                return ' fill-critical' + alpha;
            } else if (score >= this.criticity.high) {
                return ' fill-high' + alpha;
            } else if (score >= this.criticity.medium) {
                return ' fill-medium' + alpha;
            } else if (score >= this.criticity.low) {
                return ' fill-low' + alpha;
            } else {
                return ' fill-nonecol' + alpha;
            }
        }

        return ' fill-nonecol' + alpha;
    }

    getColorStrokeFromScore(score: number) {
        if (!!this.criticity) {
            if (score >= this.criticity.critical) {
                return ' stroke-critical';
            } else if (score >= this.criticity.high) {
                return ' stroke-high';
            } else if (score >= this.criticity.medium) {
                return ' stroke-medium';
            } else if (score >= this.criticity.low) {
                return ' stroke-low';
            } else {
                return ' stroke-nonecol';
            }
        }

        return ' stroke-nonecol';
    }

    getColorBgClassNameFromAttackScore(score: number, alpha: string = '') {
        if (!!this.criticity) {
            if (score >= this.criticity.attack_critical) {
                return ' bg-critical' + alpha;
            } else if (score >= this.criticity.attack_high) {
                return ' bg-high' + alpha;
            } else if (score >= this.criticity.attack_medium) {
                return ' bg-medium' + alpha;
            } else if (score >= this.criticity.attack_low) {
                return ' bg-low' + alpha;
            } else {
                return ' bg-nonecol';
            }
        }

        return '';
    }

    getColorTextClassNameFromScore(score: number | undefined) {
        if (!!score) {
            if (!!this.criticity) {
                if (score >= this.criticity.critical) {
                    return ' text-critical';
                } else if (score >= this.criticity.high) {
                    return ' text-high';
                } else if (score >= this.criticity.medium) {
                    return ' text-medium';
                } else if (score >= this.criticity.low) {
                    return ' text-low';
                } else {
                    return ' text-nonecol';
                }
            }
        }

        return '';
    }

    getColorTextClassNameFromAttackScore(score: number | undefined) {
        if (score !== undefined) {
            if (!!this.criticity) {
                if (score >= this.criticity.attack_critical) {
                    return ' text-critical';
                } else if (score >= this.criticity.attack_high) {
                    return ' text-high';
                } else if (score >= this.criticity.attack_medium) {
                    return ' text-medium';
                } else {
                    return ' text-low';
                }
            }
        }

        return ' text-nonecol';
    }

    getBorderFromScore(score: number) {
        if (!!this.criticity) {
            if (score >= this.criticity.critical) {
                return ' border-critical';
            } else if (score >= this.criticity.high) {
                return ' border-high';
            } else if (score >= this.criticity.medium) {
                return ' border-medium';
            } else if (score >= this.criticity.low) {
                return ' border-low';
            } else {
                return ' border-nonecol';
            }
        }

        return '';
    }

    getOutlineFromScore(score: number) {
        if (!!this.criticity) {
            if (score >= this.criticity.critical) {
                return ' outline-critical';
            } else if (score >= this.criticity.high) {
                return ' outline-high';
            } else if (score >= this.criticity.medium) {
                return ' outline-medium';
            } else if (score >= this.criticity.low) {
                return ' outline-low';
            } else {
                return ' outline-nonecol';
            }
        }

        return '';
    }

    getBorderFromAttackScore(score: number) {
        if (!!this.criticity) {
            if (score >= this.criticity.attack_critical) {
                return ' border-critical';
            } else if (score >= this.criticity.attack_high) {
                return ' border-high';
            } else if (score >= this.criticity.attack_medium) {
                return ' border-medium';
            } else if (score >= this.criticity.attack_low) {
                return ' border-low';
            } else {
                return ' border-nonecol';
            }
        }

        return '';
    }

    getStatusIcon(status: number) {
        switch (status) {
            case 0:
                return 'settings';
            case 1:
                return 'racing-flag';

            default:
                return '';
        }
    }

    getAssetIconFromType(assetType: string | undefined) {
        if (!!assetType) {
            switch (assetType?.toLowerCase()) {
                case 'pc':
                case 'asset':
                    return 'at-computer';
                case 'routeur':
                case 'router':
                    return 'at-router2';
                case 'network_device':
                    return 'at-router2';
                case 'network':
                    return 'at-switch';
                case 'server':
                    return 'at-server';
                case 'interface':
                    return 'at-interface';
                case 'service':
                    return 'at-service';
                case 'firewall':
                    return 'at-firewall';
                case 'internet':
                    return 'earth';
                case 'aggregate':
                    return 'ckc-availability';
                default:
                    return '';
            }
        }
        return '';
    }

    getAssetIconSvgFromType(assetType: string | undefined) {
        if (!!assetType) {
            switch (assetType?.toLowerCase()) {
                case 'pc':
                case 'asset':
                    return 'image:///assets/icons/assets/at-computer.svg';
                case 'routeur':
                case 'network_device':
                    return 'image:///assets/icons/assets/at-router2.svg';
                case 'network':
                    return 'image:///assets/icons/assets/at-switch.svg';
                case 'server':
                    return 'image:///assets/icons/assets/at-server.svg';
                case 'interface':
                    return 'image:///assets/icons/assets/at-interface.svg';
                case 'service':
                    return 'image:///assets/icons/assets/at-service.svg';
                case 'firewall':
                    return 'image:///assets/icons/assets/at-firewall.svg';
                case 'internet':
                    return 'image:///assets/icons/earth.svg';
                default:
                    return '';
            }
        }
        return '';
    }

    getAssetIconSvgFromType2(assetType: string | undefined) {
        if (!!assetType) {
            switch (assetType?.toLowerCase()) {
                case 'pc':
                case 'asset':
                    return '/assets/icons/assets/at-computer.svg';
                case 'routeur':
                case 'network_device':
                    return '/assets/icons/assets/at-router2.svg';
                case 'network':
                    return '/assets/icons/assets/at-switch.svg';
                case 'server':
                    return '/assets/icons/assets/at-server.svg';
                case 'interface':
                    return '/assets/icons/assets/at-interface.svg';
                case 'service':
                    return '/assets/icons/assets/at-service.svg';
                case 'firewall':
                    return '/assets/icons/assets/at-firewall.svg';
                case 'internet':
                    return '/assets/icons/earth.svg';
                default:
                    return '';
            }
        }
        return '';
    }

    getCharacterFromObjectType(objectType: string) {
        switch (objectType.toLowerCase()) {
            case 'threat':
                return 't';
            case 'event':
                return 'e';
            case 'event_group':
                return 'g';
            case 'attack':
                return 'a';
            default:
                return '';
        }
    }

    getCharacterFromAssetType(assetType: string) {
        if (!!assetType) {
            switch (assetType?.toLowerCase()) {
                case 'pc':
                case 'asset':
                    return 'c';
                case 'routeur':
                case 'network_device':
                    return 'r';
                case 'network':
                    return 'n';
                case 'server':
                    return 's';
                case 'interface':
                    return 'i';
                case 'firewall':
                    return 'f';
                case 'internet':
                    return 'x';
                case 'location-error':
                    return 'l';
                default:
                    return 'u';
            }
        }
        return 'u';
    }

    getCharacterFromType(type: number) {
        if (type === 2) return 'c';
        else if (type === 1) return 'r';
        else if (type === 3) return 'a';
        else if (type === 4) return 't';
        return 'u';
    }

    getColorFromScore(score: number, alpha = '1') {
        if (!!this.criticity) {
            if (score >= this.criticity.critical) {
                return 'rgba(' + this.themeLuminosityParams.critical + ', ' + alpha + ')';
            } else if (score >= this.criticity.high) {
                return 'rgba(' + this.themeLuminosityParams.high + ', ' + alpha + ')';
            } else if (score >= this.criticity.medium) {
                return 'rgba(' + this.themeLuminosityParams.medium + ', ' + alpha + ')';
            } else if (score >= this.criticity.low) {
                return 'rgba(' + this.themeLuminosityParams.low + ', ' + alpha + ')';
            }
        }

        return 'rgba(' + this.themeLuminosityParams.none + ', ' + alpha + ')';
    }

    getColorFromAttackScore(score: number, alpha = '1') {
        if (!!this.criticity) {
            if (score >= this.criticity.attack_critical) {
                return 'rgba(' + this.themeLuminosityParams.critical + ', ' + alpha + ')';
            } else if (score >= this.criticity.attack_high) {
                return 'rgba(' + this.themeLuminosityParams.high + ', ' + alpha + ')';
            } else if (score >= this.criticity.attack_medium) {
                return 'rgba(' + this.themeLuminosityParams.medium + ', ' + alpha + ')';
            } else {
                return 'rgba(' + this.themeLuminosityParams.low + ', ' + alpha + ')';
            }
        }

        return 'rgba(' + this.themeLuminosityParams.none + ', ' + alpha + ')';
    }

    getClassIconFromType(type: string) {
        if (type === 'attack') {
            return 'attack';
        } else if (type === 'step-attack') {
            return 'step-attack';
        } else if (type === 'threat') {
            return 'threat';
        }

        return '';
    }

    getIconFromAnalyzer(analyzer: AnalyzerEnum) {
        let iconClass = "";
        if (analyzer !== undefined) {
            switch (analyzer) {
                // Suricata
                case AnalyzerEnum.SURICATA: return "suricata";
                // Custocy MetaLearner
                case AnalyzerEnum.METALEARNER: return "metalearner";
                // Zeek
                case AnalyzerEnum.ZEEK: return "zeek";
                // Custocy Asset Anomaly Detection
                case AnalyzerEnum.BEHAVIOR: return "behavior";
                // Custocy Asset Anomaly Detection
                case AnalyzerEnum.CANDC: return "candc";

                default: return "analyzer-unknown";
            }

        }
        return iconClass;
    }

    getBgColorFromAnalyzer(analyzer: AnalyzerEnum) {
        let iconClass = "rgb(125,125,125)";
        if (analyzer !== undefined) {
            switch (analyzer) {
                // Suricata
                case AnalyzerEnum.SURICATA: return "rgb(196,229,56)";
                // Custocy MetaLearner
                case AnalyzerEnum.METALEARNER: return "rgb(40,184,206)";
                // Zeek
                case AnalyzerEnum.ZEEK: return "rgb(32,65,108)";
                // Custocy Asset Anomaly Detection
                case AnalyzerEnum.BEHAVIOR: return "rgb(238,44,130)";
                // Command and Control
                case AnalyzerEnum.CANDC: return "rgb(131,52,113)";
            }
        }
        return iconClass;
    }

    isEventWhitelisted(event: any, whitelistRules: any[]): boolean {
        for (const rule of whitelistRules) {
            // Vérifier si les champs de la règle correspondent aux champs de l'événement
            let match = true;

            const fieldsToCheck: any[] = [
                "analyzer",
                "dst_ip",
                "dst_port",
                "src_ip",
                "src_port",
                "proto_4",
            ];

            if (rule.analyzer === 1) {
                // Si l'analyzer est égal à 1, comparer le champ suricata_rule_id
                fieldsToCheck.push("suricata_rule_id");
            } else {
                // Sinon, comparer le champ event_type_id
                fieldsToCheck.push("event_type_id");
            }

            for (const fieldName of fieldsToCheck) {
                // Récupérer la valeur de l'attribut dans l'objet event
                const eventValue = event[fieldName];
                // Récupérer la valeur de l'attribut dans l'objet de règle de whitelist
                const ruleValue = rule[fieldName];
                // Si la valeur de la règle est non-null et ne correspond pas à la valeur de l'événement, il n'y a pas de correspondance
                if (ruleValue !== null && eventValue !== ruleValue) {
                    match = false;
                    break;
                }
            }
            // Si tous les champs non-null de la règle correspondent, l'événement doit être whitelisté
            if (match) {
                return true;
            }
        }
        // Aucune règle ne correspond à l'événement
        return false;
    }

    async initTemporalFilter() {
        const defaultTimeSelection = await lastValueFrom(this.store.select(selectDefaultTimeSelection).pipe(take(1)));

        let minTime = new Date(defaultTimeSelection.minTime).toISOString();
        let maxTime = new Date(defaultTimeSelection.maxTime).toISOString();

        if (defaultTimeSelection.timeType === TimeType.customPeriod) {
            // Si on est en mode "Last X time", on recalcule le min et le max date
            const maxTimeDate = moment(defaultTimeSelection.maxTime);
            minTime = maxTimeDate.subtract(defaultTimeSelection.periodValue, defaultTimeSelection.periodUnit).toDate().toISOString();
            maxTime = new Date().toISOString();
        }

        const defaultTimeSelectionSanitized: TimeSelection = { ...defaultTimeSelection, minTime, maxTime, };

        this.store.dispatch(
            addActiveFilter({
                newFilter: {
                    id: UUID.UUID(),
                    field: 'Time selection',
                    operator: FilterOperator.equal,
                    type: FilterType.DATE_RANGE,
                    value: defaultTimeSelectionSanitized,
                    scope: FilterScope.global,
                    isDeletable: false,
                    isEditable: true,
                },
            })
        );
    }

    /**
     * Récupère les dates min et max de la temporalité
     * @param filters Listes des filtres de l'application
     */
    getTemporalFilterDate(filters: Filter[]): { fromDate: string; toDate: string } {
        let fromDate = '';
        let toDate = '';

        if (filters && filters.length > 0) {
            let tempFilter = filters.find(
                v => v.type === FilterType.DATE_RANGE && (v.scope === FilterScope.view || v.scope === FilterScope.widget)
            );
            if (tempFilter) {
                fromDate = (tempFilter.value as TimeSelection).minTime;
                toDate = (tempFilter.value as TimeSelection).maxTime;
            } else {
                tempFilter = filters.find(v => v.type === FilterType.DATE_RANGE);
                if (tempFilter) {
                    fromDate = (tempFilter.value as TimeSelection).minTime;
                    toDate = (tempFilter.value as TimeSelection).maxTime;
                    if (tempFilter?.value?.timeType === TimeType.customPeriod) {
                        return this.getDatesFromPeriod(tempFilter.value.periodValue, tempFilter.value.periodUnit);
                    }
                }
            }
        }

        return { fromDate, toDate };
    }

    getDatesFromPeriod(periodValue, periodUnit) {
        const toDateRaw = utc(Date.now());
        const fromDateRaw = utc(Date.now());
        fromDateRaw.subtract(periodValue, periodUnit);

        return { fromDate: fromDateRaw.toISOString(), toDate: toDateRaw.toISOString() };
    }

    getContextMenu(field: string, value: unknown) {
        let fType = this.getFilterTypeFromField(field);
        if (!fType) {
            fType = this.getFilterTypeFromValue(value);
        }
        if (value == undefined || (Array.isArray(value) && !value.length)) return [];
        return this.getContextMenuFromFilterType(fType, field, value);
    }

    getFilterOperatorLabel(operator: FilterOperator) {
        let filterOperator = operator.toString();
        switch (operator) {
            case FilterOperator.like:
                filterOperator = 'contains';
                break;
            default:
                break;
        }

        return filterOperator;
    }

    createViewFilter(type: FilterType, field: string, operator: FilterOperator, value, isDeletable = true, isEditable = true) {
        this.store.dispatch(
            addActiveFilter({
                newFilter: {
                    id: UUID.UUID(),
                    field,
                    operator,
                    type,
                    value,
                    scope: FilterScope.view,
                    isDeletable,
                    isEditable,
                },
            })
        );
    }

    removeViewFilters() {
        this.store.dispatch(removeActiveFilterByScope({ scope: FilterScope.view }));
    }

    changeTimeSelectionEdition(lock: boolean) {
        this.store
            .select(selectFilterByType(FilterType.DATE_RANGE))
            .pipe(
                filter(r => !!r),
                take(1)
            )
            .subscribe(filters => {
                const found = filters.find(f => f.scope === FilterScope.global);
                if (found) {
                    const updatedFilter = cloneDeep(found);
                    updatedFilter.isEditable = lock;
                    this.store.dispatch(updateActiveFilter({ updatedFilter }));
                }
            });
    }

    convertEventFilters(filters: Filter[]) {
        const convertedFilters: any[] = [];
        filters.forEach(f => {
            const newFilter = {
                field: f.field,
                operator: f.operator,
                value: f.value,
                type: f.type,
            };

            switch (f.type) {
                case FilterType.CAT_TYPE:
                    let field = 'event_type_id';
                    if (f.value.class_type === 1) {
                        field = 'category_id';
                    } else if (f.value.class_type === 3) {
                        field = 'event_category_id';
                    }

                    newFilter.field = field;
                    newFilter.value = f.value.id;
                    break;

                case FilterType.COUNTRY:
                    newFilter.field = f.field === 'src_country' ? 'src_country_code' : 'dst_country_code';
                    newFilter.value = +f.value.id;
                    break;

                case FilterType.ANALYZER:
                    newFilter.field = 'analyzer';
                    newFilter.value = +f.value.id;
                    break;

                case FilterType.PROTO_7:
                    newFilter.field = 'proto_7';
                    newFilter.value = +f.value.id;
                    break;

                case FilterType.STRING:
                    if (f.operator === FilterOperator.like) {
                        newFilter.value = '%' + f.value + '%';
                    }
                    break;

                default:
                    break;
            }

            convertedFilters.push(newFilter);
        });

        const rules: Array<{ field: string; label: string; operator: string; type: string; value: any }> = [];
        convertedFilters.forEach(cf => {
            if (cf.type === FilterType.QUERY_BUILDER) {
                rules.push(cf.value);
            } else {
                rules.push({
                    field: cf.field,
                    label: '',
                    operator: this.mapOperatorForQueryBuilder(cf.operator),
                    type: cf.type,
                    value: cf.value,
                });
            }
        });

        let ret4API: any = {};
        if (rules.length > 0) {
            ret4API = {
                condition: 'and',
                not: undefined,
                rules,
            };
        }

        return ret4API;
    }

    getAssetsFilterFields(apiColumns, assetTypes) {
        const availableFilters: AvailableFilter[] = [];

        apiColumns.forEach(colName => {
            switch (colName) {
                case 'name':
                    availableFilters.push({ field: colName, type: 'string', label: 'Name' });
                    break;
                case 'id_site':
                    availableFilters.push({ field: colName, type: 'string', label: 'Site identifier' });
                    break;
                case 'id':
                    availableFilters.push({ field: colName, type: 'string', label: 'Identifier' });
                    break;
                case 'id_asset_type':
                    availableFilters.push({
                        field: 'asset_type',
                        type: 'string',
                        label: 'Asset type',
                        values: assetTypes.map(c => {
                            return { value: c.id, text: c.name };
                        }),
                    });
                    break;
                case 'interface_id':
                    break;
                case 'interface_ip_address':
                    availableFilters.push({ field: colName, type: 'string', label: 'IP' });
                    break;
                case 'interface_mac':
                    availableFilters.push({ field: colName, type: 'string', label: 'Mac' });
                    break;
                case 'interface_name':
                    break;
                case 'interface_network':
                    availableFilters.push({ field: colName, type: 'string', label: 'Network' });
                    break;
                case 'interface_ip_gateway':
                    availableFilters.push({ field: colName, type: 'string', label: 'Gateway' });
                    break;
                case 'interface_ip_mask':
                    availableFilters.push({ field: colName, type: 'string', label: 'Mask' });
                    break;
                case 'service_id':
                    break;
                case 'service_port_start':
                    availableFilters.push({ field: colName, type: 'number', label: 'Port strart' });
                    break;
                case 'service_port_end':
                    availableFilters.push({ field: colName, type: 'number', label: 'Port end' });
                    break;
                case 'service_name':
                    availableFilters.push({ field: colName, type: 'string', label: 'Service name' });
                    break;

                default:
                    availableFilters.push({ field: colName, type: 'string', label: colName });
                    break;
            }
        });

        return availableFilters;
    }

    getEventsFilterFields(apiColumns, analyzers, classChoices, typeChoices) {
        const availableFilters: AvailableFilter[] = [];

        apiColumns.forEach(colName => {
            switch (colName) {
                case 'analyzer':
                    availableFilters.push({
                        field: 'analyzer_details',
                        type: 'string',
                        label: 'Analyzer',
                        values: analyzers.map(c => {
                            return { value: c.id, text: c.text };
                        }),
                    });
                    break;
                case 'community_id':
                    break;
                case 'date':
                    availableFilters.push({ field: colName, type: 'date', label: 'Start date' });
                    break;
                case 'description':
                    break;
                case 'dst_country_code':
                    availableFilters.push({ field: colName, type: 'number', label: 'Dest. country code' });
                    break;
                case 'dst_geoip':
                    break;
                case 'dst_ip':
                    availableFilters.push({ field: colName, type: 'string', label: 'Dest. IP' });
                    break;
                case 'dst_is_private':
                    availableFilters.push({ field: colName, type: 'boolean', label: 'Dest. is private ?' });
                    break;
                case 'dst_port':
                    availableFilters.push({ field: colName, type: 'number', label: 'Dest. port' });
                    break;
                case 'duration':
                    availableFilters.push({ field: colName, type: 'number', label: 'Duration' });
                    break;
                case 'event_agg_id':
                    break;
                case 'event_agg_score':
                    break;
                case 'event_category_id':
                    availableFilters.push({
                        field: 'category',
                        type: 'string',
                        label: 'Category',
                        values: classChoices.map(c => {
                            return { value: c.id, text: c.name };
                        }),
                    });
                    break;
                case 'event_type_id':
                    availableFilters.push({
                        field: 'type',
                        type: 'string',
                        label: 'Type',
                        values: typeChoices.map(c => {
                            return { value: c.id, text: c.name };
                        }),
                    });
                    break;
                case 'icmp_code':
                    availableFilters.push({ field: colName, type: 'number', label: 'ICMP code' });
                    break;
                case 'icmp_type':
                    availableFilters.push({ field: colName, type: 'number', label: 'ICMP type' });
                    break;
                case 'id':
                    availableFilters.push({ field: colName, type: 'string', label: 'Identifier' });
                    break;
                case 'pkt_in':
                    availableFilters.push({ field: colName, type: 'number', label: 'Packets in' });
                    break;
                case 'pkt_out':
                    availableFilters.push({ field: colName, type: 'number', label: 'Packets out' });
                    break;
                case 'pseudo_event':
                    availableFilters.push({ field: colName, type: 'number', label: 'Is pseudo event ?' });
                    break;
                case 'proto_4':
                    availableFilters.push({ field: colName, type: 'string', label: 'Protocol' });
                    break;
                case 'proto_7':
                    availableFilters.push({ field: colName, type: 'string', label: 'Application' });
                    break;
                case 'rate_in':
                    availableFilters.push({ field: colName, type: 'number', label: 'Rate in' });
                    break;
                case 'rate_out':
                    availableFilters.push({ field: colName, type: 'number', label: 'Rate out' });
                    break;
                case 'score':
                    availableFilters.push({ field: colName, type: 'number', label: 'Score' });
                    break;
                case 'size_in':
                    availableFilters.push({ field: colName, type: 'number', label: 'Size in' });
                    break;
                case 'size_out':
                    availableFilters.push({ field: colName, type: 'number', label: 'Size out' });
                    break;
                case 'source_id':
                    break;
                case 'source_ref':
                    break;
                case 'src_country_code':
                    availableFilters.push({ field: colName, type: 'number', label: 'Source country code' });
                    break;
                case 'src_geoip':
                    break;
                case 'src_ip':
                    availableFilters.push({ field: colName, type: 'string', label: 'Source IP' });
                    break;
                case 'src_is_private':
                    availableFilters.push({ field: colName, type: 'boolean', label: 'Source is private ?' });
                    break;
                case 'src_port':
                    availableFilters.push({ field: colName, type: 'number', label: 'Source port' });
                    break;
                case 'threat_id':
                    break;
                case 'threat_score':
                    break;

                default:
                    availableFilters.push({ field: colName, type: 'number', label: colName });
                    break;
            }
        });

        return availableFilters;
    }

    getThreatsFilterFields(apiColumns, classChoices) {
        const availableFilters: AvailableFilter[] = [];

        apiColumns.forEach(colName => {
            switch (colName) {
                case 'score':
                    availableFilters.push({ field: colName, type: 'number', label: 'Score' });
                    break;
                case 'start_date':
                    availableFilters.push({ field: colName, type: 'date', label: 'Start date' });
                    break;
                case 'id':
                    availableFilters.push({ field: colName, type: 'string', label: 'Identifier' });
                    break;
                case 'src_ip':
                    availableFilters.push({ field: colName, type: 'string', label: 'Source IP' });
                    break;
                case 'dst_ip':
                    availableFilters.push({ field: colName, type: 'string', label: 'Dest. IP' });
                    break;
                case 'end_date':
                    availableFilters.push({ field: colName, type: 'date', label: 'End date' });
                    break;
                case 'status':
                    availableFilters.push({ field: colName, type: 'boolean', label: 'Status' });
                    break;

                case 'category_id':
                    availableFilters.push({
                        field: 'threat_category',
                        type: 'string',
                        label: 'Category',
                        values: classChoices.map(c => {
                            return { value: c.id, text: c.name };
                        }),
                    });
                    break;

                    break;
                case 'count_elems':
                    break;

                default:
                    availableFilters.push({ field: colName, type: 'number', label: colName });
                    break;
            }
        });

        return availableFilters;
    }

    private mapOperatorForQueryBuilder(operator: FilterOperator) {
        // '=': 'equal', '!=': 'notequal', '<': 'lessthan', '>': 'greaterthan', '<=': 'lessthanorequal',
        // '>=': 'greaterthanorequal', 'in': 'in', 'not in': 'notin', 'between': 'between', 'not between': 'notbetween',
        // 'is empty': 'isempty', 'is null': 'isnull', 'is not null': 'isnotnull', 'is not empty': 'isnotempty'

        switch (operator) {
            case '=':
                return 'equal';
            case '≠':
                return 'notequal';
            case '>':
                return 'greaterthan';
            case '≥':
                return 'greaterthanorequal';
            case '<':
                return 'lessthan';
            case '≤':
                return 'lessthanorequal';
            case 'LIKE':
                return 'like';
            default:
                return 'equal';
        }
    }

    private getFilterTypeFromField(field: string): FilterType | undefined {
        switch (field) {
            case 'src_country':
            case 'dst_country':
                return FilterType.COUNTRY;
            case 'type':
            case 'category':
                return FilterType.CAT_TYPE;
            case 'analyzer_details':
                return FilterType.ANALYZER;
            case 'proto_7':
                return FilterType.PROTO_7;
            case 'suricata_rule_id':
                return FilterType.NUMBER;
            default:
                return undefined;
        }

        return undefined;
    }

    getFilterFieldFromFilter(filter: Filter) {
        let filterField = '';

        if (filter.field === 'event_agg_id') {
            filterField = 'alert_id';
        } else if (filter.type === FilterType.QUERY_BUILDER) {
            filterField = 'Custom query';
        } else {
            filterField = filter.field;
        }

        return filterField;
    }

    getFilterLabelFromFilter(filter: Filter) {
        let filterLabel = '';
        switch (filter.type) {
            case FilterType.DATE_RANGE:
                if (!!filter.value) {
                    const dateRangeFilter = filter as Filter<FilterType.DATE_RANGE>;
                    switch (dateRangeFilter.value.timeType) {
                        case TimeType.customRange:
                            const dateMin = moment(dateRangeFilter.value.minTime);
                            const dateMax = moment(dateRangeFilter.value.maxTime);
                            filterLabel +=
                                'from ' + dateMin.format('DD MMM YYYY, HH:mm:ss') + ' to ' + dateMax.format('DD MMM YYYY, HH:mm:ss');
                            break;
                        default:
                            filterLabel += dateRangeFilter.value.periodValue + '  ' + dateRangeFilter.value.periodUnit;
                    }
                }
                break;
            case FilterType.DASHBOARD_DATE_RANGE:
                if (filter.value !== undefined) {
                    const dateRangeFilter = filter as Filter<FilterType.DASHBOARD_DATE_RANGE>;
                    switch (dateRangeFilter.value) {
                        case FilterDashboardType.SEVEN_DAYS: return 'Last 7 days';
                        case FilterDashboardType.FIFTEEN_DAYS: return 'Last 15 days';
                        case FilterDashboardType.THIRTY_DAYS: return 'Last 30 days';
                        case FilterDashboardType.ONE_WEEK: return 'Last 1 week';
                        case FilterDashboardType.TWO_WEEKS: return 'Last 2 weeks';
                        case FilterDashboardType.ONE_MONTH: return 'Last 1 month';
                        default: return 'Last 7 days'
                    }
                }
                break;
            case FilterType.CAT_TYPE:
                if (!!filter.value) {
                    filterLabel += filter.value.name;
                }
                break;
            case FilterType.COUNTRY:
                if (!!filter.value) {
                    filterLabel += filter.value.name;
                }
                break;
            case FilterType.ANALYZER:
                if (!!filter.value) {
                    filterLabel += filter.value.text;
                }
                break;
            case FilterType.PROTO_7:
                if (!!filter.value) {
                    filterLabel += filter.value.text;
                }
                break;
            case FilterType.QUERY_BUILDER:
                filterLabel = filter.field;
                break;
            case FilterType.ATTACK_STATUS:
                filterLabel = filter.value === 0 ? 'ongoing' : 'closed';
                break;
            default:
                filterLabel += filter.value;
        }

        return filterLabel;
    }

    private getFilterTypeFromValue(value: unknown): FilterType {
        if (typeof value == 'boolean') {
            return FilterType.BOOLEAN;
        } else if (!isNaN(value as number)) {
            return FilterType.NUMBER;
        } else if (!isNaN(new Date(value + '').getTime())) {
            return FilterType.DATE;
        } else {
            return FilterType.STRING;
        }
    }

    private getContextMenuFromFilterType(type: FilterType, field: string, value: any): ContextMenuItemModel[] {
        switch (type) {
            case FilterType.NUMBER:
                return [
                    this.createContextMenuItem(type, field, FilterOperator.equal, value),
                    this.createContextMenuItem(type, field, FilterOperator.sup, value),
                    this.createContextMenuItem(type, field, FilterOperator.supEqual, value),
                    this.createContextMenuItem(type, field, FilterOperator.inf, value),
                    this.createContextMenuItem(type, field, FilterOperator.infEqual, value),
                    this.createContextMenuItem(type, field, FilterOperator.different, value),
                ];
            case FilterType.CAT_TYPE:
            case FilterType.ANALYZER:
            case FilterType.COUNTRY:
            case FilterType.DATE:
            case FilterType.PROTO_7:
                return [
                    this.createContextMenuItem(type, field, FilterOperator.equal, value),
                    this.createContextMenuItem(type, field, FilterOperator.different, value),
                ];
            case FilterType.BOOLEAN:
                return [
                    this.createContextMenuItem(type, field, FilterOperator.equal, false),
                    this.createContextMenuItem(type, field, FilterOperator.equal, true),
                ];
            default:
                return [
                    this.createContextMenuItem(type, field, FilterOperator.like, value),
                    this.createContextMenuItem(type, field, FilterOperator.equal, value),
                    this.createContextMenuItem(type, field, FilterOperator.different, value),
                ];
        }
    }

    private createContextMenuItem(type: FilterType, field: string, operator: FilterOperator, value: any) {
        return {
            text: 'Add filter : ' + field + ' ' + this.getFilterOperatorLabel(operator) + ' ' + this.getFilterLabel(type, value),
            iconCss: 'icon small filter',
            command: event => this.createViewFilter(type, field, operator, value),
        };
    }

    private getFilterLabel(type: FilterType, value: any) {
        switch (type) {
            case FilterType.CAT_TYPE:
                return value.name;
            case FilterType.COUNTRY:
                return value.name;
            case FilterType.ANALYZER:
                return value.text;
            case FilterType.PROTO_7:
                return value.text;
            default:
                return value;
        }
    }

    /**
     *
     * @param rules Règles à convertir
     * @param forApi
     * @returns
     */
    getQueryBuidlerString(rules: RuleModel, forApi: boolean = false, forEdition: boolean = false): RuleModel {
        let ret: RuleModel = { condition: rules.condition, rules: [] };

        if (!!rules.condition && !!rules.rules) {
            ret = { condition: rules.condition, rules: this.getCondition(rules.rules, forApi, forEdition), not: rules.not };
        }
        return ret;
    }

    /**
     * Convertie une règle (avec le nom clair pour le User)
     * @param rule : Règle à convertir
     * @param forApi : Règle à convertir
     * @returns Règle convertie
     */
    private getRule(rule: RuleModel, forApi: boolean, forEdition: boolean): RuleModel {
        let field = rule?.field;
        let value = !!(rule.value as any)?.text ? (rule.value as any)?.text : rule.value;

        // Cas où on souhaite avec la chaîne pour l'API
        if (forApi) {
            field = this.getMappedFieldForAPI(rule?.field);
            value = !!(rule.value as any)?.value ? (rule.value as any)?.value : rule.value;
        } else if (forEdition) {
            value = !!(rule.value as any)?.value ? (rule.value as any)?.value : rule.value;
        }

        return {
            field,
            label: rule?.label,
            operator: rule?.operator,
            type: rule?.type,
            value,
        };
    }

    /**
     * Convertie une condition
     * @param rules : Condition et ses règles à convertir
     * @returns Condition convertie
     */
    private getCondition(rules: RuleModel[], forApi: boolean, forEdition: boolean) {
        const retList: RuleModel[] = [];
        rules.forEach(r => {
            if (!!r.condition && !!r.rules) {
                retList.push({ condition: r.condition, rules: this.getCondition(r.rules, forApi, forEdition), not: r.not });
            } else {
                retList.push(this.getRule(r, forApi, forEdition));
            }
        });
        return retList;
    }

    /**
     * Retourne le champs pour la BD
     * @param field Champs à mapper
     * @returns Nom du champs de la BD
     */
    private getMappedFieldForAPI(field: string | undefined): string | undefined {
        switch (field) {
            case 'analyzer_details':
                return 'analyzer';
            case 'type':
                return 'event_type_id';
            case 'threat_category':
                return 'category_id';
            case 'category':
                return 'event_category_id';
            case 'asset_type':
                return 'id_asset_type';
            default:
                return field;
        }
    }

    //#endregion Filters Utils

    //#region Boost

    getPcatBoostedValue(x: number) {
        if (x < 5) {
            return (x / 625.0) * (2.0 * x * x - 45.0 * x + 300.0);
        } else {
            return 1;
        }
    }

    boostAttackScoreRaw(score: number) {
        const b = 4.2304273798536;
        const x = score / 100;
        return Math.round((Math.log(b * x + 1) / Math.log(1 + b)) * 100);
    }

    // Fonction de calcul du boosting (Pour Théo A par ce qu'il ne comprend rien)

    private f(x: number) {
        return 0.4 * Math.pow(x, 3) - 1.8 * Math.pow(x, 2) + 2.4 * x;
    }

    private computeBoost(c: number, cMin: number, cMax: number, maxBoost: number, maxInit: number) {
        const x = (c - cMin) / (cMax - cMin);

        if (x < 0) {
            return 0;
        } else if (x > 1) {
            return maxBoost / maxInit;
        } else {
            return this.f(x) * (maxBoost / maxInit - 1.0) + 1.0;
        }
    }

    computeSurBoost(
        surboost: boolean,
        c: number,
        cMin: number,
        cMax: number,
        maxBoost: number,
        maxInit: number,
        analyzer_c: number = 0,
        analyzer_cMin: number = 0,
        analyzer_cMax: number = 0,
        analyzer_maxBoost: number = 0,
        analyzer_maxInit: number = 0
    ) {
        const oldBoost = this.computeBoost(c, cMin, cMax, maxBoost, maxInit);
        const newBoost = surboost ? this.computeBoost(analyzer_c, analyzer_cMin, analyzer_cMax, analyzer_maxBoost, analyzer_maxInit) : 1;

        return 1 + (oldBoost - 1) + (newBoost - 1);
    }

    generateBoostData(
        surboost: boolean,
        cMin: number,
        cMax: number,
        maxBoost: number,
        maxInit: number,
        analyzer_c: number,
        analyzer_cMin: number,
        analyzer_cMax: number,
        analyzer_maxBoost: number,
        analyzer_maxInit: number
    ): Array<[number, number]> {
        const retArray: Array<[number, number]> = [];
        for (let i = cMin; i < cMax; i = i + 0.1) {
            const oldBoost = this.computeBoost(i, cMin, cMax, maxBoost, maxInit);
            const newBoost = surboost
                ? this.computeBoost(analyzer_c, analyzer_cMin, analyzer_cMax, analyzer_maxBoost, analyzer_maxInit)
                : 1;

            retArray.push([i, 1 + (oldBoost - 1) + (newBoost - 1)]);
        }

        return retArray;
    }

    formatBoostValue(v: number) {
        return Math.round((v - 1) * 100 * 10) / 10 + '%';
    }

    //#endregion Boost

    getFiltersFromObjectType($event: ThreatView): Filter[] {
        const filters: Filter[] = [];
        switch ($event.object) {
            case 'event_group':
                filters.push(
                    {
                        id: '',
                        field: 'threat_id',
                        operator: FilterOperator.equal,
                        type: FilterType.STRING,
                        value: $event.threat_id,
                        isDeletable: true,
                        isEditable: true,
                        scope: FilterScope.view,
                    },
                    {
                        id: '',
                        field: 'event_agg_id',
                        operator: FilterOperator.equal,
                        type: FilterType.STRING,
                        value: $event.parent_id,
                        isDeletable: true,
                        isEditable: true,
                        scope: FilterScope.view,
                    },
                    {
                        id: '',
                        field: 'type',
                        operator: FilterOperator.equal,
                        type: FilterType.CAT_TYPE,
                        value: $event.type,
                        isDeletable: true,
                        isEditable: true,
                        scope: FilterScope.view,
                    }
                );
                break;
            case 'alert':
                filters.push(
                    {
                        id: '',
                        field: 'threat_id',
                        operator: FilterOperator.equal,
                        type: FilterType.STRING,
                        value: $event.parent_id,
                        isDeletable: true,
                        isEditable: true,
                        scope: FilterScope.view,
                    },
                    {
                        id: '',
                        field: 'event_agg_id',
                        operator: FilterOperator.equal,
                        type: FilterType.STRING,
                        value: $event.id,
                        isDeletable: true,
                        isEditable: true,
                        scope: FilterScope.view,
                    }
                );
                break;
            case 'threat':
                filters.push({
                    id: '',
                    field: 'threat_id',
                    operator: FilterOperator.equal,
                    type: FilterType.STRING,
                    value: $event.id,
                    isDeletable: true,
                    isEditable: true,
                    scope: FilterScope.view,
                });
                break;
            default:
                break;
        }
        return filters;
    }

    mapThreatToTreeNodeAttack(threat: ThreatView): TreeNodeAttack {
        return {
            id: threat.id,
            object: threat.object,
            start_date: threat.start_date,
            end_date: threat.end_date,
            score: threat.score,
            parent_id: threat.parent_id,
            qualification: threat.category?.name,
            src_ip: threat.src_ip,
            dst_ip: threat.dst_ip,
            status: threat.status,
        };
    }

    mapTreeNodeThreatToTreeNodeTreeNodeAttack(threat: TreeNode<ThreatView>): TreeNode<TreeNodeAttack> {
        if (threat.data) {
            return {
                data: this.mapThreatToTreeNodeAttack(threat.data),
                children: [],
            };
        }

        return {
            data: undefined,
            children: [],
        };
    }

    convertBytes(x: number) {
        const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        let l = 0;
        let n = x;

        while (n >= 1024 && ++l) {
            n = n / 1024;
        }

        return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
    }

    convertNumber(x: number) {
        const units = ['', 'K', 'M', 'G'];

        let l = 0;
        let n = x;

        while (n >= 1000 && ++l) {
            n = n / 1000;
        }

        return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
    }

    getReduceID(id: string) {
        return (id.substring(0, 5) + '[...]').toUpperCase();
    }

    isAdmin(user: User | null) {
        return !!user && (user.roles.includes('ADMIN') || user.roles.includes('SUPERADMIN'));
    }

    isSuperAdmin(user: User | null) {
        return !!user && user.roles.includes('ADMIN');
    }

    getTagAspectFromRole(role: string) {
        switch (role) {
            case 'USER':
                return 'b2-header text-header';
            case 'ADMIN':
                return 'b2-high-criticity text-high-criticity';
            case 'SUPERADMIN':
                return 'b2-critical-criticity text-critical-criticity';
            default:
                return 'b2-header text-header';
        }
    }

    getIatDetectionDetailsFromML(proba: any) {
        return proba.find(f => f.sub_model_type === 'iat');
    }
    getFlowDetectionDetailsFromML(proba: any) {
        return proba.find(f => f.sub_model_type === 'vect');
    }
    getAggSrcDetectionDetailsFromML(proba: any) {
        return proba.find(f => f.sub_model_type === 'aggsrc');
    }
    getAggDstDetectionDetailsFromML(proba: any) {
        return proba.find(f => f.sub_model_type === 'aggdst');
    }

    getVeracityFromEventDetails(event: any) {
        return event?.source_elem?.source_ml?.ml_meta_proba?.ml_proba * 100;
    }

    getProbaFromEventDetails(event: any) {
        return event?.source_elem?.source_ml?.ml_meta_proba?.metalearner;
    }

    getIconFromCategoryId(categoryId) {
        if (!!categoryId) {
            switch (categoryId) {
                case 512: // 'information gathering':
                    return 'ckc-recon';
                case 513: // 'intrustion':
                    return 'ckc-intrusion';
                case 514: // 'intrustion CA':
                    return 'ckc-intrusion-ca';
                case 521: // 'availability':
                    return 'ckc-availability';
                case 515: // 'internal information gathering':
                    return 'ckc-internal';
                case 519: // 'Malicious Code CandC':
                    return 'ckc-network';
                case 523: // 'abusive content':
                    return 'ckc-content';
                case 524: // 'unwanted activity':
                    return 'ckc-activity';
                case 516: // 'malicious code non critical':
                    return 'ckc-code-non-c';
                case 517: // 'malicious code':
                    return 'ckc-code';
                case 518: // 'collection':
                    return 'ckc-install';
                case 520: // 'Information Content Security':
                    return 'ckc-exploitation';
                case 522: // 'Internal Availability':
                    return 'ckc-internal-availability';

                default:
                    return 'threat';
            }
        }

        return 'attack';
    }

    getGraphParticleCountFromScore(score) {
        if (!!this.criticity) {
            if (score >= this.criticity.critical) {
                return 4;
            } else if (score >= this.criticity.high) {
                return 3;
            } else if (score >= this.criticity.medium) {
                return 2;
            } else if (score >= this.criticity.low) {
                return 1;
            } else {
                return 0;
            }
        }

        return 0;
    }

    getGraphParticleSizeFromScore(score) {
        if (!!this.criticity) {
            if (score >= this.criticity.critical) {
                return 3;
            } else if (score >= this.criticity.high) {
                return 3;
            } else if (score >= this.criticity.medium) {
                return 2;
            } else if (score >= this.criticity.low) {
                return 2;
            } else {
                return 0;
            }
        }

        return 0;
    }

    getTacticIdFromName(tacticName: string) {
        switch (tacticName) {
            case 'collection':
                return 'TA0009';
            case 'command-and-control':
                return 'TA0011';
            case 'credential-access':
                return 'TA0006';
            case 'defense-evasion':
                return 'TA0005';
            case 'discovery':
                return 'TA0007';
            case 'execution':
                return 'TA0002';
            case 'exfiltration':
                return 'TA0010';
            case 'impact':
                return 'TA0040';
            case 'initial-access':
                return 'TA0001';
            case 'lateral-movement':
                return 'TA0008';
            case 'persistence':
                return 'TA0003';
            case 'privilege-escalation':
                return 'TA0004';
            case 'reconnaissance':
                return 'TA0043';
            case 'resource-development':
                return 'TA0042';
            default:
                return '';
        }
    }

    convertWhitelistTypeForDB(whitelistType: WhitelistRuleIpType) {
        switch (whitelistType) {
            case WhitelistRuleIpType.IP: return WhitelistRuleIpType_FromDB.IP;
            case WhitelistRuleIpType.NETWORK: return WhitelistRuleIpType_FromDB.NETWORK;
            case WhitelistRuleIpType.INTERNAL: return WhitelistRuleIpType_FromDB.INTERNAL;
            case WhitelistRuleIpType.EXTERNAL: return WhitelistRuleIpType_FromDB.EXTERNAL;
            default: return WhitelistRuleIpType_FromDB.IP
        }

        return WhitelistRuleIpType_FromDB.IP
    }

    convertWhitelistTypeFromDB(whitelistType: WhitelistRuleIpType_FromDB) {
        switch (whitelistType) {
            case WhitelistRuleIpType_FromDB.IP: return WhitelistRuleIpType.IP;
            case WhitelistRuleIpType_FromDB.NETWORK: return WhitelistRuleIpType.NETWORK;
            case WhitelistRuleIpType_FromDB.INTERNAL: return WhitelistRuleIpType.INTERNAL;
            case WhitelistRuleIpType_FromDB.EXTERNAL: return WhitelistRuleIpType.EXTERNAL;
            default: return WhitelistRuleIpType.IP
        }

        return WhitelistRuleIpType.IP
    }

    isValidNetworkWithIpNum(network: string): boolean {
        try {
            if (network.includes('/')) {
                // Tentative de création d'une plage CIDR pour voir si elle est valide.
                if (network.includes(':')) {
                    // IPv6 CIDR
                    IPv6CidrRange.fromCidr(network);
                } else {
                    // IPv4 CIDR
                    IPv4CidrRange.fromCidr(network);
                }
                return true; // Si aucune exception n'est levée, le réseau est valide.
            }
        } catch (error) {
            // Si une exception est levée, le réseau n'est pas valide.
            console.error(error);
            return false;
        }
        return false; // Retourne faux si le réseau n'est pas au format CIDR.
    }

    isValidIp(ip) {
        const [isValidIPv4] = Validator.isValidIPv4String(ip);
        const [isValidIPv6] = Validator.isValidIPv6String(ip);

        return isValidIPv4 || isValidIPv6;
    }
}
