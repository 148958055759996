import { createAction, props } from '@ngrx/store';

export enum MitreTypes {
    LOAD_MITRE_MATRIX_REQUEST = '[Mitre Matrix Api] Load Request',
    LOAD_MITRE_MATRIX_SUCCESS = '[Mitre Matrix Api] Load Success',
    LOAD_MITRE_MATRIX_FAILURE = '[Mitre Matrix Api] Load Failure',

    LOAD_MITRE_MITIGATIONS_REQUEST = '[Mitre Mitigations Api] Load Request',
    LOAD_MITRE_MITIGATIONS_SUCCESS = '[Mitre Mitigations Api] Load Success',
    LOAD_MITRE_MITIGATIONS_FAILURE = '[Mitre Mitigations Api] Load Failure',

    LOAD_MITRE_DATASOURCES_REQUEST = '[Mitre Datasources Api] Load Request',
    LOAD_MITRE_DATASOURCES_SUCCESS = '[Mitre Datasources Api] Load Success',
    LOAD_MITRE_DATASOURCES_FAILURE = '[Mitre Datasources Api] Load Failure',

    LOAD_MITRE_GROUPS_REQUEST = '[Mitre Groups Api] Load Request',
    LOAD_MITRE_GROUPS_SUCCESS = '[Mitre Groups Api] Load Success',
    LOAD_MITRE_GROUPS_FAILURE = '[Mitre Groups Api] Load Failure',
}

export const loadMitreMatrixRequest = createAction(MitreTypes.LOAD_MITRE_MATRIX_REQUEST);
export const loadMitreMatrixSuccess = createAction(MitreTypes.LOAD_MITRE_MATRIX_SUCCESS, props<{ mitres: any[] }>());
export const loadMitreMatrixFailure = createAction(MitreTypes.LOAD_MITRE_MATRIX_FAILURE, props<{ error: any }>());

export const loadMitreMitigationsRequest = createAction(MitreTypes.LOAD_MITRE_MITIGATIONS_REQUEST);
export const loadMitreMitigationsSuccess = createAction(MitreTypes.LOAD_MITRE_MITIGATIONS_SUCCESS, props<{ mitigations: any[] }>());
export const loadMitreMitigationsFailure = createAction(MitreTypes.LOAD_MITRE_MITIGATIONS_FAILURE, props<{ error: any }>());

export const loadMitreDatasourcesRequest = createAction(MitreTypes.LOAD_MITRE_DATASOURCES_REQUEST);
export const loadMitreDatasourcesSuccess = createAction(MitreTypes.LOAD_MITRE_DATASOURCES_SUCCESS, props<{ datasources: any[] }>());
export const loadMitreDatasourcesFailure = createAction(MitreTypes.LOAD_MITRE_DATASOURCES_FAILURE, props<{ error: any }>());

export const loadMitreGroupsRequest = createAction(MitreTypes.LOAD_MITRE_GROUPS_REQUEST);
export const loadMitreGroupsSuccess = createAction(MitreTypes.LOAD_MITRE_GROUPS_SUCCESS, props<{ groups: any[] }>());
export const loadMitreGroupsFailure = createAction(MitreTypes.LOAD_MITRE_GROUPS_FAILURE, props<{ error: any }>());
