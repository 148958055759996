import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { CmdbService } from '../../../services/cmdb.service';
import {
    AssetsActionTypes,
    loadAssetsSuccessAction,
    loadAssetsTypeFailureAction,
    loadAssetsTypeRequestAction,
    loadAssetsTypeSuccessAction,
} from '../actions/assets.actions';
import { AuthActionTypes } from '../actions/auth.actions';
import { AssetsStoreState } from '../state/assets.state';

@Injectable()
export class AssetsEffects {
    constructor(private readonly actions$: Actions, private readonly store: Store<AssetsStoreState>, private readonly cmdb: CmdbService) {}

    init$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActionTypes.USER_AUTO_LOGGED_IN, AuthActionTypes.LOGIN_SUCCESS),
                tap(() => {
                    this.store.dispatch(loadAssetsTypeRequestAction());
                })
            ),
        { dispatch: false }
    );

    loadAssetsTypes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AssetsActionTypes.ASSETS_TYPE_LOAD_REQUEST),
            exhaustMap(() =>
                this.cmdb.getCmdbTypes().pipe(
                    map(types => loadAssetsTypeSuccessAction({ types })),
                    catchError(error => of(loadAssetsTypeFailureAction({ error })))
                )
            )
        )
    );

    loadAssetsList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AssetsActionTypes.ASSETS_LOAD_REQUEST),
            exhaustMap(({ fromDate, toDate, limit, offset, isGraph }) =>
                this.cmdb.getCmdbAssets(fromDate, toDate, limit, offset, isGraph).pipe(
                    map(assets => loadAssetsSuccessAction({ assets })),
                    catchError(error => of(loadAssetsTypeFailureAction({ error })))
                )
            )
        )
    );
}
