import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, take } from 'rxjs';
import { KnubeCell } from 'src/app/models/knube-cell.interface';
import { User } from 'src/app/models/user.interface';
import { OverlayManagerService } from 'src/app/services/overlay-manager.service';
import { KnubeComponent } from 'src/app/shared/modules/knube/knube.component';
import { selectAuthUser } from 'src/app/shared/store/selectors/auth.selectors';
import { AuthState } from 'src/app/shared/store/state/auth.state';
import { UtilsService } from 'src/app/shared/utils/utils.service';
import { AdminService } from '../../services/admin.service';
import { UserEditComponent } from '../user-edit/user-edit.component';

@Component({
    selector: 'hypervision-users-list',
    templateUrl: './users-list.component.html',
    styleUrls: ['./users-list.component.scss'],
})
export class UsersListComponent implements OnInit {
    @Input() users: Observable<User[]>;
    @Output() reloadUsers = new EventEmitter<null>();
    currentUser: User | null;

    cells: KnubeCell[] = [];
    knube: KnubeComponent | null;

    constructor(
        readonly utils: UtilsService,
        private readonly store: Store<AuthState>,
        private readonly adminService: AdminService,
        private readonly overlayManager: OverlayManagerService
    ) {}

    ngOnInit(): void {
        this.bindEvents();
        this.store.select(selectAuthUser).subscribe(user => {
            this.currentUser = user;
        });
    }

    /**
     * Lance l'interface de modification d'un compte USER
     * @param user USER à modifier
     */
    updateUser(user: User) {
        this.initCells(user);
        this.showKnube();
    }

    /**
     * Supprime un USER
     * @param user USER à supprimer
     */
    deleteUser(user: User) {
        if (user.id) {
            this.adminService.deleteUser(user.id).subscribe(r => {
                this.reloadUsers.emit();
            });
        }
    }

    canUpdate(user: User | null) {
        // Si pas de USER détecté
        if (!this.currentUser) {
            return false;
        }

        // Si USER est ADMIN et veut modifier son compte
        if (this.utils.isAdmin(user) && user?.id === this.currentUser.id) {
            return true;
        }

        // Si le compte à modifier est celui d'un ADMIN ou d'un SUPERADMIN et que le USER est ADMIN => Update impossible
        if (this.utils.isAdmin(user) && !this.utils.isSuperAdmin(this.currentUser)) {
            return false;
        } else if (!this.utils.isAdmin(this.currentUser)) {
            return false;
        }

        return true;
    }

    canDelete(user: User | null) {
        if (this.utils.isAdmin(user) && !this.utils.isSuperAdmin(this.currentUser)) {
            return false;
        } else if (!this.utils.isAdmin(this.currentUser)) {
            return false;
        }

        return true;
    }

    updateUserLock(event: any, user: User) {
        this.adminService.updateUser(user.id, { locked: event.target.checked }).subscribe();
    }

    private showKnube() {
        this.knube = this.overlayManager.openOverlay<KnubeComponent>(KnubeComponent, 'center', {
            cells: this.cells,
            height: 'fit-content',
            width: 'fit-content',
            showTitle: true,
        });
        this.knube.askForClose.pipe(take(1)).subscribe(r => {
            this.overlayManager.closeOverlay();
            this.knube = null;
        });
    }

    private initCells(user: User) {
        this.cells = [
            {
                component: UserEditComponent,
                title: 'User edition',
                inputs: {
                    user,
                },
                outputs: {
                    askForClose: () => {
                        this.overlayManager.closeOverlay();
                        this.reloadUsers.emit();
                        this.knube = null;
                    },
                },
            },
        ];
    }

    private bindEvents() {
        document.addEventListener(
            'keydown',
            event => {
                const keyCode = event.key;
                switch (keyCode) {
                    case 'ArrowRight':
                        this.knube?.previous();
                        break;
                    case 'ArrowLeft':
                        this.knube?.next();
                        break;
                    case 'Escape':
                        this.overlayManager.closeOverlay();
                        this.knube = null;
                        break;
                    default:
                        break;
                }
            },
            { passive: true }
        );
    }
}
