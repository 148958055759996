import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { AssetType } from 'src/app/models/asset-type.interface';
import { AssetView } from 'src/app/models/asset-view.interface';
import { TreeGridCol } from 'src/app/models/tree-grid-col.interface';

export const assetsAdapter: EntityAdapter<AssetView> = createEntityAdapter<AssetView>({
    selectId: item => item.id,
});

export interface AssetsState extends EntityState<AssetView> {
    isLoading?: boolean;
    error?: any;
}
export const initialAssetsState: AssetsState = assetsAdapter.getInitialState({
    isLoading: false,
    error: null,
});

export const assetsTypeAdapter = createEntityAdapter<AssetType>({
    selectId: assetType => assetType.id,
});
export interface AssetsTypeState extends EntityState<AssetType> {
    isLoading?: boolean;
    error?: any;
}
export const initialAssetsTypeState: AssetsTypeState = assetsTypeAdapter.getInitialState({
    isLoading: false,
    error: null,
});

export const assetsColAdapter: EntityAdapter<TreeGridCol> = createEntityAdapter<TreeGridCol>({
    selectId: item => item.field,
});
export const initialAssetsColState = assetsColAdapter.getInitialState();

export interface AssetsStoreState {
    assets: AssetsState;
    types: AssetsTypeState;
    cols: EntityState<TreeGridCol>;
}

export const initialAssetsStoreState: AssetsStoreState = {
    assets: initialAssetsState,
    types: initialAssetsTypeState,
    cols: initialAssetsColState,
};
