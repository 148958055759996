import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule, routerReducer } from '@ngrx/router-store';
import { Action, ActionReducer, MetaReducer, Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { localStorageSync } from 'ngrx-store-localstorage';
import { combineLatest, takeWhile } from 'rxjs';
import { AdminModule } from './admin/admin.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DecoratorInjectorModule } from './decorators/decorator-injector.module';
import { APP_CONFIGURATION_DONE, EnvConfigurationService } from './services/env-configuration.service';
import { FilterInterceptor } from './services/filter.interceptor';
import { TokenInterceptor } from './services/token.interceptor';
import { AuthActionTypes, setFreshLogin, tokenRefreshRequest } from './shared/store/actions/auth.actions';
import { HistoryListActionType } from './shared/store/actions/history-list.action';
import { AnalyzerEffects } from './shared/store/effects/analyzer.effects';
import { AssetsEffects } from './shared/store/effects/assets.effects';
import { AttackReasonEffects } from './shared/store/effects/attack-reason.effects';
import { AuthEffects } from './shared/store/effects/auth.effects';
import { CountryEffects } from './shared/store/effects/country.effects';
import { DictionaryEffects } from './shared/store/effects/dictionary.effects';
import { FiltersEffects } from './shared/store/effects/filters.effects';
import { HistoryListEffects } from './shared/store/effects/history-list.effects';
import { MitreEffects } from './shared/store/effects/mitre.effects';
import { RouterEffects } from './shared/store/effects/router.effects';
import { ScoringEffects } from './shared/store/effects/scoring.effects';
import { SettingsEffects } from './shared/store/effects/settings.effects';
import { StatusEffects } from './shared/store/effects/status.effects';
import { ViewSettingsEffects } from './shared/store/effects/view-settings.effects';
import { analyzerReducer } from './shared/store/reducers/analyzer.reducers';
import { assetsStoreReducer } from './shared/store/reducers/assets.reducers';
import { attackReasonReducer } from './shared/store/reducers/attack-reason.reducers';
import { authReducer } from './shared/store/reducers/auth.reducers';
import { breadcrumbReducer } from './shared/store/reducers/breadcrumb.reducers';
import { countryReducer } from './shared/store/reducers/country.reducers';
import { dictionaryReducer } from './shared/store/reducers/dictionary.reducers';
import { displaySettingsReducer } from './shared/store/reducers/display-settings.reducers';
import { filtersReducer } from './shared/store/reducers/filters.reducers';
import { historyListReducer } from './shared/store/reducers/history-list.reducers';
import { mitreReducer } from './shared/store/reducers/mitre.reducers';
import { scoringReducer } from './shared/store/reducers/scoring.reducers';
import { settingsReducer } from './shared/store/reducers/settings.reducers';
import { statusReducers } from './shared/store/reducers/status.reducers';
import { viewSettingsReducer } from './shared/store/reducers/view-settings.reducers';
import { selectAuthFreshLogin, selectAuthIsLoggedIn } from './shared/store/selectors/auth.selectors';
import { AppState } from './shared/store/state/app.state';

export function localStorageSyncReducer(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
    return localStorageSync({ keys: [{ auth: ['user', 'tokens'] }, 'settings'], rehydrate: true })(reducer);
}
export function loadHistoryState(reducer: ActionReducer<any>): ActionReducer<any> {
    return (state: AppState, action) => {
        if (action.type === HistoryListActionType.LOAD_CURRENT_HISTORY_STATE) {
            const typedAction = action as Action & { state: AppState };
            return reducer({ ...typedAction.state, historyList: state.historyList, auth: state.auth, router: state.router }, action);
        }
        return reducer(state, action);
    };
}

const metaReducers: MetaReducer[] = [localStorageSyncReducer, loadHistoryState];

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        StoreModule.forRoot(
            {
                auth: authReducer,
                settings: settingsReducer,
                router: routerReducer,
                breadcrumb: breadcrumbReducer,
                filters: filtersReducer,
                displaySettings: displaySettingsReducer,
                scoring: scoringReducer,
                status: statusReducers,
                mitre: mitreReducer,
                country: countryReducer,
                dictionary: dictionaryReducer,
                analyzer: analyzerReducer,
                attackReason: attackReasonReducer,
                assets: assetsStoreReducer,
                historyList: historyListReducer,
                viewSettings: viewSettingsReducer,
            },
            { metaReducers }
        ),
        EffectsModule.forRoot([
            RouterEffects,
            SettingsEffects,
            AuthEffects,
            ScoringEffects,
            FiltersEffects,
            StatusEffects,
            MitreEffects,
            CountryEffects,
            DictionaryEffects,
            AnalyzerEffects,
            AttackReasonEffects,
            AssetsEffects,
            HistoryListEffects,
            ViewSettingsEffects,
        ]),
        StoreRouterConnectingModule.forRoot(),
        StoreDevtoolsModule.instrument({ maxAge: 20, autoPause: true }),
        DecoratorInjectorModule,
        AdminModule,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: (envConfigService: EnvConfigurationService, store: Store<AppState>) => async () => {
                await envConfigService.load();
                store.dispatch({ type: APP_CONFIGURATION_DONE });
                combineLatest([
                    store.select(selectAuthIsLoggedIn),
                    store.select(selectAuthFreshLogin)
                ])
                    .pipe(
                        takeWhile(([_, freshLogin]) => !freshLogin, true) // Inclut la dernière émission où freshLogin est `true`
                    )
                    .subscribe(([isLoggedIn, freshLogin]) => {
                        if (isLoggedIn && !freshLogin) {
                            store.dispatch({ type: AuthActionTypes.USER_AUTO_LOGGED_IN });
                            store.dispatch(tokenRefreshRequest());
                        }
                        if (freshLogin) {
                            store.dispatch(setFreshLogin({ freshLogin: false }));
                        }
                    });
            },
            deps: [EnvConfigurationService, Store],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: FilterInterceptor,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
