import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Analyzer } from 'src/app/models/analyzer.interface';
import { analyzerAdapter, AnalyzerState } from '../state/analyzer.state';

export const selectAnalyzerState = createFeatureSelector<AnalyzerState>('analyzer');

export const {
    selectIds: selectAnalyzerIds,
    selectEntities: selectAnalyzerEntities,
    selectAll: selectAnalyzerAll,
    selectTotal: selectAnalyzerTotal,
} = analyzerAdapter.getSelectors(selectAnalyzerState);

export const selectAnalyzerById = (id: number) =>
    createSelector(selectAnalyzerAll, (allAnalyzer: Analyzer[]) => {
        if (allAnalyzer) {
            return allAnalyzer.find(c => c.id === id);
        } else {
            return undefined;
        }
    });
