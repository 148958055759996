import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { Observable, lastValueFrom } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ApiResponse } from 'src/app/models/api-response.interface';
import { AssetView } from 'src/app/models/asset-view.interface';
import { Asset } from 'src/app/models/asset.interface';
import { selectAssetsTypeAll, selectAssetsTypeEntities } from 'src/app/shared/store/selectors/assets.selectors';
import { environment } from 'src/environments/environment';
import { AssetType } from '../models/asset-type.interface';
import { AvailableFilter } from '../models/available-filter.interface';
import { DistributionTrafficProtocols } from '../models/distribution-traffic-protocols.interface';
import { GraphData } from '../models/graph-data.interface';
import { setAvailableFilters } from '../shared/store/actions/filters.actions';
import { AssetsStoreState } from '../shared/store/state/assets.state';
import { UtilsService } from '../shared/utils/utils.service';

@Injectable({
    providedIn: 'root',
})
export class CmdbService {
    assetsType: Dictionary<AssetType>;

    constructor(
        @Inject(LOCALE_ID) protected localeId: string,
        private readonly http: HttpClient,
        private readonly store: Store<AssetsStoreState>,
        private readonly utils: UtilsService
    ) {
        this.store.select(selectAssetsTypeEntities).subscribe(v => (this.assetsType = v));
    }

    getAssetStats(fromDate: string, toDate: string, id: string): Observable<Asset> {
        let params = '?';
        if (fromDate) {
            params += 'from_date=' + fromDate.replace('Z', '');
        }
        if (toDate) {
            params += '&to_date=' + toDate.replace('Z', '');
        }

        return this.http
            .get<ApiResponse<Asset>>(environment.bckAPI.cmdbAPIs.assetUrl + id + '/stats/' + params)
            .pipe(map(response => response.result));
    }

    getAssetEvents(fromDate: string, toDate: string, id: string): Observable<any> {
        let params = '?';
        if (fromDate) {
            params += 'from_date=' + fromDate.replace('Z', '');
        }
        if (toDate) {
            params += '&to_date=' + toDate.replace('Z', '');
        }

        return this.http
            .get<ApiResponse<any>>(environment.bckAPI.cmdbAPIs.assetUrl + id + '/events/' + params)
            .pipe(map(response => response.result));
    }

    getCmdbAssetFromId(id: string): Observable<Asset> {
        return this.http.get<ApiResponse<Asset>>(environment.bckAPI.cmdbAPIs.assetUrl + id).pipe(map(response => response.result));
    }

    getCmdbInfoFromIP(ip: string): Observable<Asset> {
        return this.http.get<ApiResponse<Asset>>(environment.bckAPI.cmdbAPIs.ipUrl + ip).pipe(map(response => response.result));
    }
    getCmdbAssets(fromDate: string, toDate: string, limit: number, offset: number, isGraph: boolean = false): Observable<AssetView[]> {
        if (isGraph) {
            return this.getGraphAssets(fromDate, toDate);
        } else {
            const formData = new FormData();
            if (!!fromDate) {
                formData.append('from_date', fromDate.replace('Z', ''));
            }
            if (!!toDate) {
                formData.append('to_date', toDate.replace('Z', ''));
            }
            if (!!limit) {
                formData.append('limit', limit.toFixed());
            }
            if (!!offset) {
                formData.append('offset', offset.toFixed());
            }
            return this.http
                .post<ApiResponse<Asset[]>>(environment.bckAPI.cmdbAPIs.cmdbUrl, formData)
                .pipe(map(response => this.mapAssetToTreeGridItems(response.result)));
        }
    }

    getGraphAssets(fromDate: string, toDate: string): Observable<AssetView[]> {
        const queryParams: string[] = [];
        if (!!fromDate) queryParams.push('from_date=' + fromDate.replace('Z', ''));
        if (!!toDate) queryParams.push('to_date=' + toDate.replace('Z', ''));
        queryParams.push('get_assets=true');
        const queryParamsFormat = '?' + queryParams.join('&');

        return this.http
            .get<ApiResponse<GraphData[]>>(environment.bckAPI.graphAPIs.graphUrl + queryParamsFormat)
            .pipe(map(response => this.mapGraphNodeToAssetsTree(response.result)));
    }

    updateAsset(updatedAsset) {
        const formData = new FormData();
        if (!!updatedAsset) {
            formData.append('name', updatedAsset.name);
        }

        return this.http
            .patch<ApiResponse<any>>(environment.bckAPI.cmdbAPIs.assetUrl + updatedAsset.id, formData)
            .pipe(map(response => response.result));
    }

    postInterface(newInterface: any): Observable<any> {
        const formData = new FormData();
        if (!!newInterface) {
            if (!!newInterface.name) formData.append('name', newInterface.name);
            if (!!newInterface.id_asset) formData.append('id_asset', newInterface.id_asset);
            if (!!newInterface.id_asset) formData.append('type_asset', newInterface.type_asset);
            if (!!newInterface.ip_address) formData.append('ip_address', newInterface.ip_address);
            if (!!newInterface.macaddress) formData.append('mac', newInterface.macaddress);
            if (!!newInterface.network) formData.append('network', newInterface.network);
            if (!!newInterface.ip_gateway) formData.append('ip_gateway', newInterface.ip_gateway);
            if (!!newInterface.ip_mask) formData.append('ip_mask', newInterface.ip_mask);
        }

        return this.http.post<ApiResponse<any>>(environment.bckAPI.cmdbAPIs.interfaceUrl, formData).pipe(map(response => response.result));
    }

    updateInterface(interfaceToUpdate: any): Observable<any> {
        const formData = new FormData();
        if (!!interfaceToUpdate) {
            if (!!interfaceToUpdate.name) formData.append('name', interfaceToUpdate.name);
            if (!!interfaceToUpdate.ip_address) formData.append('ip_address', interfaceToUpdate.ip_address);
            if (!!interfaceToUpdate.macaddress) formData.append('mac', interfaceToUpdate.macaddress);
            if (!!interfaceToUpdate.network) formData.append('network', interfaceToUpdate.network);
            if (!!interfaceToUpdate.ip_gateway) formData.append('ip_gateway', interfaceToUpdate.ip_gateway);
            if (!!interfaceToUpdate.ip_mask) formData.append('ip_mask', interfaceToUpdate.ip_mask);
        }

        return this.http
            .patch<ApiResponse<any>>(environment.bckAPI.cmdbAPIs.interfaceUrl + interfaceToUpdate.id, formData)
            .pipe(map(response => response.result));
    }

    deleteInterface(interfaceId: any): Observable<any> {
        return this.http
            .delete<ApiResponse<any>>(environment.bckAPI.cmdbAPIs.interfaceUrl + interfaceId)
            .pipe(map(response => response.result));
    }

    getScoreByIps(fromDate: string, toDate: string): Observable<any> {
        const queryParams: string[] = [];
        if (!!fromDate) queryParams.push('from_date=' + fromDate.replace('Z', ''));
        if (!!toDate) queryParams.push('to_date=' + toDate.replace('Z', ''));
        const queryParamsFormat = '?' + queryParams.join('&');

        return this.http.get<any>(environment.bckAPI.cmdbAPIs.scoreByIpsUrl + queryParamsFormat).pipe(map(response => response.result));
    }

    private mapGraphNodeToAssetsTree(graphNodes: GraphData[]) {
        const parents = graphNodes.filter(a => a.depth >= 1);
        const finalList: AssetView[] = [];
        parents.forEach(n => {
            finalList.push(this.getNode(n));
        });

        return finalList;
    }

    private getNode(node: GraphData): AssetView {
        const isParent = !!node.related_nodes.find(a => a.type === 'child');
        const parent_id = node.depth !== 1 ? node.related_nodes.find(a => a.type === 'parent')?.related : undefined;

        if (node.cmdb_data) {
            return {
                ...node.cmdb_data,
                isParent,
                parent_id,
                type: { id: -1, is_locked: true, name: this.graphTypeToAssetType(node.type) },
            };
        } else {
            return {
                id: node.name,
                name: node.name,
                type: { id: -1, is_locked: true, name: this.graphTypeToAssetType(node.type) },
                interfaces: [],
                id_asset_type: -1,
                isParent,
                parent_id,
                score: node.score,
            };
        }
    }

    private graphTypeToAssetType(graphType: string) {
        // ! A voir si on garde

        switch (graphType?.toLowerCase()) {
            case 'asset':
                return 'pc'.toUpperCase();
            case 'network_device':
                return 'routeur'.toUpperCase();
            default:
                return graphType;
        }

        return graphType;
    }

    getDistributionTrafficProtocols(id: string): Observable<DistributionTrafficProtocols[]> {
        return this.http.get<ApiResponse<DistributionTrafficProtocols[]>>('/assets/json/proto.json').pipe(
            map(response => response.result),
            map(val => {
                const shuffledArray = val.sort(() => 0.5 - Math.random());
                return shuffledArray.slice(0, 10);
            })
        );
    }

    getCmdbTypes(): Observable<AssetType[]> {
        return this.http
            .get<ApiResponse<AssetType[]>>(environment.bckAPI.cmdbAPIs.cmdbTypeUrl + '?lang=' + this.localeId)
            .pipe(map(response => this.mapAssetTypeResult(response.result)));
    }

    getCmdbColumns() {
        return this.http.get<ApiResponse<any>>(environment.bckAPI.cmdbAPIs.filterFieldsUrl).pipe(map(r => r.result));
    }

    getCmdbInterfaceCount() {
        return this.http.get<ApiResponse<any>>(environment.bckAPI.cmdbAPIs.cmdbInterfaceCountUrl).pipe(map(r => r.result));
    }

    getCmdbInterfacesFromNetwork(network: string) {
        const formData = new FormData();
        if (!!network) {
            formData.append('network', network);
        }
        return this.http.post<ApiResponse<any>>(environment.bckAPI.cmdbAPIs.cmdbInterfacesFromNetworkUrl, formData).pipe(map(r => r.result));
    }

    getIsFreeInterface(ip: string) {
        return this.http.get<ApiResponse<any>>(environment.bckAPI.cmdbAPIs.cmdbIsInterfaceFreeUrl + ip).pipe(map(r => r.result));
    }


    async getAssetsFilterFieldsForQueryBuilder() {
        // // Récupération des analyzers
        const assetType = await lastValueFrom(this.store.select(selectAssetsTypeAll).pipe(take(1)));
        if (!assetType) return;

        this.getCmdbColumns().subscribe(c => {
            const availableFilters: AvailableFilter[] = this.utils.getAssetsFilterFields(c, assetType);
            availableFilters.sort((a, b) => (a.label > b.label ? 1 : -1));
            this.store.dispatch(setAvailableFilters({ availableFilters }));
        });
    }

    private mapAssetTypeResult(assetTypes: AssetType[]) {
        if (!!assetTypes) {
            assetTypes.forEach(at => {
                at.name = at.name.toLowerCase();
            });
        }
        return assetTypes;
    }

    private mapAssetToTreeGridItems(assets: Asset[]) {
        const assetsView: AssetView[] = [];

        assets.forEach(a => {
            const newAsset: AssetView = { ...a };
            newAsset.type = this.assetsType[a.id_asset_type];
            newAsset.isParent = a.interfaces.length > 0;
            newAsset.parent_id = undefined;

            // Si un PC n'a qu'une seule interface, on la fusionne avec son père
            if (a.id_asset_type === 2 && a.interfaces.length === 1) {
                newAsset.ip_address = a.interfaces[0].ip_address;
                newAsset.ip_gateway = a.interfaces[0].ip_gateway;
                newAsset.ip_mask = a.interfaces[0].ip_mask;
                newAsset.mac = a.interfaces[0].mac;
                newAsset.network = a.interfaces[0].network;
            } else {
                // Sinon, on crée un item et on le lie à son père
                a.interfaces.forEach(i => {
                    const assetInterface: AssetView = {
                        ...i,
                        type: { id: 0, name: 'interface', is_locked: true },
                        interfaces: [],
                        id_asset_type: -1,
                        isParent: i.services.length > 0,
                        parent_id: a.id,
                    };
                    assetsView.push(assetInterface);
                });
            }

            assetsView.push(newAsset);
        });

        return assetsView;
    }
}
