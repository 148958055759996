import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../models/api-response.interface';
import { ClassificationTreeNode } from '../models/score-class-node.interface';
import { Score } from '../models/score.interface';

@Injectable({
    providedIn: 'root',
})
export class ScoringService {
    constructor(@Inject(LOCALE_ID) protected localeId: string, private readonly http: HttpClient) {}

    getScoreList() {
        return this.http.get<ApiResponse<Score[]>>(environment.bckAPI.scoreAPIs.scoreUrl).pipe(
            map(response => {
                response.result.forEach(r => {
                    const realAC = r.ACm !== null ? r.ACm : r.AC;
                    r.Ec = r.Er !== null ? r.Er : (r.UI / 100) * (realAC / 100) * (r.PR / 100) * (r.AV / 100) * 100;
                    r.Ic = r.Ir !== null ? r.Ir : (1 - (1 - r.C / 100) * (1 - r.I / 100) * (1 - r.A / 100)) * 100;
                });
                return response.result;
            })
        );
    }

    getScoreClassList() {
        return this.http.get<ApiResponse<any>>(environment.bckAPI.scoreAPIs.classUrl).pipe(map(response => response.result));
    }

    getScoreClassTree() {
        return this.http
            .get<ApiResponse<ClassificationTreeNode[]>>(environment.bckAPI.scoreAPIs.classTreeUrl + '?lang=' + this.localeId)
            .pipe(
                map(response => {
                    const nodes = response.result;
                    const treeFlat: ClassificationTreeNode[] = [];
                    const topLevelNodesIds: number[] = [];

                    const flatten = (node: any, parentId?: number) => {
                        const newNode: ClassificationTreeNode = {
                            name: node.name,
                            description: node.description,
                            id: node.id,
                            class_type: node.class_type,
                            c_min: node.c_min,
                            c_max: node.c_max,
                            incoming: node.incoming,
                            internal: node.internal,
                            ip_dst: node.ip_dst,
                            ip_src: node.ip_src,
                            mitre_tactic: node.mitre_tactic,
                            mitre_technique: node.mitre_technique,
                            outgoing: node.outgoing,
                            port_dst: node.port_dst,
                            pseudo_event: node.pseudo_event,
                            selected_pseudo_threat: node.selected_pseudo_threat,
                            selected_type_threat: node.selected_type_threat,
                            severity: node.severity,
                            window: node.window,
                            childrenIds: null,
                            parentId: parentId || null,
                        };
                        if (node.children.length > 0) {
                            const childIds: number[] = [];
                            node.children.forEach(child => {
                                childIds.push(child.id);
                                flatten(child, node.id);
                            });
                            newNode.childrenIds = childIds;
                        }
                        treeFlat.push(newNode);
                    };

                    nodes.forEach(node => {
                        flatten(node);
                        topLevelNodesIds.push(node.id);
                    });

                    return { scoreClassTree: treeFlat, topLevelNodesIds };
                })
            );
    }
}
