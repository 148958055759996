import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { Observable, lastValueFrom, of } from 'rxjs';
import { first, mergeMap, take } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AuthActionTypes, logoutRequest, tokenRefreshRequest } from '../shared/store/actions/auth.actions';
import { selectAuthTokens } from '../shared/store/selectors/auth.selectors';
import { AuthState } from '../shared/store/state/auth.state';
import { AuthService } from './auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    private isRefreshing = false;

    constructor(private readonly store: Store<AuthState>, private readonly actions$: Actions) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Consider only adding the auth header to API requests as this will add it to all HTTP requests.
        if (this.checkRequestUrl(request)) {
            if (!this.isRefreshing) {
                this.checkForTokenRefresh().then(needRefresh => {
                    if (needRefresh && !this.isRefreshing) {
                        this.isRefreshing = true;
                        this.store.dispatch(tokenRefreshRequest());
                        this.waitForTokenRefresh();
                    }
                });
            }
            return this.addToken(request).pipe(
                first(),
                mergeMap((requestWithToken: HttpRequest<any>) => next.handle(requestWithToken))
            );
        }
        return next.handle(request);
    }

    /**
     * Adds the JWT token to the request's header.
     */
    private addToken(request: HttpRequest<any>): Observable<HttpRequest<any>> {
        // NOTE: DO NOT try to immediately setup this selector in the constructor or as an assignment in a
        // class member variable as there's no stores available when this interceptor fires fires up and
        // as a result it'll throw a runtime error.
        return this.store.pipe(
            select(selectAuthTokens),
            first(),
            mergeMap(tokens => {
                if (tokens?.access_token) {
                    request = request.clone({
                        headers: request.headers.set('Authorization', `Bearer ${tokens?.access_token}`),
                    });
                }
                return of(request);
            })
        );
    }

    private async checkForTokenRefresh(): Promise<boolean> {
        const token = await lastValueFrom(this.store.select(selectAuthTokens).pipe(first()));

        if (!token) return false;
        const parsedToken = AuthService.parseJwt(token.access_token) as { iat: number; exp: number };
        const tokenStart = parsedToken.iat;
        const tokenEnd = parsedToken.exp;
        const timeDiff = tokenEnd - tokenStart;

        const refreshStartTime = tokenStart + timeDiff / 2;
        const timeNow = Math.floor(new Date().getTime() / 1000);
        if (timeNow > tokenEnd) {
            this.store.dispatch(logoutRequest());
            return true;
        } else if (timeNow >= refreshStartTime) {
            return true;
        }
        return false;
    }

    private waitForTokenRefresh() {
        this.actions$
            .pipe(take(1), ofType(AuthActionTypes.TOKEN_REFRESH_SUCCESS, AuthActionTypes.TOKEN_REFRESH_FAILURE))
            .subscribe((action: Action) => {
                this.isRefreshing = false;
                if (action.type === AuthActionTypes.TOKEN_REFRESH_FAILURE) {
                    this.store.dispatch(logoutRequest());
                }
            });
    }

    private checkRequestUrl(request: HttpRequest<any>): boolean {
        // On ne met pas de token sur l'auth ou le refresh
        if (request.url === environment.bckAPI.authAPIs.loginUrl || request.url === environment.bckAPI.authAPIs.refreshUrl) return false;
        // L'url du back est définie et la requête est une requête vers le back
        if (!!environment.bckAPI.baseUrl && request.url.startsWith(environment.bckAPI.baseUrl)) return true;
        // L'url des notifs est définie et la requête est une requête vers les notifs
        if (!!environment.notifsAPI.baseUrl && request.url.startsWith(environment.notifsAPI.baseUrl)) return true;
        // Par défaut on ne met pas de token
        return false;
    }
}
