import { combineReducers, createReducer, on } from '@ngrx/store';
import {
    loadClassificationTreeFailure,
    loadClassificationTreeRequest,
    loadClassificationTreeSuccess,
    loadScoreParametersFailure,
    loadScoreParametersRequest,
    loadScoreParametersSuccess,
    setMaxBoostAlert,
    setMaxBoostThreat,
    setMaxInitAlert,
    setMaxInitThreat,
} from '../actions/scoring.actions';
import {
    classificationTreeAdapter,
    initialClassificationTreeState,
    initialScoreParametersState,
    initialScoringMaxBoostAndInitState,
    initialScoringState,
    scoreParametersAdapter,
} from '../state/scoring.state';

//#region Score Parameters
export const scoreParametersReducers = createReducer(
    initialScoreParametersState,
    on(loadScoreParametersRequest, state => ({ ...state, isLoading: true, error: null })),
    on(loadScoreParametersSuccess, (state, { scores }) =>
        scoreParametersAdapter.setAll(scores, { ...state, isLoading: false, error: null })
    ),
    on(loadScoreParametersFailure, (state, { error }) => ({ ...state, isLoading: false, error }))
);
//#endregion

//#region Classification Tree
export const classificationTreeReducers = createReducer(
    initialClassificationTreeState,
    on(loadClassificationTreeRequest, state => ({ ...state, isLoading: true, error: null })),
    on(loadClassificationTreeSuccess, (state, { scoreClassTree, topLevelNodesIds }) =>
        classificationTreeAdapter.setAll(scoreClassTree, { ...state, isLoading: false, error: null, topLevelNodesIds })
    ),
    on(loadClassificationTreeFailure, (state, { error }) => ({ ...state, isLoading: false, error }))
);
//#endregion

//#region Max Boost and Init
export const maxBoostAndInitReducers = createReducer(
    initialScoringMaxBoostAndInitState,
    on(setMaxBoostThreat, (state, { newValue }) => ({ ...state, maxBoostThreat: newValue })),
    on(setMaxBoostAlert, (state, { newValue }) => ({ ...state, maxBoostAlert: newValue })),
    on(setMaxInitThreat, (state, { newValue }) => ({ ...state, maxInitThreat: newValue })),
    on(setMaxInitAlert, (state, { newValue }) => ({ ...state, maxInitAlert: newValue }))
);
//#endregion

export const scoringReducer = combineReducers(
    { maxBoostAndInit: maxBoostAndInitReducers, scoreParameters: scoreParametersReducers, classificationTree: classificationTreeReducers },
    initialScoringState
);
