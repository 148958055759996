import { CdkAccordionModule } from '@angular/cdk/accordion';
import { OverlayModule } from '@angular/cdk/overlay';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule, CheckBoxModule, RadioButtonModule, SwitchModule } from '@syncfusion/ej2-angular-buttons';
import { ChartAllModule } from '@syncfusion/ej2-angular-charts';
import { CircularGaugeModule } from '@syncfusion/ej2-angular-circulargauge';
import { ComboBoxModule, DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { GridModule } from '@syncfusion/ej2-angular-grids';
import { ColorPickerModule, TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { SplitterModule } from '@syncfusion/ej2-angular-layouts';
import { AccordionModule, ToolbarModule, TreeViewModule } from '@syncfusion/ej2-angular-navigations';
import { ToastModule } from '@syncfusion/ej2-angular-notifications';
import { TooltipModule } from '@syncfusion/ej2-angular-popups';
import { ProgressBarModule } from '@syncfusion/ej2-angular-progressbar';
import { TreeGridModule } from '@syncfusion/ej2-angular-treegrid';
import { NgApexchartsModule } from 'ng-apexcharts';
import { BarChartComponent } from './components/charts/bar-chart/bar-chart.component';
import { LineChartComponent } from './components/charts/line-chart/line-chart.component';
import { RadarChartComponent } from './components/charts/radar-chart/radar-chart.component';
import { ScatterChartComponent } from './components/charts/scatter-chart/scatter-chart.component';
import { ScatterPlotComponent } from './components/charts/scatter-plot/scatter-plot.component';
import { SyncingChartsComponent } from './components/charts/syncing-charts/syncing-charts.component';
import { LoadingComponent } from './components/loading/loading.component';
import { AutoFocusDirective } from './directives/auto-focus.directive';
import { ResizeObserverDirective } from './directives/resize-observer.directive';
import { KnubeModule } from './modules/knube/knube.module';
import { ClonePipe } from './pipes/clone.pipe';
import { NoSanitizePipe } from './pipes/no-sanitize.pipe';

const importExportComponents = [
    ResizeObserverDirective,
    AutoFocusDirective,
    ClonePipe,
    NoSanitizePipe,
    LoadingComponent,
    RadarChartComponent,
    LineChartComponent,
    ScatterChartComponent,
    SyncingChartsComponent,
    ScatterPlotComponent,
    BarChartComponent,
];
const importExportModules = [
    CommonModule,
    AccordionModule,
    CdkAccordionModule,
    DropDownListModule,
    FormsModule,
    ReactiveFormsModule,
    TooltipModule,
    ToolbarModule,
    SplitterModule,
    ChartAllModule,
    CircularGaugeModule,
    ScrollingModule,
    CheckBoxModule,
    GridModule,
    TreeGridModule,
    TreeViewModule,
    KnubeModule,
    ToastModule,
    SwitchModule,
    ButtonModule,
    RadioButtonModule,
    OverlayModule,
    ColorPickerModule,
    ComboBoxModule,
    TextBoxModule,
];

@NgModule({
    declarations: [...importExportComponents],
    imports: [...importExportModules, ProgressBarModule, NgApexchartsModule],
    exports: [...importExportModules, ...importExportComponents],
})
export class SharedModule {}
