<div class="w-full h-full p-1" *ngIf="!isLoading" (resized)="onResized($event)">
    <apx-chart
        [series]="chartOptions.series"
        [chart]="chartOptions.chart"
        [xaxis]="chartOptions.xaxis"
        [yaxis]="chartOptions.yaxis"
        [title]="chartOptions.title"
        [legend]="chartOptions.legend"
        [dataLabels]="chartOptions.dataLabels"
        [colors]="chartOptions.colors"
        [fill]="chartOptions.fill"
        [markers]="chartOptions.markers"
        [stroke]="chartOptions.stroke"
        [tooltip]="chartOptions.tooltip"
    ></apx-chart>
</div>
