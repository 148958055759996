import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment.prod';
import { AccessToken } from '../models/access-token.interface';
import { Authenticate } from '../models/authenticate.interface';
import { Tokens } from '../models/tokens.interface';
import { User } from '../models/user.interface';
import { ApiResponse } from '../models/api-response.interface';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    static parseJwt<T>(token: string): T | undefined {
        try {
            const base64Url = token.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const jsonPayload = decodeURIComponent(
                atob(base64)
                    .split('')
                    .map(c => {
                        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                    })
                    .join('')
            );
            return JSON.parse(jsonPayload) as T;
        } catch (err) {
            return undefined;
        }
    }

    constructor(private readonly http: HttpClient) { }

    login(auth: Authenticate): Observable<{ tokens: Tokens; user: User }> {
        return this.http.post<Tokens>(environment.bckAPI.authAPIs.loginUrl, { name: auth.username, password: auth.password }).pipe(
            map(tokens => ({
                tokens,
                user: {
                    id: AuthService.parseJwt<AccessToken>(tokens.access_token)?.user_id || undefined,
                    roles: AuthService.parseJwt<AccessToken>(tokens.access_token)?.roles || [],
                    name: auth.username,
                } || {
                    username: '',
                    roles: [],
                },
            }))
        );
    }

    refreshToken(refreshToken: string): Observable<Tokens> {
        return this.http.post<Tokens>(
            environment.bckAPI.authAPIs.refreshUrl,
            {},
            { headers: new HttpHeaders({ Authorization: `Bearer ${refreshToken}` }) }
        );
    }

    logout(): Observable<ApiResponse<any>> {
        return this.http.post<ApiResponse<any>>(environment.bckAPI.authAPIs.logoutUrl, {});
    }
}
