import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { MitreAttackService } from 'src/app/services/mitre-attack.service';
import { AuthActionTypes } from '../actions/auth.actions';
import {
    loadMitreMatrixFailure,
    loadMitreMatrixRequest,
    loadMitreMatrixSuccess,
    loadMitreMitigationsFailure,
    loadMitreMitigationsRequest,
    loadMitreMitigationsSuccess,
    loadMitreDatasourcesFailure,
    loadMitreDatasourcesRequest,
    loadMitreDatasourcesSuccess,
    MitreTypes,
    loadMitreGroupsSuccess,
    loadMitreGroupsFailure,
    loadMitreGroupsRequest,
} from '../actions/mitre.action';
import { AppState } from '../state/app.state';

@Injectable()
export class MitreEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly store: Store<AppState>,
        private readonly mitreService: MitreAttackService
    ) {}

    init$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActionTypes.USER_AUTO_LOGGED_IN, AuthActionTypes.LOGIN_SUCCESS),
                tap(() => {
                    this.store.dispatch(loadMitreMatrixRequest());
                    this.store.dispatch(loadMitreMitigationsRequest());
                    this.store.dispatch(loadMitreDatasourcesRequest());
                    this.store.dispatch(loadMitreGroupsRequest());
                })
            ),
        { dispatch: false }
    );

    mitreMatrixRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MitreTypes.LOAD_MITRE_MATRIX_REQUEST),
            exhaustMap(() =>
                this.mitreService.getMitreMatrix().pipe(
                    map(mitres => loadMitreMatrixSuccess({ mitres })),
                    catchError(error => of(loadMitreMatrixFailure({ error })))
                )
            )
        )
    );

    mitreMitigationsRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MitreTypes.LOAD_MITRE_MITIGATIONS_REQUEST),
            exhaustMap(() =>
                this.mitreService.getMitigations().pipe(
                    map(mitigations => loadMitreMitigationsSuccess({ mitigations })),
                    catchError(error => of(loadMitreMitigationsFailure({ error })))
                )
            )
        )
    );

    mitreDatasourcesRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MitreTypes.LOAD_MITRE_DATASOURCES_REQUEST),
            exhaustMap(() =>
                this.mitreService.getDatasources().pipe(
                    map(datasources => loadMitreDatasourcesSuccess({ datasources })),
                    catchError(error => of(loadMitreDatasourcesFailure({ error })))
                )
            )
        )
    );

    mitreGroupsRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MitreTypes.LOAD_MITRE_GROUPS_REQUEST),
            exhaustMap(() =>
                this.mitreService.getGroups().pipe(
                    map(groups => loadMitreGroupsSuccess({ groups })),
                    catchError(error => of(loadMitreGroupsFailure({ error })))
                )
            )
        )
    );
}
