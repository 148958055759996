import { createAction, props } from '@ngrx/store';
import { DictionaryItem } from 'src/app/models/dictionary-item';
import { FeatureImportanceDictionary } from 'src/app/models/feature-importance-dictionary.interface';
import { NdpiL7Dictionary } from 'src/app/models/ndpil7-dictionary.interface';

export enum DictionaryActionsType {
    LOAD_FI_DICO_REQUEST = '[FI Dico Api] Load Request',
    LOAD_FI_DICO_SUCCESS = '[FI Dico Api] Load Success',
    LOAD_FI_DICO_FAILURE = '[FI Dico Api] Load Failure',

    LOAD_EVENT_DICO_REQUEST = '[Event Dico Api] Load Request',
    LOAD_EVENT_DICO_SUCCESS = '[Event Dico Api] Load Success',
    LOAD_EVENT_DICO_FAILURE = '[Event Dico Api] Load Failure',

    LOAD_THREAT_DICO_REQUEST = '[Threat Dico Api] Load Request',
    LOAD_THREAT_DICO_SUCCESS = '[Threat Dico Api] Load Success',
    LOAD_THREAT_DICO_FAILURE = '[Threat Dico Api] Load Failure',

    LOAD_NDPIL7_DICO_REQUEST = '[NDPI L7 Dico Api] Load Request',
    LOAD_NDPIL7_DICO_SUCCESS = '[NDPI L7 Dico Api] Load Success',
    LOAD_NDPIL7_DICO_FAILURE = '[NDPI L7 Dico Api] Load Failure',
}

//#region Event Dico
export const loadEventDictionaryRequest = createAction(DictionaryActionsType.LOAD_EVENT_DICO_REQUEST);
export const loadEventDictionarySuccess = createAction(
    DictionaryActionsType.LOAD_EVENT_DICO_SUCCESS,
    props<{ events: DictionaryItem[] }>()
);
export const loadEventDictionaryFailure = createAction(DictionaryActionsType.LOAD_EVENT_DICO_FAILURE, props<{ error: any }>());
//#endregion

//#region Threat Dico
export const loadThreatDictionaryRequest = createAction(DictionaryActionsType.LOAD_THREAT_DICO_REQUEST);
export const loadThreatDictionarySuccess = createAction(
    DictionaryActionsType.LOAD_THREAT_DICO_SUCCESS,
    props<{ threats: DictionaryItem[] }>()
);
export const loadThreatDictionaryFailure = createAction(DictionaryActionsType.LOAD_THREAT_DICO_FAILURE, props<{ error: any }>());
//#endregion

//#region FI Dico
export const loadFeatureImportanceDictionaryRequest = createAction(DictionaryActionsType.LOAD_FI_DICO_REQUEST);
export const loadFeatureImportanceDictionarySuccess = createAction(
    DictionaryActionsType.LOAD_FI_DICO_SUCCESS,
    props<{ features_importance: FeatureImportanceDictionary[] }>()
);
export const loadFeatureImportanceDictionaryFailure = createAction(DictionaryActionsType.LOAD_FI_DICO_FAILURE, props<{ error: any }>());
//#endregion

//#region NDPI L7
export const loadNdpiL7DictionaryRequest = createAction(DictionaryActionsType.LOAD_NDPIL7_DICO_REQUEST);
export const loadNdpiL7DictionarySuccess = createAction(
    DictionaryActionsType.LOAD_NDPIL7_DICO_SUCCESS,
    props<{ ndpi_l7: NdpiL7Dictionary[] }>()
);
export const loadNdpiL7DictionaryFailure = createAction(DictionaryActionsType.LOAD_NDPIL7_DICO_FAILURE, props<{ error: any }>());
//#endregion
