import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {
    ApexAnnotations,
    ApexAxisChartSeries,
    ApexChart,
    ApexFill,
    ApexGrid,
    ApexMarkers,
    ApexPlotOptions,
    ApexStroke,
    ApexTitleSubtitle,
    ApexTooltip,
    ApexXAxis,
    ApexYAxis,
    ChartComponent,
    PointAnnotations,
} from 'ng-apexcharts';
import { UtilsService } from 'src/app/shared/utils/utils.service';

export type ChartOptions = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    title: ApexTitleSubtitle;
    stroke: ApexStroke;
    fill: ApexFill;
    markers: ApexMarkers;
    xaxis: ApexXAxis;
    yaxis: ApexYAxis;
    plotOptions: ApexPlotOptions;
    tooltip: ApexTooltip;
    annotations: ApexAnnotations;
    grid: ApexGrid;
};

@Component({
    selector: 'hypervision-scatter-chart',
    templateUrl: './scatter-chart.component.html',
    styleUrls: ['./scatter-chart.component.scss'],
})
export class ScatterChartComponent implements AfterViewInit {
    @ViewChild('chart') chart: ChartComponent;
    @ViewChild('content') elementView: ElementRef;

    chartOptions: any;
    isLoading = true;

    @Input() minX: number | undefined = undefined;
    @Input() minY: number | undefined = undefined;
    @Input() maxX: number | undefined = undefined;
    @Input() maxY: number | undefined = undefined;
    @Input() medX: number | undefined = undefined;
    @Input() medY: number | undefined = undefined;
    @Input() xAxisName: string | undefined = undefined;
    @Input() yAxisName: string | undefined = undefined;
    @Input() markerSize: number = 12;

    @Output() selectionChange = new EventEmitter();

    @Input() set params(v) {
        if (!!v) {
            this.minX = v.minX;
            this.minY = v.minY;
            this.maxX = v.maxX;
            this.maxY = v.maxY;
            this.medX = v.medX;
            this.medY = v.medY;
            this.xAxisName = v.xAxisName;
            this.yAxisName = v.yAxisName;
        }

        this.updateSeries();
    }

    private categoryInternal: string[] = [];
    @Input() set category(v: string[]) {
        this.categoryInternal = v;
        this.updateSeries();
    }
    get category(): string[] {
        return this.categoryInternal;
    }

    private seriesInternal: Array<{ name: string; data: Array<{ x: number; y: number; meta: any }> }> = [];
    @Input() set series(v: Array<{ name: string; data: Array<{ x: number; y: number; meta: any }> }>) {
        this.seriesInternal = v;
        this.updateSeries();
    }
    get series(): Array<{ name: string; data: Array<{ x: number; y: number; meta: any }> }> {
        return this.seriesInternal;
    }

    constructor(private readonly utils: UtilsService) {}

    ngAfterViewInit() {
        this.updateSeries();
    }

    onResized(event: ResizeObserverEntry) {
        if (!!this.chart) {
            this.chart.updateOptions({
                chart: {
                    height: event.contentRect.height * 0.85,
                    width: event.contentRect.width * 0.95,
                },
            });
        }
    }

    private loadData() {
        this.isLoading = true;

        const onSurface = 'rgba(var(--onsurface))';
        const borderColor = 'rgba(var(--primary300))';

        this.chartOptions = {
            series: this.convertSeries(this.series),
            chart: {
                type: 'scatter',
                height: this.elementView?.nativeElement?.offsetHeight * 0.85,
                width: this.elementView?.nativeElement?.offsetWidth * 0.95,
                animations: {
                    enabled: false,
                },
                zoom: {
                    type: 'xy',
                },
                toolbar: {
                    show: true,
                    tools: {
                        download: true,
                        selection: true,
                        zoom: true,
                        zoomin: false,
                        zoomout: false,
                        pan: false,
                        reset: false,
                        customIcons: [
                            {
                                icon: '<div class="icon small home bg-primary-500">',
                                index: 1,
                                title: '',
                                class: 'flex items-center justify-center',
                                click: (chart, options, e) => chart.zoomX(this.minX, this.maxX),
                            },
                        ],
                    },
                },
                events: {
                    click: (event, chartContext, config) => {
                        const asset = config?.config?.series[config.seriesIndex]?.data[config.dataPointIndex];
                        if (asset && asset?.meta) {
                            this.selectionChange.emit(asset?.meta);
                        }
                    },
                    dataPointMouseEnter: event => {
                        event.path[0].style.cursor = 'pointer';
                    },
                },
            },
            legend: {
                show: false,
            },
            plotOptions: {
                bubble: {
                    minBubbleRadius: 5,
                    maxBubbleRadius: 10,
                },
            },
            markers: {
                size: this.markerSize / 2 + 2,
                colors: ['transparent'],
                strokeWidth: 0,
            },
            dataLabels: {
                enabled: false,
            },
            fill: {
                type: ['solid'],
                colors: ['transparent'],
            },
            tooltip: {
                enabled: true,
                followCursor: false,
                fillSeriesColor: false,
                style: {
                    fontSize: '12px',
                    fontFamily: 'Roboto',
                },
                fixed: {
                    enabled: true,
                    position: 'topRight',
                    offsetX: 0,
                    offsetY: 0,
                },
                custom: ({ seriesIndex, dataPointIndex, w }) => {
                    const asset = w.config?.series[seriesIndex]?.data[dataPointIndex];
                    if (asset && asset?.meta) {
                        return `
                        <div class="flex">
                            <div class="flex items-center justify-center border-2 ${this.utils.getColorBgClassNameFromScore(
                                asset.meta.score_max,
                                '/25'
                            )} ${this.utils.getBorderFromScore(asset.meta.score_max)}">
                                <span class="text-2xl ${this.utils.getColorTextClassNameFromScore(
                                    asset.meta.score_max
                                )} font-bold px-4 py-2">${asset.meta.score_max}</span>
                            </div>
                            <div class="flex flex-col p-1">
                                <div class="flex border-b border-primary-500 uppercase font-bold">
                                    <span>${asset.meta.threat_occurency} threats detected on ...</span>
                                </div>
                                <div class="flex">
                                    <div class="flex flex-col">${this.getAssetsForTooltip(asset.meta.items)}</div>
                                </div>
                            </div>
                        </div>`;
                    }

                    return '';
                },
            },
            xaxis: {
                tickAmount: 10,
                type: 'numeric',
                min: this.minX,
                max: this.maxX,
                decimalsInFloat: 0,
                title: {
                    text: '------ ' + this.xAxisName + ' ------>',
                    style: {
                        color: onSurface,
                        fontSize: '12px',
                        fontWeight: '500',
                        fontFamily: 'Roboto',
                        cssClass: 'uppercase',
                    },
                },
                crosshairs: {
                    show: true,
                    position: 'back',
                    stroke: {
                        color: '#b6b6b6',
                        width: 1,
                        dashArray: 2,
                    },
                },
                tooltip: {
                    enabled: true,
                    offsetX: 0,
                },
                labels: {
                    style: {
                        colors: onSurface,
                    },
                },
            },
            yaxis: {
                tickAmount: 10,
                min: this.minY,
                max: this.maxY,
                decimalsInFloat: 0,
                title: {
                    text: '------ ' + this.yAxisName + ' ------>',
                    style: {
                        color: onSurface,
                        fontSize: '12px',
                        fontWeight: '500',
                        fontFamily: 'Roboto',
                        cssClass: 'uppercase',
                    },
                },
                crosshairs: {
                    show: true,
                    position: 'back',
                    stroke: {
                        color: '#b6b6b6',
                        width: 1,
                        dashArray: 2,
                    },
                },
                tooltip: {
                    enabled: true,
                    offsetX: 0,
                },
                labels: {
                    style: {
                        colors: onSurface,
                    },
                },
            },
            annotations: this.generateAnnotations(onSurface),
            grid: {
                show: true,
                borderColor,
                strokeDashArray: 1,
                position: 'back',
                xaxis: {
                    lines: {
                        show: true,
                    },
                },
                yaxis: {
                    lines: {
                        show: true,
                    },
                },
            },
        };

        this.isLoading = false;
    }

    generateAnnotations(backColor: string) {
        const xAxis = this.getXAxisAnnotations(backColor);
        const yAxis = this.getYAxisAnnotations(backColor);
        const points = this.createAnnotations(this.series, xAxis, yAxis);

        return {
            position: 'back',
            yaxis: yAxis,
            xaxis: xAxis,
            points: points,
        };
    }

    private getAssetsForTooltip(items) {
        let retTooltipTemplate = '';
        items.forEach(i => {
            const iconFromType = this.getIconFromNumberType(i.type);
            if (!!i.name) {
                retTooltipTemplate += `<div class="flex items-center"><div class="icon small ${iconFromType} mx-1 bg-onsurface"></div>${i.name}</div>`;
            } else {
                i.ips.forEach(ip => {
                    retTooltipTemplate += `<div class="flex items-center"><div class="icon small ${iconFromType} mx-1 bg-onsurface"></div>${ip}</div>`;
                });
            }
        });

        return retTooltipTemplate;
    }

    private getYAxisAnnotations(color: string) {
        const yAxisAnno: Array<{ y: number; borderColor: string; strokeDashArray: number }> = [];

        if (this.minY !== undefined) {
            yAxisAnno.push({ y: this.minY, borderColor: color, strokeDashArray: 0 });
        }
        if (this.maxY !== undefined) {
            yAxisAnno.push({ y: this.maxY, borderColor: color, strokeDashArray: 0 });
        }
        if (this.medY !== undefined) {
            yAxisAnno.push({ y: this.medY, borderColor: color, strokeDashArray: 0 });
        }

        return yAxisAnno;
    }

    private getXAxisAnnotations(color: string) {
        const xAxisAnno: Array<{ x: number; borderColor: string; strokeDashArray: number }> = [];

        if (this.minX !== undefined) {
            xAxisAnno.push({ x: this.minX, borderColor: color, strokeDashArray: 0 });
        }
        if (this.maxX !== undefined) {
            xAxisAnno.push({ x: this.maxX, borderColor: color, strokeDashArray: 0 });
        }
        if (this.medX !== undefined) {
            xAxisAnno.push({ x: this.medX, borderColor: color, strokeDashArray: 0 });
        }

        return xAxisAnno;
    }

    private updateSeries() {
        if (this.category.length > 0 /*&& this.series.length > 0*/) {
            this.loadData();
        }
    }

    private getCharacterFromType(type: number) {
        if (type === 2) return 'c';
        else if (type === 1) return 'r';
        else if (type === 3) return 'a';
        else if (type === 4) return 't';
        return 'u';
    }

    private getIconFromNumberType(type: number) {
        switch (type) {
            case -1:
                return 'at-interface';
            case 1:
                return 'at-router2';
            case 2:
                return 'at-computer';
            default:
                return 'at-interface';
        }
        return 'at-interface';
    }

    private convertSeries(series: Array<{ name: string; data: Array<{ x: number; y: number; meta: any }> }>) {
        const convertedSeries: Array<{ name: string; data: Array<{ x: number; y: number; meta: any }> }> = [];
        series.forEach(s => convertedSeries.push({ name: s.name, data: s.data }));
        return convertedSeries;
    }

    private createAnnotations(
        series: Array<{ name: string; data: Array<{ x: number; y: number; meta: any }> }>,
        xAxis,
        yAxis
    ): PointAnnotations[] {
        const annotationsPoints: PointAnnotations[] = [];
        const fontSize = 22;

        series.forEach(p => {
            p.data.forEach(e => {
                const selected = e.meta?.selected ? e.meta?.selected : false;

                const highlightColor = selected ? this.utils.getColorFromScore(e.y) : 'transparent';
                const backHighlightColor = selected ? this.utils.getColorFromScore(e.y, '0.2') : 'transparent';
                const padding = selected ? 6 : 0;

                if (selected) {
                    xAxis.push({ x: e.x, borderColor: highlightColor, strokeDashArray: 0, opacity: 1 });
                    yAxis.push({ y: e.y, borderColor: highlightColor, strokeDashArray: 0, opacity: 1 });
                }

                annotationsPoints.push({
                    x: e.x,
                    y: e.y,
                    marker: {
                        size: 0,
                    },
                    label: {
                        borderColor: highlightColor,
                        borderWidth: 2,
                        borderRadius: 100,
                        text: this.getCharacterFromType(e.meta.type),
                        textAnchor: 'middle',
                        offsetX: 0,
                        offsetY: fontSize + this.markerSize / 2 - 3,
                        style: {
                            background: backHighlightColor,
                            color: this.utils.getColorFromScore(e.y),
                            fontSize: fontSize + 'px',
                            fontFamily: 'Assets',
                            cssClass: 'apexcharts-point-annotation-label',
                            padding: {
                                left: padding,
                                right: padding,
                                top: padding,
                                bottom: padding,
                            },
                        },
                    },
                });
            });
        });

        return annotationsPoints;
    }
}
