import { createAction, props } from '@ngrx/store';
import { AssetView } from 'src/app/models/asset-view.interface';
import { TreeGridCol } from 'src/app/models/tree-grid-col.interface';
import { AssetType } from '../../../models/asset-type.interface';

export enum AssetsActionTypes {
    ASSETS_LOAD_REQUEST = '[Assets] Load Request',
    ASSETS_LOAD_FAILURE = '[Assets] Load Failure',
    ASSETS_LOAD_SUCCESS = '[Assets] Load Success',
    ASSETS_UPDATE_ONE = '[Assets] Update One',
    ASSETS_UPDATE_MANY = '[Assets] Update Many',

    ASSETS_TYPE_LOAD_REQUEST = '[Assets Type] Load Request',
    ASSETS_TYPE_LOAD_FAILURE = '[Assets Type] Load Failure',
    ASSETS_TYPE_LOAD_SUCCESS = '[Assets Type] Load Success',

    ASSETS_COL_UPSERT_MANY = '[Assets Col] Upsert Many Col',
    ASSETS_COL_ADD_ONE = '[Assets Col] Add One Col',
    ASSETS_COL_ADD_MANY = '[Assets Col] Add Many Col',
    ASSETS_COL_UPDATE_ONE = '[Assets Col] Update One Col',
    ASSETS_COL_UPDATE_MANY = '[Assets Col] Update Many Col',
}

export const loadAssetsRequestAction = createAction(
    AssetsActionTypes.ASSETS_LOAD_REQUEST,
    props<{ fromDate: string; toDate: string; limit: number; offset: number; isGraph: boolean }>()
);
export const loadAssetsFailureAction = createAction(AssetsActionTypes.ASSETS_LOAD_FAILURE, props<{ error: string }>());
export const loadAssetsSuccessAction = createAction(AssetsActionTypes.ASSETS_LOAD_SUCCESS, props<{ assets: AssetView[] }>());
export const assetsUpdateOne = createAction(AssetsActionTypes.ASSETS_UPDATE_ONE, props<{ updatedAsset: AssetView }>());
export const assetsUpdateMany = createAction(AssetsActionTypes.ASSETS_UPDATE_MANY, props<{ updatedAssets: AssetView[] }>());

export const loadAssetsTypeRequestAction = createAction(AssetsActionTypes.ASSETS_TYPE_LOAD_REQUEST);
export const loadAssetsTypeFailureAction = createAction(AssetsActionTypes.ASSETS_TYPE_LOAD_FAILURE, props<{ error: string }>());
export const loadAssetsTypeSuccessAction = createAction(AssetsActionTypes.ASSETS_TYPE_LOAD_SUCCESS, props<{ types: AssetType[] }>());

export const assetsColUpsertMany = createAction(AssetsActionTypes.ASSETS_COL_UPSERT_MANY, props<{ newCols: TreeGridCol[] }>());
export const assetsColAddOne = createAction(AssetsActionTypes.ASSETS_COL_ADD_ONE, props<{ newCol: TreeGridCol }>());
export const assetsColAddMany = createAction(AssetsActionTypes.ASSETS_COL_ADD_MANY, props<{ newCols: TreeGridCol[] }>());
export const assetsColUpdateOne = createAction(AssetsActionTypes.ASSETS_COL_UPDATE_ONE, props<{ updatedCol: TreeGridCol }>());
export const assetsColUpdateMany = createAction(AssetsActionTypes.ASSETS_COL_UPDATE_MANY, props<{ updatedCols: TreeGridCol[] }>());
