import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { DictionaryItem } from 'src/app/models/dictionary-item';
import { NdpiL7Dictionary } from 'src/app/models/ndpil7-dictionary.interface';
import { FeatureImportanceDictionary } from 'src/app/models/feature-importance-dictionary.interface';

//#region Event dico

export const eventDictionaryAdapter = createEntityAdapter<DictionaryItem>({
    selectId: item => item.id,
});

export interface EventDictionaryState extends EntityState<DictionaryItem> {
    isLoading: boolean;
    error: any;
}

export const initialEventDictionaryState: EventDictionaryState = eventDictionaryAdapter.getInitialState({
    isLoading: false,
    error: null,
});

//#endregion

//#region Threat dico

export const threatDictionaryAdapter = createEntityAdapter<DictionaryItem>({
    selectId: item => item.id,
});

export interface ThreatDictionaryState extends EntityState<DictionaryItem> {
    isLoading: boolean;
    error: any;
}

export const initialThreatDictionaryState: ThreatDictionaryState = threatDictionaryAdapter.getInitialState({
    isLoading: false,
    error: null,
});

//#endregion

//#region Feature Importance Dictionary

export const featureImportanceDictionaryAdapter = createEntityAdapter<FeatureImportanceDictionary>({
    selectId: item => item.id,
});
export interface FeatureImportanceDictionaryState extends EntityState<FeatureImportanceDictionary> {
    isLoading: boolean;
    error: any;
}
export const initialFeatureImportanceDictionaryState: FeatureImportanceDictionaryState = featureImportanceDictionaryAdapter.getInitialState(
    {
        isLoading: false,
        error: null,
    }
);

//#endregion

//#region NDPI L7 Dictionary

export const ndpiL7DictionaryAdapter = createEntityAdapter<NdpiL7Dictionary>({
    selectId: item => item.id,
});
export interface NdpiL7DictionaryState extends EntityState<NdpiL7Dictionary> {
    isLoading: boolean;
    error: any;
}
export const initialNdpiL7DictionaryState: NdpiL7DictionaryState = ndpiL7DictionaryAdapter.getInitialState({
    isLoading: false,
    error: null,
});

//#endregion

export interface DictionaryState {
    events: EventDictionaryState;
    threats: ThreatDictionaryState;
    features_importance: FeatureImportanceDictionaryState;
    ndpi_l7: NdpiL7DictionaryState;
}

export const initialDictionaryState: DictionaryState = {
    events: initialEventDictionaryState,
    threats: initialThreatDictionaryState,
    features_importance: initialFeatureImportanceDictionaryState,
    ndpi_l7: initialNdpiL7DictionaryState,
};
