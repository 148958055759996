import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { ScoringService } from '../../../services/scoring.service';
import { AuthActionTypes } from '../actions/auth.actions';
import {
    loadClassificationTreeFailure,
    loadClassificationTreeRequest,
    loadClassificationTreeSuccess,
    loadScoreParametersFailure,
    loadScoreParametersRequest,
    loadScoreParametersSuccess,
    ScoringActionTypes,
} from '../actions/scoring.actions';
import { ScoringState } from '../state/scoring.state';

@Injectable()
export class ScoringEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly store: Store<ScoringState>,
        private readonly scoring: ScoringService
    ) {}

    init$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActionTypes.USER_AUTO_LOGGED_IN, AuthActionTypes.LOGIN_SUCCESS),
                tap(() => {
                    this.store.dispatch(loadScoreParametersRequest());
                    this.store.dispatch(loadClassificationTreeRequest());
                })
            ),
        { dispatch: false }
    );

    scoreParametersRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ScoringActionTypes.LOAD_SCORE_PARAMETERS_REQUEST),
            exhaustMap(() =>
                this.scoring.getScoreList().pipe(
                    map(scores => loadScoreParametersSuccess({ scores })),
                    catchError(error => of(loadScoreParametersFailure({ error })))
                )
            )
        )
    );

    classificationTreeRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ScoringActionTypes.LOAD_CLASSIFICATION_TREE_REQUEST),
            exhaustMap(() =>
                this.scoring.getScoreClassTree().pipe(
                    map(({ scoreClassTree, topLevelNodesIds }) => loadClassificationTreeSuccess({ scoreClassTree, topLevelNodesIds })),
                    catchError(error => of(loadClassificationTreeFailure({ error })))
                )
            )
        )
    );
}
