import { combineReducers, createReducer, on } from '@ngrx/store';
import {
    loadMitreMatrixFailure,
    loadMitreMatrixRequest,
    loadMitreMatrixSuccess,
    loadMitreMitigationsFailure,
    loadMitreMitigationsRequest,
    loadMitreMitigationsSuccess,
    loadMitreDatasourcesFailure,
    loadMitreDatasourcesRequest,
    loadMitreDatasourcesSuccess,
    loadMitreGroupsFailure,
    loadMitreGroupsRequest,
    loadMitreGroupsSuccess,
} from '../actions/mitre.action';
import {
    initialMitreDatasourcesState,
    initialMitreMatrixState,
    initialMitreMitigationsState,
    initialMitreState,
    mitreMatrixAdapter,
    mitreMitigationsAdapter,
    mitreDatasourcesAdapter,
    initialMitreGroupsState,
    mitreGroupsAdapter,
} from '../state/mitre.state';

//----------------------------------------------------------------------------------------------------------
export const mitreMatrixReducer = createReducer(
    initialMitreMatrixState,
    on(loadMitreMatrixRequest, state => ({ ...state, isLoading: true, error: null })),
    on(loadMitreMatrixSuccess, (state, { mitres }) => mitreMatrixAdapter.setAll(mitres, { ...state, isLoading: false, error: null })),
    on(loadMitreMatrixFailure, (state, { error }) => ({ ...state, isLoading: false, error }))
);

//----------------------------------------------------------------------------------------------------------
export const mitreMitigationsReducer = createReducer(
    initialMitreMitigationsState,
    on(loadMitreMitigationsRequest, state => ({ ...state, isLoading: true, error: null })),
    on(loadMitreMitigationsSuccess, (state, { mitigations }) =>
        mitreMitigationsAdapter.setAll(mitigations, { ...state, isLoading: false, error: null })
    ),
    on(loadMitreMitigationsFailure, (state, { error }) => ({ ...state, isLoading: false, error }))
);

//----------------------------------------------------------------------------------------------------------
export const mitreDatasourcesReducer = createReducer(
    initialMitreDatasourcesState,
    on(loadMitreDatasourcesRequest, state => ({ ...state, isLoading: true, error: null })),
    on(loadMitreDatasourcesSuccess, (state, { datasources }) =>
        mitreDatasourcesAdapter.setAll(datasources, { ...state, isLoading: false, error: null })
    ),
    on(loadMitreDatasourcesFailure, (state, { error }) => ({ ...state, isLoading: false, error }))
);

//----------------------------------------------------------------------------------------------------------
export const mitreGroupsReducer = createReducer(
    initialMitreGroupsState,
    on(loadMitreGroupsRequest, state => ({ ...state, isLoading: true, error: null })),
    on(loadMitreGroupsSuccess, (state, { groups }) => mitreGroupsAdapter.setAll(groups, { ...state, isLoading: false, error: null })),
    on(loadMitreGroupsFailure, (state, { error }) => ({ ...state, isLoading: false, error }))
);

export const mitreReducer = combineReducers(
    { matrix: mitreMatrixReducer, mitigations: mitreMitigationsReducer, datasources: mitreDatasourcesReducer, groups: mitreGroupsReducer },
    initialMitreState
);
