<div class="flex-auto flex flex-col p-1 bg-surface text-onsurface">
    <form class="flex-auto flex flex-col justify-center" autocomplete="off">
        <div class="flex flex-col items-center w-full pt-1 mb-2">
            <label>Username</label>
            <input type="text" autocomplete="false" name="username" [(ngModel)]="userEditUsername" />
        </div>
        <div class="flex flex-col items-center w-full pt-1 mb-2">
            <label>Password</label>
            <input type="password" autocomplete="new-password" name="newpassword" [(ngModel)]="userEditPassword" />
        </div>
        <div class="flex flex-col items-center w-full pt-1 mb-2">
            <label>Role</label>
            <div class="flex w-full justify-evenly items-center p-1">
                <div *ngFor="let role of availableRoles" class="flex items-center">
                    <input type="checkbox" [disabled]="disableItem(role)" [checked]="userEditRoles.includes(role)"
                        (change)="updateUserEditRole(role)" />
                    <span class="px-1">{{ role }}</span>
                </div>
            </div>
        </div>
        <div class="flex justify-end">
            <button
                class="p-1 rounded bg-secondary-500 hover:bg-secondary-600 text-onsecondary disabled:opacity-80 disabled:cursor-not-allowed mx-1"
                (click)="updateUser()" type="submit">
                Validate
            </button>
            <button
                class="p-1 text-white bg-red-500 hover:bg-red-600 disabled:opacity-80 disabled:cursor-not-allowed rounded mx-1"
                (click)="closePopup()" type="reset">
                Cancel
            </button>
        </div>
    </form>
</div>