<div class="relative w-full h-full flex items-center justify-center bg-surface" #content (resized)="onResized($event)">
    <apx-chart
        #chart
        *ngIf="!isLoading"
        [series]="chartOptions.series"
        [chart]="chartOptions.chart"
        [labels]="chartOptions.labels"
        [responsive]="chartOptions.responsive"
        [legend]="chartOptions.legend"
        [plotOptions]="chartOptions.plotOptions"
        [dataLabels]="chartOptions.dataLabels"
        [fill]="chartOptions.fill"
        [stroke]="chartOptions.stroke"
        [colors]="chartOptions.colors"
        [markers]="chartOptions.markers"
        [tooltip]="chartOptions.tooltip"
        [yaxis]="chartOptions.yaxis"
        [xaxis]="chartOptions.xaxis"
        [annotations]="chartOptions.annotations"
        [grid]="chartOptions.grid"
    >
    </apx-chart>
</div>
