import { createAction, props } from '@ngrx/store';
import { Authenticate } from '../../../models/authenticate.interface';
import { Tokens } from '../../../models/tokens.interface';
import { User } from '../../../models/user.interface';
import { AuthError } from '../state/auth.state';

export enum AuthActionTypes {
    LOGIN_REQUEST = '[Auth API] Login Request',
    LOGIN_SUCCESS = '[Auth API] Login Success',
    LOGIN_FAILURE = '[Auth API] Login Failure',
    TOKEN_REFRESH_REQUEST = '[Auth API] Refresh Token request',
    TOKEN_REFRESH_SUCCESS = '[Auth API] Refresh Token success',
    TOKEN_REFRESH_FAILURE = '[Auth API] Refresh Token failure',
    LOGOUT_REQUEST = '[Auth API] Logout Request',
    LOGOUT_SUCCESS = '[Auth API] Logout Success',
    LOGOUT_FAILURE = '[Auth API] Logout Failure',
    USER_AUTO_LOGGED_IN = '[Auth] User logged in from cache',
    FRESH_LOGIN = '[Auth] User just logged in',
}

export const loginRequest = createAction(AuthActionTypes.LOGIN_REQUEST, props<Authenticate>());
export const loginSuccess = createAction(AuthActionTypes.LOGIN_SUCCESS, props<{ tokens: Tokens; user: User }>());
export const loginFailure = createAction(AuthActionTypes.LOGIN_FAILURE, props<AuthError>());
export const logoutRequest = createAction(AuthActionTypes.LOGOUT_REQUEST);
export const logoutSuccess = createAction(AuthActionTypes.LOGOUT_SUCCESS);
export const logoutFailure = createAction(AuthActionTypes.LOGOUT_FAILURE, props<AuthError>());
export const tokenRefreshRequest = createAction(AuthActionTypes.TOKEN_REFRESH_REQUEST);
export const tokenRefreshSuccess = createAction(AuthActionTypes.TOKEN_REFRESH_SUCCESS, props<{ tokens: Tokens }>());
export const tokenRefreshFailure = createAction(AuthActionTypes.TOKEN_REFRESH_FAILURE, props<AuthError>());
export const setFreshLogin = createAction(AuthActionTypes.FRESH_LOGIN, props<{ freshLogin: boolean }>());
