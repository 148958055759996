<div class="h-full w-full flex flex-col items-center justify-center p-2">
    <div #scatterPlotContent class="flex flex-col w-full h-full items-center justify-center" (resized)="onResized($event)">
        <ejs-chart
            #scatterPlot
            id="scatterPlot"
            class="block"
            [background]="'transparent'"
            [chartArea]="chartArea"
            [height]="height"
            [width]="width"
            [title]="title"
            [legendSettings]="legendSettings"
            [primaryXAxis]="primaryXAxis"
            [primaryYAxis]="primaryYAxis"
            [tooltip]="tooltip"
            [zoomSettings]="zoomSettings"
            [crosshair]="crosshair"
            enableAnimation="true"
            selectionMode="Point"
            (pointRender)="pointRender($event)"
            (pointClick)="pointClick($event)"
            (tooltipRender)="tooltipRender($event)"
            (axisLabelRender)="axisLabelRender($event)"
        >
            <e-series-collection>
                <e-series
                    [dataSource]="serieLowBg.data"
                    fill="url(#lowPattern)"
                    [border]="serieLowBg.border"
                    type="StackingArea"
                    opacity="1"
                    xName="x"
                    yName="y"
                >
                </e-series>
                <e-series
                    [dataSource]="serieMediumBg.data"
                    fill="url(#mediumPattern)"
                    [border]="serieMediumBg.border"
                    type="StackingArea"
                    opacity="1"
                    xName="x"
                    yName="y"
                >
                </e-series>
                <e-series
                    [dataSource]="serieHighBg.data"
                    fill="url(#highPattern)"
                    [border]="serieHighBg.border"
                    type="StackingArea"
                    opacity="1"
                    xName="x"
                    yName="y"
                >
                </e-series>
                <e-series
                    [dataSource]="serieCriticalBg.data"
                    fill="url(#criticalPattern)"
                    [border]="serieCriticalBg.border"
                    type="StackingArea"
                    opacity="1"
                    xName="x"
                    yName="y"
                >
                </e-series>

                <e-series
                    [dataSource]="serieHistory"
                    name="historyLine"
                    type="Line"
                    opacity="1"
                    width="1"
                    xName="x"
                    yName="y"
                    [dashArray]="'4, 4'"
                    [fill]="'rgb(var(--onsurface))'"
                >
                </e-series>
                <e-series
                    [dataSource]="serieHistory"
                    name="history"
                    type="Scatter"
                    opacity="0.6"
                    width="2"
                    xName="x"
                    yName="y"
                    [pointColorMapping]="'color'"
                    [marker]="markerHistory"
                    [animation]="historyAnimation"
                >
                </e-series>
                <e-series [dataSource]="serieLow" name="low" type="Scatter" xName="x" yName="y" [marker]="markerLow"> </e-series>
                <e-series [dataSource]="serieMedium" name="medium" type="Scatter" xName="x" yName="y" [marker]="markerMedium"> </e-series>
                <e-series [dataSource]="serieHigh" name="high" type="Scatter" xName="x" yName="y" [marker]="markerHigh"> </e-series>
                <e-series [dataSource]="serieCritical" name="critical" type="Scatter" xName="x" yName="y" [marker]="markerCritical">
                </e-series>
            </e-series-collection>
        </ejs-chart>
    </div>
</div>

<svg width="0" height="0">
    <pattern #lowPattern id="lowPattern" patternUnits="userSpaceOnUse" width="5" height="10" patternTransform="rotate(45 0 0)">
        <line x1="0" y1="0" x2="0" y2="10" style="stroke: {{ serieLowBg.fill }}; stroke-width: 2" />
    </pattern>
    <pattern #mediumPattern id="mediumPattern" patternUnits="userSpaceOnUse" width="5" height="10" patternTransform="rotate(-45 0 0)">
        <line x1="0" y1="0" x2="0" y2="10" style="stroke: {{ serieMediumBg.fill }}; stroke-width: 2" />
    </pattern>
    <pattern #highPattern id="highPattern" patternUnits="userSpaceOnUse" width="5" height="10" patternTransform="rotate(45 0 0)">
        <line x1="0" y1="0" x2="0" y2="10" style="stroke: {{ serieHighBg.fill }}; stroke-width: 2" />
    </pattern>
    <pattern #criticalPattern id="criticalPattern" patternUnits="userSpaceOnUse" width="5" height="10" patternTransform="rotate(-45 0 0)">
        <line x1="0" y1="0" x2="0" y2="10" style="stroke: {{ serieCriticalBg.fill }}; stroke-width: 2" />
    </pattern>
</svg>
