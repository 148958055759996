import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of, take } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { UserViewSettingsService } from '../../../services/user-view-settings.service';
import { AuthActionTypes } from '../actions/auth.actions';
import {
    loadViewSettingsFailure,
    loadViewSettingsRequest,
    loadViewSettingsSuccess,
    ViewSettingsTypes,
} from '../actions/view-settings.actions';
import { selectViewSettingsAll } from '../selectors/view-settings.selectors';
import { ViewSettingsState } from '../state/view-settings.state';

@Injectable()
export class ViewSettingsEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly store: Store<ViewSettingsState>,
        private readonly userViewSettings: UserViewSettingsService
    ) {}

    init$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActionTypes.USER_AUTO_LOGGED_IN, AuthActionTypes.LOGIN_SUCCESS),
                tap(() => {
                    this.store.dispatch(loadViewSettingsRequest());
                })
            ),
        { dispatch: false }
    );

    loadViewSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ViewSettingsTypes.LOAD_VIEW_SETTINGS_REQUEST),
            exhaustMap(() =>
                this.userViewSettings.loadViewSettings().pipe(
                    map(viewSettings => loadViewSettingsSuccess({ viewSettings })),
                    catchError(error => of(loadViewSettingsFailure({ error })))
                )
            )
        )
    );

    updateViewSettings$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ViewSettingsTypes.UPDATE_VIEW_SETTINGS),
                tap(() => {
                    this.store
                        .select(selectViewSettingsAll)
                        .pipe(take(1))
                        .subscribe(viewSettings => {
                            this.userViewSettings.saveViewSettings(viewSettings);
                        });
                })
            ),
        { dispatch: false }
    );
}
