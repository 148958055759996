import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
    ApexAxisChartSeries,
    ApexChart,
    ApexDataLabels,
    ApexFill,
    ApexLegend,
    ApexMarkers,
    ApexStroke,
    ApexTooltip,
    ApexXAxis,
    ApexYAxis,
    ChartComponent,
} from 'ng-apexcharts';

export type ChartOptions = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    xaxis: ApexXAxis;
    dataLabels: ApexDataLabels;
    yaxis: ApexYAxis;
    fill: ApexFill;
    stroke: ApexStroke;
    markers: ApexMarkers;
    colors: string[];
    legend: ApexLegend;
    tooltip: ApexTooltip;
};

@Component({
    selector: 'hypervision-line-chart',
    templateUrl: './line-chart.component.html',
    styleUrls: ['./line-chart.component.scss'],
})
export class LineChartComponent implements OnInit {
    @ViewChild('chart') chart: ChartComponent;
    isLoading = true;
    chartOptions;

    @Input() title = '';

    @Input() strokeCurvature = 'smooth'; //straight, smooth, stepline;
    @Input() defaultColors = false;

    private categoryInternal: string[] = [];
    @Input() set category(v: string[]) {
        this.categoryInternal = v;
        this.updateSeries();
    }
    get category(): string[] {
        return this.categoryInternal;
    }

    private seriesInternal: Array<{ name: string; data: number[] }> = [];
    @Input() set series(v: Array<{ name: string; data: number[] }>) {
        this.seriesInternal = v;
        this.updateSeries();
    }
    get series(): Array<{ name: string; data: number[] }> {
        return this.seriesInternal;
    }

    constructor() { }

    ngOnInit(): void {
        this.updateSeries();
    }

    onResized(event: ResizeObserverEntry) {
        if (!!this.chart) {
            this.chart.updateOptions({
                chart: {
                    height: event.contentRect.height * 0.8,
                    width: event.contentRect.width,
                },
            });
        }
    }

    private loadData() {
        const headerColor = `rgba(var(--primary500))`;
        const greenColor = `rgba(var(--low-criticity))`;
        const redColor = `rgba(var(--high-criticity))`;

        let colors = [greenColor, redColor];
        if (this.defaultColors) {
            colors = [];
        }

        this.chartOptions = {
            series: this.series,
            chart: {
                type: 'line',
                stacked: false,
                width: '100%',
                height: 250,
                zoom: {
                    type: 'x',
                    enabled: true,
                },
                toolbar: {
                    autoSelected: 'zoom',
                },
                animations: {
                    enabled: false,
                },
            },
            colors: colors?.length ? colors : undefined,
            stroke: {
                show: true,
                curve: this.strokeCurvature,
                width: 2,
            },
            title: {
                text: this.title?.toUpperCase(),
                align: 'left',
                style: {
                    fontSize: '14px',
                    fontWeight: 'normal',
                    fontFamily: 'Roboto',
                    color: headerColor,
                },
            },
            grid: {},
            dataLabels: {
                enabled: false,
            },
            markers: {
                size: 0,
                strokeWidth: 0,
            },
            xaxis: {
                type: 'datetime',
                categories: this.category,
                tooltip: {
                    enabled: false,
                },
            },
            yaxis: {
                tickAmount: 3,
                labels: {
                    show: true,
                    style: {
                        fontSize: '12px',
                        fontFamily: 'Roboto',
                        fontWeight: 400,
                    },
                    formatter: (value: number) => {
                        return Math.floor(value);
                    },
                },
            },
            legend: {
                horizontalAlign: 'center',
                showForSingleSeries: true,
                fontSize: '14px',
                fontFamily: 'Roboto',
            },
            tooltip: {
                followCursor: true,
                style: {
                    fontSize: '12px',
                    fontFamily: 'Roboto',
                },
                x: {
                    show: true,
                    format: 'dd MMM yyyy, HH:mm:ss',
                },
            },
        };

        this.isLoading = false;
    }

    private updateSeries() {
        if (this.category.length > 0 && this.series.length > 0) {
            this.loadData();
        }
    }
}
