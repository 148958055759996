import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/user.interface';
import { selectAuthUser } from 'src/app/shared/store/selectors/auth.selectors';
import { AuthState } from 'src/app/shared/store/state/auth.state';
import { UtilsService } from 'src/app/shared/utils/utils.service';
import { AdminService } from '../../services/admin.service';

@Component({
    selector: 'hypervision-user-edit',
    templateUrl: './user-edit.component.html',
    styleUrls: ['./user-edit.component.scss'],
})
export class UserEditComponent implements OnInit, OnDestroy {
    @Input() set user(v: User) {
        this.initHMI(v);
        this.userEdit = true;
    }
    @Output() askForClose = new EventEmitter<null>();

    //! Désactivation des comptes utilisateurs pour l'instant
    availableRoles: string[] = ['USER', 'ADMIN'];
    //availableRoles: string[] = ['SUPERADMIN', 'ADMIN', 'USER'];
    userEdit = false;
    userEditId: number | undefined;
    userEditUsername = '';
    userEditPassword = '';
    userEditRoles: string[] = ['USER'];
    currentUser: User | null = null;

    private readonly subscriptions: Subscription[] = [];

    constructor(readonly utils: UtilsService, private readonly adminService: AdminService, private readonly store: Store<AuthState>) {}

    ngOnInit(): void {
        this.subscriptions.push(this.store.select(selectAuthUser).subscribe(u => (this.currentUser = u)));
    }

    ngOnDestroy(): void {
        for (const sub of this.subscriptions) {
            sub.unsubscribe();
        }
    }

    private initHMI(user: User) {
        this.userEditId = user.id;
        this.userEditUsername = user.name;
        this.userEditRoles = user.roles;
    }

    disableItem(role) {
        switch (role) {
            case 'USER':
                return true;
            default:
                return !this.utils.isSuperAdmin(this.currentUser);
        }
    }

    updateUserEditRole(role: string) {
        if (this.userEditRoles.includes(role)) {
            const idx = this.userEditRoles.indexOf(role);
            this.userEditRoles.splice(idx, 1);
        } else {
            this.userEditRoles.push(role);
        }
    }

    updateUser() {
        if (this.userEdit) {
            this.adminService
                .updateUser(this.userEditId, {
                    newUsername: this.userEditUsername,
                    newPassword: this.userEditPassword,
                    newRoles: this.userEditRoles,
                })
                .subscribe(ret => {
                    this.askForClose.emit();
                });
        } else {
            this.adminService
                .updateUser(undefined, {
                    newUsername: this.userEditUsername,
                    newPassword: this.userEditPassword,
                    newRoles: this.userEditRoles,
                })
                .subscribe(ret => {
                    this.askForClose.emit();
                });
        }
    }

    closePopup() {
        this.askForClose.emit();
    }
}
