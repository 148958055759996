<div class="w-full h-full flex flex-col overflow-x-hidden relative" #content>
    <div class="w-full relative" [style.height]="realMinTimelineHeight + 'px'" (contextmenu)="rightClick($event)">
        <div class="absolute w-full h-full" [style.left]="'-' + offset + 'px'">
            <apx-chart
                #chartTrafficTimeline
                [series]="chart3options.series"
                [chart]="chart3options.chart"
                [plotOptions]="chart3options.plotOptions"
                [xaxis]="chart3options.xaxis"
                [yaxis]="chart3options.yaxis"
                [stroke]="chart3options.stroke"
                [fill]="chart3options.fill"
                [tooltip]="chart3options.tooltip"
                [title]="chart3options.title"
                [grid]="chart3options.grid"
            ></apx-chart>
        </div>
    </div>
    <div class="w-full relative" (contextmenu)="rightClick($event)" [style.height]="minChart1Height + 'px'">
        <div class="absolute w-full h-full" [style.left]="'-' + offset + 'px'">
            <apx-chart
                #chartTrafficIn
                [series]="chart1options.series"
                [chart]="chart1options.chart"
                [colors]="chart1options.colors"
                [yaxis]="chart1options.yaxis"
                [dataLabels]="commonOptions.dataLabels"
                [markers]="chart1options.markers"
                [stroke]="commonOptions.stroke"
                [grid]="commonOptions.grid"
                [xaxis]="chart1options.xaxis"
                [tooltip]="commonOptions.tooltip"
                [fill]="chart1options.fill"
                [title]="chart1options.title"
            ></apx-chart>
        </div>
    </div>
    <div class="w-full relative" (contextmenu)="rightClick($event)" [style.height]="minChart2Height + 'px'">
        <div class="absolute w-full h-full" [style.left]="'-' + offset + 'px'">
            <apx-chart
                #chartTrafficOut
                [series]="chart2options.series"
                [chart]="chart2options.chart"
                [colors]="chart2options.colors"
                [yaxis]="chart2options.yaxis"
                [dataLabels]="commonOptions.dataLabels"
                [markers]="chart2options.markers"
                [stroke]="commonOptions.stroke"
                [grid]="commonOptions.grid"
                [xaxis]="chart2options.xaxis"
                [tooltip]="commonOptions.tooltip"
                [fill]="chart2options.fill"
                [title]="chart2options.title"
            ></apx-chart>
        </div>
    </div>
    <div class="absolute top-0 right-0 text-onsurface/70 text-sm"><h6>* right click on the chart to unzoom</h6></div>
</div>
