import { combineReducers, createReducer, on } from '@ngrx/store';
import {
    assetsColAddMany,
    assetsColAddOne,
    assetsColUpdateMany,
    assetsColUpdateOne,
    assetsColUpsertMany,
    assetsUpdateMany,
    assetsUpdateOne,
    loadAssetsFailureAction,
    loadAssetsRequestAction,
    loadAssetsSuccessAction,
    loadAssetsTypeFailureAction,
    loadAssetsTypeRequestAction,
    loadAssetsTypeSuccessAction,
} from '../actions/assets.actions';
import {
    assetsAdapter,
    assetsColAdapter,
    assetsTypeAdapter,
    initialAssetsColState,
    initialAssetsState,
    initialAssetsStoreState,
    initialAssetsTypeState,
} from '../state/assets.state';

export const assetsListReducer = createReducer(
    initialAssetsState,
    on(loadAssetsRequestAction, state => ({ ...state, isLoading: true, error: null })),
    on(loadAssetsSuccessAction, (state, { assets }) => assetsAdapter.setAll(assets, { ...state, isLoading: false })),
    on(loadAssetsFailureAction, (state, { error }) => ({ ...state, isLoading: false, error })),
    on(assetsUpdateOne, (state, { updatedAsset }) =>
        assetsAdapter.updateOne({ changes: updatedAsset, id: updatedAsset.id }, { ...state, isLoading: false })
    ),
    on(assetsUpdateMany, (state, { updatedAssets }) =>
        assetsAdapter.updateMany(
            updatedAssets.map(updatedAsset => ({ changes: updatedAsset, id: updatedAsset.id })),
            { ...state, isLoading: false }
        )
    )
);

export const assetsTypeReducer = createReducer(
    initialAssetsTypeState,
    on(loadAssetsTypeRequestAction, state => ({ ...state, isLoading: true, error: null })),
    on(loadAssetsTypeSuccessAction, (state, { types }) => assetsTypeAdapter.setAll(types, { ...state, isLoading: false })),
    on(loadAssetsTypeFailureAction, (state, { error }) => ({ ...state, isLoading: false, error }))
);

export const assetsColReducer = createReducer(
    initialAssetsColState,
    on(assetsColUpsertMany, (state, { newCols }) => assetsColAdapter.upsertMany(newCols, state)),
    on(assetsColAddOne, (state, { newCol }) => assetsColAdapter.addOne(newCol, state)),
    on(assetsColAddMany, (state, { newCols }) => assetsColAdapter.addMany(newCols, state)),
    on(assetsColUpdateOne, (state, { updatedCol }) => assetsColAdapter.updateOne({ changes: updatedCol, id: updatedCol.field }, state)),
    on(assetsColUpdateMany, (state, { updatedCols }) =>
        assetsColAdapter.updateMany(
            updatedCols.map(col => ({ changes: col, id: col.field })),
            state
        )
    )
);

export const assetsStoreReducer = combineReducers(
    { assets: assetsListReducer, types: assetsTypeReducer, cols: assetsColReducer },
    initialAssetsStoreState
);
