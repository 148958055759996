export const apiUrls = {
    bckAPI: {
        baseUrl: '',
        versionApi: 'version/',
        authAPIs: {
            loginUrl: 'auth/login',
            logoutUrl: 'auth/logout',
            refreshUrl: 'auth/refresh',
            registerUrl: 'auth/register',
        },
        adminAPIs: {
            usersUrl: 'admin/users/',
            userUrl: 'admin/user/',
            userdataUrl: 'admin/userdata/',
            settingsUrl: 'admin/settings/',
        },
        userAPIs: {
            dataUrl: 'user/data/',
            validateTokenUrl: 'user/validateToken/',
            passwordUrl: 'user/password/',
        },
        notificationsAPIs: {
            notificationsUrl: 'notifications/',
        },
        censysAPIs: {
            censysUrl: 'censys/v2/',
        },
        shodanAPIs: {
            shodanUrl: 'shodan/',
        },
        whoisAPIs: {
            whoisUrl: 'whois/',
        },
        cmdbAPIs: {
            cmdbUrl: 'cmdb/',
            cmdbTypeUrl: 'cmdb/type/',
            cmdbInterfaceCountUrl: 'cmdb/interface/count/',
            cmdbInterfacesFromNetworkUrl: 'cmdb/interfaces/from_network/',
            cmdbIsInterfaceFreeUrl: 'cmdb/interface/is_free/',
            ipUrl: 'cmdb/asset/IP/',
            macUrl: 'cmdb/asset/MAC/',
            assetUrl: 'cmdb/asset/',
            filterFieldsUrl: 'cmdb/filter_fields/',
            interfaceUrl: 'cmdb/interface/',
            scoreByIpsUrl: 'cmdb/ip/score/',
        },
        scoreAPIs: {
            scoreUrl: 'scores/',
            classTreeUrl: 'scores/class_tree/',
            classUrl: 'scores/class/',
            classTypeUrl: 'scores/class_type/',
        },
        rules_tiAPIs: {
            rules_tiUrl: 'rules_ti/',
            update_tasksUrl: 'rules_ti/update_tasks/',
        },
        open_ctiAPIs: {
            openctiUrl: 'opencti/',
        },
        suricataAPIs: {
            suricataRuleUrl: 'suricata/',
        },
        threatAPIs: {
            threatUrl: 'threat/',
            threatEventGroupsUrl: 'threat/event_groups/',
            threatsNotificationsUrl: 'threat/notifications/',
            statusUrl: 'threat/status/',
            filterFieldsUrl: 'threat/filter_fields/',
            timelineUrl: 'threat/timeline/',
        },
        attackAPIs: {
            attackUrl: 'attack/',
            attackStatusUrl: 'attack/status/',
            attackClosedUrl: 'attack/closed/',
            socWorkloadUrl: 'attack/soc_workload/',
            attackDiagramUrl: 'attack/diagram/',
            attackThreatsUrl: 'attack/threats/',
            attackTreeUrl: 'attack/tree/',
            attackReasonUrl: 'attack/reason/',
            attacksStatusCounter: 'attack/status/counter/',
            attackCountFromThreasholdUrl: 'attack/count_from_threshold/',
            attackMttrUrl: 'attack/mttr/'
        },
        alertAPIs: {
            alertUrl: 'alert/',
            highestEvent: 'alert/highest_event/',
            events_by_categoryUrl: '/alert/events_by_category/',
        },
        eventAPIs: {
            eventUrl: 'event/',
            eventDetailsUrl: 'event/details/',
            from_event_aggUrl: 'event/from_event_agg/',
            filterFieldsUrl: 'event/filter_fields/',
        },
        mitre_attackAPIs: {
            mitreUrl: 'mitre_attack/',
            tacticsUrl: 'mitre_attack/tactics/',
            technicsUrl: 'mitre_attack/technics/',
            mitigationsUrl: 'mitre_attack/mitigations/',
            datasourcesUrl: 'mitre_attack/datasources/',
            groupsUrl: 'mitre_attack/groups/',
        },
        cyber_kill_chainAPIs: {
            cyber_kill_chainUrl: 'cyber_kill_chain/',
        },
        graphAPIs: {
            graphUrl: 'graph/',
            graphEventsUrl: 'graph/events/',
        },
        mlAPIs: {
            ml_model: 'ml/model/',
        },
        countryAPIs: {
            country_code: 'country_code/',
        },
        dictionaryAPIs: {
            feat_impUrl: 'dictionary/feat_imp/',
            eventUrl: 'dictionary/event/',
            threatUrl: 'dictionary/threat/',
            ndpi_l7: 'dictionary/ndpi_l7/',
        },
        analyzerAPIs: {
            analyzerUrl: 'analyzer/',
        },
        flowAPIs: {
            flowUrl: 'flow/',
        },
        statsAPIs: {
            topEventTypesUrl: 'stats/top_event_types/',
            topEventCategoriesUrl: 'stats/top_event_categories/',
            topThreatCategoriesUrl: 'stats/top_threat_categories/',
            nbAssetsUrl: 'stats/nb_assets/',
            hightestThreatUrl: 'stats/highest_threat/',
            hightestEventUrl: 'stats/highest_event/',
            nbEventsThreatsAttacksUrl: 'stats/nb_events_threats_attacks/',
            lvl7Url: 'stats/lvl7/',
            statsByAssetUrl: 'stats/stats_by_asset/',
            flowEventCount: 'stats/flow_event_count/',
            flowEventCountTimeline: 'stats/flow_event_count/timeline/',
            ipsFromEventCategoryUrl: 'stats/ips_from_event_category/',
            ipsFromEventCategoriesUrl: 'stats/ips_from_event_categories/',
            ipsFromEachEventCategoriesUrl: 'stats/ips_from_each_event_categories/',
            privateIpsThreatenedUrl: 'stats/private_ips_threatened/',
            mitreThreatsProjectionUrl: 'stats/mitre_threats_projection/',
            attacksClosureReasonUrl: 'stats/attacks_closure_reason/',
            detectionPerimeterUrl: 'stats/detection_perimeter/',
        },
        time_seriesAPIs: {
            time_seriesClassUrl: 'time_series/class/threat/',
            time_seriesChildrenByClassUrl: 'time_series/children_by_class/threat/',
            network_flowUrl: 'time_series/network_flow/',
            time_seriesThreatsClassUrl: 'time_series/threats/class/',
        },
        exportAPIs: {
            exportThreats: 'export/threats/',
            exportAssets: 'export/Assets/',
            exportEvents: 'export/Events/',
            exportGlobal: 'export/',
            exportRouterXlsUrl: 'export/router_xls/',
            exportRouterCsvUrl: 'export/router_csv/',
            exportLocalizationCsvToS3Url: 'export/export_localisation_csv_to_s3/',
        },
        whitelist_ruleAPIs: {
            whitelist_ruleUrl: 'whitelist_rule/',
            is_whitelistedUrl: 'whitelist_rule/is_whitelisted/',
            whitelist_mass_rulesUrl: 'whitelist_rule/mass_rules/',
        },
    },
    notifsAPI: {
        baseUrl: '',
        notificationsAPIs: {
            notificationsUrl: 'notifications/',
            hooksUrl: 'notifications/hooks',
        },
        rulesAPIs: {
            rulesUrl: 'rules/',
        },
        subscriptionsAPIs: {
            subscriptionsUrl: 'subscriptions/',
            updateEmailsUrl: 'subscriptions/update_emails',
        },
    },
};
