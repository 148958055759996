import { combineReducers, createReducer, on } from '@ngrx/store';
import {
    loadEventDictionaryFailure,
    loadEventDictionaryRequest,
    loadEventDictionarySuccess,
    loadFeatureImportanceDictionaryFailure,
    loadFeatureImportanceDictionaryRequest,
    loadFeatureImportanceDictionarySuccess,
    loadNdpiL7DictionaryFailure,
    loadNdpiL7DictionaryRequest,
    loadNdpiL7DictionarySuccess,
    loadThreatDictionaryFailure,
    loadThreatDictionaryRequest,
    loadThreatDictionarySuccess,
} from '../actions/dictionary.actions';
import {
    eventDictionaryAdapter,
    featureImportanceDictionaryAdapter,
    initialDictionaryState,
    initialEventDictionaryState,
    initialFeatureImportanceDictionaryState,
    initialNdpiL7DictionaryState,
    initialThreatDictionaryState,
    ndpiL7DictionaryAdapter,
    threatDictionaryAdapter,
} from '../state/dictionary.state';

//#region Event dictionary
export const eventDictionaryReducers = createReducer(
    initialEventDictionaryState,
    on(loadEventDictionaryRequest, state => ({ ...state, isLoading: true, error: null })),
    on(loadEventDictionarySuccess, (state, { events }) =>
        eventDictionaryAdapter.setAll(events, { ...state, isLoading: false, error: null })
    ),
    on(loadEventDictionaryFailure, (state, { error }) => ({ ...state, isLoading: false, error }))
);
//#endregion

//#region Threat dictionary
export const threatDictionaryReducers = createReducer(
    initialThreatDictionaryState,
    on(loadThreatDictionaryRequest, state => ({ ...state, isLoading: true, error: null })),
    on(loadThreatDictionarySuccess, (state, { threats }) =>
        threatDictionaryAdapter.setAll(threats, { ...state, isLoading: false, error: null })
    ),
    on(loadThreatDictionaryFailure, (state, { error }) => ({ ...state, isLoading: false, error }))
);
//#endregion

//#region Feature Importance
export const featureImportanceReducers = createReducer(
    initialFeatureImportanceDictionaryState,
    on(loadFeatureImportanceDictionaryRequest, state => ({ ...state, isLoading: true, error: null })),
    on(loadFeatureImportanceDictionarySuccess, (state, { features_importance }) =>
        featureImportanceDictionaryAdapter.setAll(features_importance, { ...state, isLoading: false, error: null })
    ),
    on(loadFeatureImportanceDictionaryFailure, (state, { error }) => ({ ...state, isLoading: false, error }))
);
//#endregion

//#region Feature Importance
export const ndpiL7Reducers = createReducer(
    initialNdpiL7DictionaryState,
    on(loadNdpiL7DictionaryRequest, state => ({ ...state, isLoading: true, error: null })),
    on(loadNdpiL7DictionarySuccess, (state, { ndpi_l7 }) =>
        ndpiL7DictionaryAdapter.setAll(ndpi_l7, { ...state, isLoading: false, error: null })
    ),
    on(loadNdpiL7DictionaryFailure, (state, { error }) => ({ ...state, isLoading: false, error }))
);
//#endregion

export const dictionaryReducer = combineReducers(
    {
        events: eventDictionaryReducers,
        threats: threatDictionaryReducers,
        features_importance: featureImportanceReducers,
        ndpi_l7: ndpiL7Reducers,
    },
    initialDictionaryState
);
