import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import {
    AnimationModel,
    AreaSeriesService,
    AxisModel,
    ChartAreaModel,
    ChartComponent,
    CrosshairService,
    CrosshairSettingsModel,
    LegendSettingsModel,
    MarkerSettingsModel,
    SelectionService,
    StripLineService,
    StripLineSettingsModel,
    TooltipService,
    ZoomService,
} from '@syncfusion/ej2-angular-charts';
import { cloneDeep } from 'lodash';
import moment, { utc } from 'moment';
import { Criticity } from 'src/app/models/criticity.interface';
import { ClassificationTreeNode } from 'src/app/models/score-class-node.interface';
import { UtilsService } from 'src/app/shared/utils/utils.service';

@Component({
    selector: 'hypervision-scatter-plot',
    templateUrl: './scatter-plot.component.html',
    styleUrls: ['./scatter-plot.component.scss'],
    providers: [TooltipService, ZoomService, AreaSeriesService, SelectionService, StripLineService, CrosshairService],
})
export class ScatterPlotComponent implements AfterViewInit {
    @ViewChild('scatterPlot') chart: ChartComponent;
    @ViewChild('scatterPlotContent') container: ElementRef;

    @Output() selectionChange = new EventEmitter();

    private seriesInternal: any[] = [];
    @Input() set series(v: any[]) {
        this.seriesInternal = v;
        this.updateSeries();
    }
    get series(): any[] {
        return this.seriesInternal;
    }

    parametersInternal: any = undefined;
    @Input() set parameters(v) {
        this.parametersInternal = v;
        this.updateSeries();
    }
    get parameters(): any {
        return this.parametersInternal;
    }

    stepHistoryInternal: any = undefined;
    @Input() set stepHistory(v) {
        this.stepHistoryInternal = v;

        this.serieHistory = v;
    }
    get stepHistory(): any {
        return this.stepHistoryInternal;
    }

    criticityInternal: Criticity;
    @Input() set criticity(v) {
        this.criticityInternal = v;
        this.updateSeries();
    }
    get criticity() {
        return this.criticityInternal;
    }

    classificationTreeInternal: Dictionary<ClassificationTreeNode>;
    @Input() set classificationTree(v: Dictionary<ClassificationTreeNode>) {
        this.classificationTreeInternal = v;
    }
    get classificationTree(): Dictionary<ClassificationTreeNode> {
        return this.classificationTreeInternal;
    }

    private selectedPointInternal: any;
    @Input() set selectedPoint(v: any) {
        if (!!v?.score) {
            this.selectedPointInternal = v;

            const pointColor = this.utils.getColorFromScore(v?.score);
            const primaryXAxis = cloneDeep(this.primaryXAxis);
            primaryXAxis.stripLines = [
                {
                    start: v?.count,
                    size: 2,
                    sizeType: 'Pixel',
                    dashArray: '3,3',
                    color: pointColor,
                },
            ];
            this.primaryXAxis = primaryXAxis;

            const primaryYAxis = cloneDeep(this.primaryYAxis);
            primaryYAxis.stripLines = [
                {
                    start: v?.score,
                    size: 2,
                    sizeType: 'Pixel',
                    dashArray: '3,3',
                    color: pointColor,
                },
            ];
            this.primaryYAxis = primaryYAxis;

            // if (!!this.chart) {
            //     this.chart.refresh();
            // }
        }
    }
    get selectedPoint(): any {
        return this.selectedPointInternal;
    }

    chartArea: ChartAreaModel = {
        border: {
            width: 0,
        },
    };

    legendSettings: LegendSettingsModel = {
        visible: false,
    };
    width: string = '100%';
    height: string = '100%';
    primaryXAxis: AxisModel = {
        minimum: 0,
        maximum: 100,
        title: '-------------->',
    };
    primaryYAxis: AxisModel = {
        minimum: 0,
        maximum: 100,
        title: '-------------->',
    };
    marker: MarkerSettingsModel = {
        visible: true,
        dataLabel: {
            visible: true,
            alignment: 'Center',
            position: 'Middle',
            name: 'type',
            font: {
                size: '22px',
                fontFamily: 'Assets',
            },
        },
        width: 25,
        height: 25,
        shape: 'Circle',
    };
    selectionStyle = {
        single: {
            color: 'red',
            width: 20,
            height: 20,
            shape: 'Circle',
        },
    };

    tooltip: Object = {
        enable: true,
        template: '<div></div>',
    };

    zoomSettings: Object = {
        mode: 'XY',
        enableMouseWheelZooming: true,
        enablePinchZooming: true,
        enableSelectionZooming: true,
        enableScrollbar: true,
    };

    crosshair: CrosshairSettingsModel = { enable: false, lineType: 'Both' };

    markerLow: MarkerSettingsModel = {};
    markerMedium: MarkerSettingsModel = {};
    markerHigh: MarkerSettingsModel = {};
    markerCritical: MarkerSettingsModel = {};
    markerHistory: MarkerSettingsModel = {
        visible: false,
        width: 12,
        height: 12,
        shape: 'Circle',
    };

    // custom code end
    title: string = '';
    serieLow: any = [];
    serieMedium: any = [];
    serieHigh: any = [];
    serieCritical: any = [];
    serieHistory: any = [];

    serieLowBg: any = { data: [] };
    serieMediumBg: any = { data: [] };
    serieHighBg: any = { data: [] };
    serieCriticalBg: any = { data: [] };

    historyAnimation: AnimationModel = {
        enable: true,
        duration: 5000,
        delay: 1,
    };

    constructor(private readonly ref: ChangeDetectorRef, private readonly utils: UtilsService) { }

    ngAfterViewInit(): void {
        if (!!this.container?.nativeElement.offsetHeight && !!this.container?.nativeElement.offsetWidth) {
            this.height = this.container?.nativeElement.offsetHeight + 'px';
            this.width = this.container?.nativeElement.offsetWidth + 'px';
            this.ref.detectChanges();
        }
    }

    axisLabelRender(arg: any) {
        if (!!arg) {
            const axis = arg.axis;
            if (!!axis) {
                if (axis.orientation === 'Vertical') {
                    arg.labelStyle.color = this.parameters?.isAttackData
                        ? this.utils.getColorFromAttackScore(arg.value)
                        : this.utils.getColorFromScore(arg.value);
                    arg.labelStyle.fontWeight = 'bold';
                }
            }
        }
    }

    pointRender(arg: any) {
        const score = arg?.point?.y;
        if (!!score) {
            if (arg?.series?.name !== 'history') {
                const pointColor = this.parameters?.isAttackData
                    ? this.utils.getColorFromAttackScore(arg.value)
                    : this.utils.getColorFromScore(arg.value);
                arg.fill = 'transparent';
                arg.point.color = pointColor;
            }
        }
    }

    pointClick(arg: any) {
        const selectedPoint = this.getSelectedPoint(arg.point.y, arg.pointIndex);
        if (!!selectedPoint && !!selectedPoint?.meta) {
            this.selectionChange.emit(selectedPoint.meta);
        }
    }

    tooltipRender(arg: any) {
        const asset = this.getSelectedPoint(arg.data.pointY, arg.data.pointIndex);
        if (!!asset && !!asset?.meta) {
            let newTemplate = '';
            if ('threat_cat_list' in asset.meta) {
                newTemplate = `
                <div class="flex bg-surface text-onsurface rounded border border-primary-500">
                    <div class="flex items-center justify-center border-r border-r-nonecol ${this.utils.getColorBgClassNameFromAttackScore(
                    asset.meta.score,
                    '0.2'
                )}">
                        <span class="text-2xl ${this.utils.getColorTextClassNameFromAttackScore(asset.meta.score)} font-bold px-4 py-2"
                            style="text-shadow: 0px 1px 1px black">
                            ${asset.meta.score}
                        </span>
                    </div>
                    <div class="flex flex-col p-1">
                        <div class="flex border-b border-primary-500 uppercase font-semibold">
                            <span class="text-secondary-500 pr-1">${asset.meta.nb_threat}</span>
                            <span>threats composed the attack</span>
                        </div>
                        <div class="flex flex-col">
                            <span class="text-onsurface/75">Categories used:</span>
                            <div class="flex flex-col overflow-auto text-secondary-500">${this.getThreatsCategoryForTooltip(
                    asset.meta.threat_cat_list
                )}</div>
                        </div>
                        <div class="flex flex-col">
                            <span class="text-onsurface/75">Starting date:</span>
                            <span class="px-1 text-secondary-500">${moment(asset.meta.start_date).format(
                    'dddd, MMMM Do YYYY, HH:mm:ss'
                )}</span>
                        </div>
                    </div>
                </div>`;
            } else {
                newTemplate = `
                <div class="flex bg-surface text-onsurface rounded border border-primary-500">
                    <div class="flex items-center justify-center border-r border-r-nonecol ${this.utils.getColorBgClassNameFromScore(
                    asset.meta.score_max,
                    '0.2'
                )}">
                        <span class="text-2xl ${this.utils.getColorTextClassNameFromScore(asset.meta.score_max)} font-bold px-4 py-2"
                            style="text-shadow: 0px 1px 1px black">
                            ${asset.meta.score_max}
                        </span>
                    </div>
                    <div class="flex flex-col p-1">
                        <div class="flex border-b border-primary-500 uppercase font-bold">
                            <span>${asset.meta.threat_occurency} threats detected on ...</span>
                        </div>
                        <div class="flex">
                            <div class="flex flex-col overflow-auto">${this.getAssetsForTooltip(asset.meta.items)}</div>
                        </div>
                    </div>
                </div>`;
            }
            arg.template = newTemplate;
        }
    }

    onResized(event: ResizeObserverEntry) {
        if (!!this.container) {
            this.height = this.container?.nativeElement.offsetHeight + 'px';
            this.width = this.container?.nativeElement.offsetWidth + 'px';
        }
    }

    private updateSeries() {
        this.serieLow = [];
        this.serieMedium = [];
        this.serieHigh = [];
        this.serieCritical = [];

        if (!!this.series && this.series.length > 0 && !!this.parameters && this.criticity) {
            this.getSeries(this.series);

            this.computeBackgrounds();

            this.primaryXAxis = this.getXAxis(
                this.parameters?.xAxisName,
                this.parameters?.minX,
                this.parameters?.medX,
                this.parameters?.maxX
            );
            this.primaryYAxis = this.getYAxis(
                this.parameters?.yAxisName,
                this.parameters?.minY,
                this.parameters?.medY,
                this.parameters?.maxY,
                5
            );
        }
    }

    private getSeries(series: any[]) {
        // Initialisation de la serie LOW
        const serieLow = series.find(s => s.name === 'low');
        if (!!serieLow) {
            this.serieLow = serieLow.data;
            this.markerLow = this.initMarker(serieLow, cloneDeep(this.marker));
        }

        // Initialisation de la serie MEDIUM
        const serieMedium = series.find(s => s.name === 'medium');
        if (!!serieMedium) {
            this.serieMedium = serieMedium.data;
            this.markerMedium = this.initMarker(serieMedium, cloneDeep(this.marker));
        }

        // Initialisation de la serie HIGH
        const serieHigh = series.find(s => s.name === 'high');
        if (!!serieHigh) {
            this.serieHigh = serieHigh.data;
            this.markerHigh = this.initMarker(serieHigh, cloneDeep(this.marker));
        }

        // Initialisation de la serie CRITICAL
        const serieCritical = series.find(s => s.name === 'critical');
        if (!!serieCritical) {
            this.serieCritical = serieCritical.data;
            this.markerCritical = this.initMarker(serieCritical, cloneDeep(this.marker));
        }
    }

    private initMarker(serieRaw: any, marker) {
        const markerRet: MarkerSettingsModel = marker;
        if (!!serieRaw) {
            if (!!markerRet.dataLabel?.font) {
                markerRet.dataLabel.font.color = serieRaw.color;
            }
        }

        return markerRet;
    }

    private getXAxis(label: string, min: number, med: number, max: number): AxisModel {
        const stripLines: StripLineSettingsModel[] = [
            {
                start: min,
                size: 2,
                sizeType: 'Pixel',
                color: 'rgb(var(--onsurface))',
            },
            {
                start: med,
                size: 1,
                sizeType: 'Pixel',
                color: 'rgb(var(--onsurface))',
            },
            {
                start: max,
                size: 2,
                sizeType: 'Pixel',
                color: 'rgb(var(--onsurface))',
            },
        ];

        return {
            majorGridLines: { width: 1, color: 'rgba(var(--none-criticity))' },
            minorGridLines: { width: 1, color: 'rgba(var(--none-criticity))' },
            minimum: min,
            maximum: max,
            title: '-------   ' + label?.toUpperCase() + '   ------->',
            labelStyle: {
                color: 'rgb(var(--onsurface))',
            },
            titleStyle: {
                color: 'rgb(var(--onsurface))',
            },
            stripLines,
        };
    }

    private getYAxis(label: string, min: number, med: number, max: number, interval: number = 5): AxisModel {
        const stripLines: StripLineSettingsModel[] = [
            {
                start: min,
                size: 2,
                sizeType: 'Pixel',
                color: 'rgb(var(--onsurface))',
            },
            {
                start: med,
                size: 1,
                sizeType: 'Pixel',
                color: 'rgb(var(--onsurface))',
            },
            {
                start: max,
                size: 2,
                sizeType: 'Pixel',
                color: 'rgb(var(--onsurface))',
            },
        ];

        stripLines.push(
            this.getCriticityStripLine(
                this.parameters.isAttackData ? this.criticity.attack_low : this.criticity.low,
                this.parameters.isAttackData
            ),
            this.getCriticityStripLine(
                this.parameters.isAttackData ? this.criticity.attack_medium : this.criticity.medium,
                this.parameters.isAttackData
            ),
            this.getCriticityStripLine(
                this.parameters.isAttackData ? this.criticity.attack_high : this.criticity.high,
                this.parameters.isAttackData
            ),
            this.getCriticityStripLine(
                this.parameters.isAttackData ? this.criticity.attack_critical : this.criticity.critical,
                this.parameters.isAttackData
            )
        );

        return {
            majorGridLines: { width: 1, color: 'rgba(var(--none-criticity))' },
            minorGridLines: { width: 1, color: 'rgba(var(--none-criticity))' },
            interval,
            minimum: min,
            maximum: max,
            title: '-------   ' + label?.toUpperCase() + '   ------->',
            titleStyle: {
                color: 'rgb(var(--onsurface))',
            },
            stripLines,
        };
    }

    private getCriticityStripLine(threshold, bIsAttack): StripLineSettingsModel {
        const dashArray = '2, 4';
        const size = 2;
        return {
            start: threshold,
            dashArray,
            size,
            sizeType: 'Pixel',
            color: bIsAttack ? this.utils.getColorFromAttackScore(threshold) : this.utils.getColorFromScore(threshold),
        };
    }

    private getSelectedPoint(score, pointIndex) {
        const serieName = this.parameters?.isAttackData
            ? this.utils.getScoreRangeLabelFromAttackScore(score)
            : this.utils.getScoreRangeLabelFromScore(score);
        if (serieName !== 'none') {
            const selectedSerie = this.series.find(s => s.name === serieName);
            if (!!selectedSerie) {
                return selectedSerie.data[pointIndex];
            }
        }
        return null;
    }

    private getThreatsCategoryForTooltip(items) {
        let retTooltipTemplate = '';
        items.forEach(category => {
            const iconFromType = this.utils.getIconFromCategoryId(category);
            const categoryName = this.classificationTree[category]?.name;

            retTooltipTemplate += `
                <div class="flex items-center">
                    <div class="icon small ${iconFromType} mx-1 bg-onsurface"></div>
                    <span>${categoryName}</span>
                </div>`;
        });

        return retTooltipTemplate;
    }

    private getAssetsForTooltip(items) {
        let retTooltipTemplate = '';

        items.sort((a, b) => b.type - a.type);
        let idx = 0;
        for (const i of items) {
            const iconFromType = this.getIconFromNumberType(i.type);
            if (!!i.name) {
                retTooltipTemplate += `<div class="flex items-center"><div class="icon small ${iconFromType} mx-1 bg-onsurface"></div>${i.name}</div>`;
            } else {
                i.ips.forEach((ip, idx2) => {
                    retTooltipTemplate += `<div class="flex items-center"><div class="icon small ${iconFromType} mx-1 bg-onsurface"></div>${ip}</div>`;

                    if (idx2 > 10) {
                        retTooltipTemplate += `<div class="flex items-center">...</div>`;
                        return;
                    }
                });
            }
            idx++;
            if (idx >= 10) {
                const reste = items.length - 10;
                retTooltipTemplate += `<div class="flex items-center">... (${reste} more)</div>`;
                break;
            }
        }

        return retTooltipTemplate;
    }

    private getIconFromNumberType(type: number) {
        switch (type) {
            case -1:
                return 'at-interface';
            case 1:
                return 'at-router2';
            case 2:
                return 'at-computer';
            default:
                return 'at-interface';
        }
    }

    private computeBackgrounds() {
        let low = this.criticity.medium;
        let medium = this.criticity.high - this.criticity.medium;
        let high = this.criticity.critical - this.criticity.high;
        let critical = 100 - this.criticity.critical;

        if (this.parameters.isAttackData) {
            low = this.criticity.attack_medium;
            medium = this.criticity.attack_high - this.criticity.attack_medium;
            high = this.criticity.attack_critical - this.criticity.attack_high;
            critical = 100 - this.criticity.attack_critical;
        }

        const bgOpacity = '0.2';

        this.serieLowBg = {
            data: [
                { x: this.parameters?.minX, y: low },
                { x: this.parameters?.maxX, y: low },
            ],
            fill: this.utils.getColorFromScore(this.criticity.low, bgOpacity),
            //border: { width: 1, color: this.utils.getColorFromScore(this.criticity.low, borderOpacity) },
        };
        this.serieMediumBg = {
            data: [
                { x: this.parameters?.minX, y: medium },
                { x: this.parameters?.maxX, y: medium },
            ],
            fill: this.utils.getColorFromScore(this.criticity.medium, bgOpacity),
            //border: { width: 1, color: this.utils.getColorFromScore(this.criticity.medium, borderOpacity) },
        };
        this.serieHighBg = {
            data: [
                { x: this.parameters?.minX, y: high },
                { x: this.parameters?.maxX, y: high },
            ],
            fill: this.utils.getColorFromScore(this.criticity.high, bgOpacity),
            //border: { width: 1, color: this.utils.getColorFromScore(this.criticity.high, borderOpacity) },
        };
        this.serieCriticalBg = {
            data: [
                { x: this.parameters?.minX, y: critical },
                { x: this.parameters?.maxX, y: critical },
            ],
            fill: this.utils.getColorFromScore(this.criticity.critical, bgOpacity),
            //border: { width: 1, color: this.utils.getColorFromScore(this.criticity.critical, borderOpacity) },
        };
    }
}
