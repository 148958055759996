export enum FilterType {
    DATE_RANGE,
    DASHBOARD_DATE_RANGE,
    DATE,
    NUMBER,
    STRING,
    CAT_TYPE,
    COUNTRY,
    BOOLEAN,
    ANALYZER,
    QUERY_BUILDER,
    ATTACK_STATUS,
    PROTO_7,
}
