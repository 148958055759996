import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AssetsStoreState, assetsAdapter, assetsColAdapter, assetsTypeAdapter } from '../state/assets.state';

export const selectAssetsStoreState = createFeatureSelector<AssetsStoreState>('assets');

export const selectAssetsState = createSelector(selectAssetsStoreState, state => state.assets);
export const {
    selectIds: selectAssetsIds,
    selectEntities: selectAssetsEntities,
    selectAll: selectAllAssets,
} = assetsAdapter.getSelectors(selectAssetsState);
export const selectAssetsById = (id: string) => createSelector(selectAssetsEntities, entities => entities[id]);
export const selectAssetsError = createSelector(selectAssetsState, state => state.error);
export const selectAssetsIsLoading = createSelector(selectAssetsState, state => state.isLoading);

export const selectAssetsTypeState = createSelector(selectAssetsStoreState, state => state.types);
export const {
    selectIds: selectAssetsTypeIds,
    selectEntities: selectAssetsTypeEntities,
    selectAll: selectAssetsTypeAll,
} = assetsTypeAdapter.getSelectors(selectAssetsTypeState);
export const selectAssetsTypeById = (id: number) => createSelector(selectAssetsTypeEntities, entities => entities[id]);
export const selectAssetsTypeIsLoading = createSelector(selectAssetsTypeState, state => state.isLoading);
export const selectAssetsTypeError = createSelector(selectAssetsTypeState, state => state.error);

export const selectAssetsColState = createSelector(selectAssetsStoreState, state => state?.cols);
export const { selectAll: selectAllAssetsCols, selectEntities: selectEntitiesAssetsCol } =
    assetsColAdapter.getSelectors(selectAssetsColState);
